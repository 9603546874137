import React from 'react';
import { Navbar } from '../components/navbar';
import { PageTitle } from '../components/PageTitle';
import '../css/index.css'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IRootState } from '../store';
import {  faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Loading: React.FC = () =>{
    const { t } = useTranslation();
    const CaseData = useSelector((state:IRootState)=>state.form.caseData)
    return (
        <>
            <Navbar canGoBack={false}/>
            <div className="container">
                <PageTitle title={''}/>
                <div className="completeContainer">
                    <div className="isLoading">
                        <FontAwesomeIcon icon={faSpinner} spin color="white"></FontAwesomeIcon>
                    </div>
                    <div className="title">{t("loading_media_title")}</div>
                    <div className="contentText"> {t('loading_media_content_1')}</div>
                    <div className="contentText"> {t('loading_media_content_2')}</div>
                </div>
            </div>
        </>
    )
}
