import React, { useEffect, useState } from "react";
import "../css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { loadCaseThunk, loadFormThunk } from "../redux/form/thunk";
import { IRootState } from "../store";
import { push } from "connected-react-router";
import { NotFound } from "./notFound";
import { Form } from "../components/form/Form";

interface Props {
  page: number;
  path: string;
  forms: any;
}

export const FormRoute: React.FC<Props> = ({ page, path, forms }: Props) => {
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const dispatch = useDispatch();
  // const url = useRouteMatch();
  useEffect(() => {
    if (!caseToken || Object.values(caseToken).length <= 0) {
      dispatch(push(`${path}`));
      return;
    }
    dispatch(loadCaseThunk(caseToken));
  }, []);

  return (
    <>
      <Switch>
        {forms &&
          forms.map((form: any, index: number) => (
            <Route path={`${path}/form/${index}`} key={`form_${index}`}>
              <Form page={page} form={form} index={index} path={path} />
            </Route>
          ))}
        <Route path={`${path}/form`} component={NotFound}></Route>
      </Switch>
    </>
  );
};
