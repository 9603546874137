import { Case } from '../../types/index';
import { Form } from '../../types/index';

export function sendQAProcessing(){
    return {
        type: "@@qa/SEND_QA_PROCESSING" as "@@qa/SEND_QA_PROCESSING",
        isProcessing: true,
        isSuccess:false,
    }  
}

export function sendQASuccess(qaData: Partial<Case>){
    return {
        type: "@@qa/SEND_QA_SUCCESS" as "@@qa/SEND_QA_SUCCESS",
        qaData: qaData,
        isProcessing: false,
        isSuccess:true,
    }  
}

export function sendQAFailure(errorMessage:string){
    return {
        type:"@@qa/SEND_QA_FAILURE" as "@@qa/SEND_QA_FAILURE",
        isProcessing: false,
        isSuccess: false,
        errorMessage: errorMessage
    }
}

export function loadQAProcessing(){
    return {
        type: "@@qa/LOAD_QA_PROCESSING" as "@@qa/LOAD_QA_PROCESSING",
        isProcessing: true,
    }  
}

export function loadQASuccess(qaData: Partial<Case>){
    return {
        type: "@@qa/LOAD_QA_SUCCESS" as "@@qa/LOAD_QA_SUCCESS",
        qaData: qaData,
        isProcessing: false
    }  
}

export function loadQAFailure(){
    return{
        type: "@@qa/LOAD_QA_FAILURE" as "@@qa/LOAD_QA_FAILURE",
        isProcessing: false,
        isSuccess: false
    }
}

// export function loadQAFormProcessing(){
//     return {
//         type: "@@qa/LOAD_QAFORM_PROCESSING" as "@@qa/LOAD_QAFORM_PROCESSING",
//         isProcessing: true,
//     }  
// }

// export function loadQAFormSuccess({ form, qa }: {form: Form[], qa: Form[]}){
//     return {
//         type: "@@qa/LOAD_QAFORM_SUCCESS" as "@@qa/LOAD_QAFORM_SUCCESS",
//         form: form,
//         qa: qa,
//         isProcessing: false,
//         totalPage: qa.length
//     }  
// }

// export function loadQAFormFailure(){
//     return{
//         type: "@@qa/LOAD_QAFORM_FAILURE" as "@@qa/LOAD_QAFORM_FAILURE",
//         isProcessing: false,
//         isSuccess: false
//     }
// }

export function resetForm(){
    return {
        type: "@@qa/RESET" as "@@qa/RESET",
    }
}

type QAActionCreators = 
    typeof loadQAProcessing | 
    typeof loadQASuccess | 
    typeof loadQAFailure | 
    typeof sendQAProcessing | 
    typeof sendQASuccess | 
    typeof sendQAFailure |
    // typeof loadQAFormProcessing | 
    // typeof loadQAFormSuccess | 
    // typeof loadQAFormFailure |
    typeof resetForm

export type IQAActions = ReturnType<QAActionCreators>
