import { toast } from "react-toastify";
import { config } from "../../config";
import { ThunkDispatch } from "../../store";
import {
  clearToken,
  sendOTPFail,
  sendOTPProcessing,
  sendOTPSuccess,
  verifyOTPSuccess,
  verifyTokenFail,
  verifyTokenProcessing,
  verifyTokenSuccess,
  verifyReactivateOTPSuccess
} from "./actions";

import * as dotenv from "dotenv";
import { push } from "connected-react-router";
import { TFunction } from "react-i18next";
import { updateLocaleFinish, updateLocaleSuccess } from "../language/actions";
dotenv.config();

export function sendOTPThunk(
  profileId: number,
  prefix: number,
  mobile: string,
  language: string
) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(sendOTPProcessing());
      let postData = {
        profileId: profileId,
        prefix: prefix,
        mobile: parseInt(mobile),
        language
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/mobile-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      const data = await res.json();
      if (res.status == 200) {
        dispatch(sendOTPSuccess());
      } else if (res.status != 200) {
        dispatch(sendOTPFail("Internal Server Error"));
        return data.message;
      }
    } catch (err) {
      dispatch(sendOTPFail("Internal Server Error"));
    }
  };
}

export function verifyOTPThunk(
  otp: string,
  prefix: number,
  mobile: string,
  profileId: number,
  merchantId: number,
  locale = "hk",
  userAgent?: string,
  visitorId?: string,
  refString?: string | null
) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(sendOTPProcessing());
      let postData = {
        otp: otp,
        prefix: prefix,
        mobile: parseInt(mobile),
        profileId: profileId,
        merchantId: merchantId,
        locale: locale,
        userAgent: userAgent,
        fingerprint: visitorId,
        refString: refString
      };
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/case/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      const data = await res.json();
      if (res.status == 200) {
        dispatch(
          verifyOTPSuccess(data.caseToken, data.currentPage, data.agreementTime)
        );
      } else if (res.status != 200) {
        dispatch(sendOTPFail("Internal Server Error"));
        return data.message;
      }
    } catch (err) {
      dispatch(sendOTPFail("Internal Server Error"));
    }
  };
}

export function verifyTokenWithProfile(
  path: string,
  token: string,
  hash: string,
  t: TFunction<"translation">
) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(verifyTokenProcessing());
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/profile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: token, hash: hash }),
        }
      );

      const data = await res.json();
      if (res.status == 200) {
        dispatch(verifyTokenSuccess());
      } else if (res.status != 200) {
        dispatch(verifyTokenFail());
        localStorage.clear();
        dispatch(clearToken());
        dispatch(push(`${path}`));
        console.log('verifyTokenWithProfile---------------------')
        console.log('data.message::', data.message)
        toast.error(t(data.message));
      }
    } catch (err) {
      dispatch(verifyTokenFail());
    }
  };
}

export function sendReactivateOTPThunk(
  hash: string = "",
  prefix: number,
  mobile: string
) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(sendOTPProcessing());
      let postData = {
        hash: hash,
        prefix: prefix,
        mobile: parseInt(mobile),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/reactivate-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      const data = await res.json();
      if (res.status == 200) {
        dispatch(sendOTPSuccess());
      } else if (res.status != 200) {
        dispatch(sendOTPFail("Internal Server Error"));
        return data.message;
      }
    } catch (err) {
      dispatch(sendOTPFail("Internal Server Error"));
    }
  };
}

export function verifyReactivateOTPThunk(
  otp: string,
  prefix: number,
  mobile: string,
  hash: string | undefined,
  userAgent?: string,
  visitorId?: string,
) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(sendOTPProcessing());
      let postData = {
        otp: otp,
        prefix: prefix,
        mobile: parseInt(mobile),
        token: hash,
        userAgent: userAgent,
        fingerprint: visitorId,
      };
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/case/reactivate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      const data = await res.json();
      if (res.status == 200) {
        dispatch(
          verifyReactivateOTPSuccess(data.caseToken, data.url_hash)
        );
        dispatch(updateLocaleSuccess(data.locale));
        dispatch(updateLocaleFinish());
      } else if (res.status != 200) {
        dispatch(sendOTPFail("Internal Server Error"));
        return data.message;
      }
    } catch (err) {
      dispatch(sendOTPFail("Internal Server Error"));
    }
  };
}
