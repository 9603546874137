import React, { useEffect } from 'react';
import Lottie from 'lottie-react-web';
import loaderAnimationData from '../assets/lottie/10910-loader.json';
import successAnimationData from '../assets/lottie/8069-simple-success.json';
import '../css/index.css';

interface Props {
    isProcessing?: boolean;
    isSuccess?: boolean;
    percentage?: number | string;
}

export default ({ isProcessing, isSuccess, percentage }: Props) => {

    return isProcessing ? (
        <div className="loadingLogo" 
            style={{
                width: "100%", 
                height:'100%', 
                backgroundColor:"rgba(0, 0, 0, 0.6)", 
                display:"flex", 
                justifyContent:'center', 
                alignItems: 'center', 
                position:'fixed',
                zIndex: 10,
                }}>
            {isSuccess ? (
                    <Lottie
                        speed={2}
                        height={200}
                        width={200}
                        forceSegments={true}
                        options={{
                            animationData: successAnimationData,
                        }}
                    />
            ) : (
                <div>
                    <Lottie
                        height={200}
                        width={200}
                        forceSegments={true}
                        options={{
                            animationData: loaderAnimationData,
                        }}
                    />
                </div>
            )}
            {percentage && <div style={{color: "white", fontSize: 20, position:"absolute"}}>{percentage}</div>}
        </div>
    ) : null;
};
