export const bankCode = {
  "003": "STANDARD CHARTERED BANK (HONG KONG) LIMITED",
  "004": "THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD",
  "006": "CITIBANK N.A.",
  "009": "CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED",
  "012": "BANK OF CHINA (HONG KONG) LIMITED",
  "014": "BANK OF CHINA (HONG KONG) LIMITED",
  "015": "THE BANK OF EAST ASIA, LIMITED",
  "016": "DBS BANK (HONG KONG) LIMITED",
  "018": "CHINA CITIC BANK INTERNATIONAL LIMITED",
  "019": "BANK OF CHINA (HONG KONG) LIMITED",
  "020": "CMB WING LUNG BANK LIMITED",
  "022": "OVERSEA-CHINESE BANKING CORPORATION LTD",
  "024": "HANG SENG BANK LTD",
  "025": "SHANGHAI COMMERCIAL BANK LTD",
  "026": "BANK OF CHINA (HONG KONG) LIMITED",
  "027": "BANK OF COMMUNICATIONS CO., LTD.",
  "028": "PUBLIC BANK (HONG KONG) LIMITED",
  "030": "BANK OF CHINA (HONG KONG) LIMITED",
  "031": "BANK OF CHINA (HONG KONG) LIMITED",
  "032": "DBS BANK (HONG KONG) LIMITED",
  "033": "BANK OF CHINA (HONG KONG) LIMITED",
  "035": "OCBC WING HANG BANK LIMITED",
  "036": "BANK OF CHINA (HONG KONG) LIMITED",
  "038": "TAI YAU BANK LTD",
  "039": "CHIYU BANKING CORPORATION LTD",
  "040": "DAH SING BANK LTD",
  "041": "CHONG HING BANK LIMITED",
  "043": "NANYANG COMMERCIAL BANK LTD",
  "050": "INDIAN OVERSEAS BANK",
  "052": "DBS BANK (HONG KONG) LIMITED",
  "060": "NATIONAL BANK OF PAKISTAN",
  "061": "TAI SANG BANK LTD",
  "063": "MALAYAN BANKING BERHAD (MAYBANK)",
  "064": "BANK OF CHINA (HONG KONG) LIMITED",
  "066": "PT. BANK NEGARA INDONESIA (PERSERO) TBK.",
  "070": "BANK OF CHINA (HONG KONG) LIMITED",
  "071": "UNITED OVERSEAS BANK LTD",
  "072": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD ",
  "082": "STATE BANK OF INDIA",
  "128": "FUBON BANK (HONG KONG) LIMITED",
  "185": "DBS BANK LTD, HONG KONG BRANCH",
  "198": "HUA NAN COMMERCIAL BANK LTD",
  "203": "FIRST COMMERCIAL BANK",
  "214": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED",
  "221": "CHINA CONSTRUCTION BANK CORPORATION",
  "222": "AGRICULTURAL BANK OF CHINA LIMITED",
  "229": "CTBC BANK CO., LTD.",
  "238": "CHINA MERCHANTS BANK CO., LTD.",
  "250": "CITIBANK (HONG KONG) LIMITED",
  "269": "THE SHANGHAI COMMERCIAL & SAVINGS BANK LTD",
  "353": "CHINA MINSHENG BANKING CORP., LTD.",
  "368": "CHINA EVERBRIGHT BANK CO., LTD",
  "377": "INDUSTRIAL BANK CO., LTD.",
  "378": "YUANTA COMMERCIAL BANK CO., LTD.",
  "382": "BANK OF COMMUNICATIONS (HONG KONG) LIMITED",
};

export const getBankNameByCode = (code: string) => {
  //@ts-ignore
  return bankCode[code];
};
