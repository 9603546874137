import React, { useEffect, useState } from "react";
import { Navbar } from "../components/navbar";
import { push } from "connected-react-router";
import { PageTitle } from "../components/PageTitle";
import "../css/index.css";
import { config } from "../config";
import { useDispatch, useSelector } from "react-redux";
// import idFrontSample from '../assets/img/id_front.png';
// import idBackSample from '../assets/img/id_back.png';
import styles from "../css/idCard.module.css";
import { LogoButton } from "../components/LogoButton";
import {
  faCamera,
  faCheck,
  faCloudUploadAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { UploadModal } from "../components/UploadModal";
import { IRootState } from "../store";
import { sendIdcardsThunk } from "../redux/document/thunk";
import { resetDocument } from "../redux/document/actions";
import idFront from "../assets/img/hkIdCard-template02.png";
import LoadingOverlay from "../components/LoadingOverlay";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPrevIdCard } from "../redux/media/actions";

interface Props {
  page: number;
  path: string;
}

export const IDCard: React.FC<Props> = ({ page, path }: Props) => {
  const dispatch = useDispatch();
  const useQuery = () =>
    new URLSearchParams(useLocation().search).get("region");
  const token = useSelector((state: IRootState) => state.otp.caseToken);
  const isSuccess = useSelector(
    (state: IRootState) => state.document.isSuccess
  );
  const isProcessing = useSelector(
    (state: IRootState) => state.document.isProcessing
  );
  const errorMessage = useSelector(
    (state: IRootState) => state.document.errorMessage
  );
  const idFrontFile = useSelector(
    (state: IRootState) => state.document.idFront
  );
  const idBackFile = useSelector((state: IRootState) => state.document.idBack);
  const prevIdFront = useSelector(
    (state: IRootState) => state.media.prevIdFront?.base64
  );
  const prevIdBack = useSelector(
    (state: IRootState) => state.media.prevIdBack?.base64
  );
  const locale = useSelector((state: IRootState) => state.setting.locale);
  const profileId = useSelector((state: IRootState) => state.form.profileId);
  const merchantId = useSelector((state: IRootState) => state.form.merchantId);

  const [file, setFile] = useState<File>();
  const [frontId, setFrontId] = useState<File>();
  const [backId, setBackId] = useState<File>();
  // const [prevIdFront, setPrevIdFront] = useState(undefined);
  // const [prevIdBack, setPrevIdBack] = useState(undefined);
  const [isOpen, setisOpen] = useState(false);
  const [percentage, setPercentage] = useState<number | string>(0);

  const { t } = useTranslation();
  const modalContent = [
    {
      title: t("doc_id_error_sub_title"),
      contents: [
        { icon: faTimes, text: t("doc_id_error_cover") },
        { icon: faTimes, text: t("doc_id_error_clear_wording") },
        { icon: faTimes, text: t("doc_id_error_center") },
        { icon: faTimes, text: t("doc_id_error_scale") },
      ],
    },
    {
      title: t("doc_id_error_sample"),
      image: idFront,
    },
  ];

  const frontbuttonOnClick = (v: File) => {
    setFrontId(v);
  };

  const backbuttonOnClick = (v: File) => {
    setBackId(v);
  };

  useEffect(() => {
    if (!token || Object.values(token).length <= 0) {
      dispatch(push(`${path}`));
      return;
    }
  }, []);

  useEffect(() => {
    if (idFrontFile) {
      setFrontId(idFrontFile);
    }
    if (idBackFile) {
      setBackId(idBackFile);
    }
  }, [idFrontFile, idBackFile]);

  useEffect(() => {
    if (isSuccess) {
      setFile(undefined);
      dispatch(resetDocument());
      dispatch(push(`${path}/form/0`));
    }
  }, [isSuccess, errorMessage]);

  return (
    <>
      <LoadingOverlay isProcessing={isProcessing} 
      // percentage={percentage}
       />
      <Navbar canGoBack={false} />
      <div className="container" id="id_card">
        <PageTitle page={page} title={t("doc_id__title")} />
        <div>
          <div>
            {/* <div className="contentText">{t("doc_id__import")}</div> */}
            <div className={styles.dashedContainer}>
              <div className={styles.subtitle}>{t("doc_id_frontside")}</div>
              {!frontId && !prevIdFront && (
                <div style={{ display: "flex",  width: "100%" }}>
                  <LogoButton
                    name={"doc_id_takephoto"}
                    icon={faCamera}
                    type={"Front_Side"}
                    onPress={frontbuttonOnClick}
                  />
                  {/* <div style={{ flex: 1 }} /> */}
                </div>
              )}
              {(frontId || prevIdFront) && (
                <div style={{ display: "flex", flexDirection:'column', flexWrap: "wrap", width:"100%" }}>
                  {frontId && (
                    <img
                      className={styles.idCardFront}
                      src={URL.createObjectURL(frontId)}
                    />
                  )}
                  {!frontId && prevIdFront && (
                    <img
                      className={styles.idCardFront}
                      src={`data:image/jpeg;base64, ${prevIdFront}`}
                    />
                  )}
                  <button
                    className={"button"}
                    onClick={() => {
                      // setFrontId(undefined)
                      if (frontId) setFrontId(undefined);
                      if (prevIdFront) {
                        dispatch(resetPrevIdCard());
                      }
                    }}
                  >
                    <div className={"buttonText"}>{t("retake")}</div>
                  </button>
                </div>
              )}
            </div>
            <div className={styles.dashedContainer}>
              <div className={styles.subtitle}>{t("doc_id_backside")}</div>
              {!backId && !prevIdBack && (
                <div style={{ display: "flex", width: "100%"}}>
                  <LogoButton
                    name={"doc_id_takephoto"}
                    icon={faCamera}
                    type={"Back_Side"}
                    onPress={backbuttonOnClick}
                  />
                  {/* <div style={{ flex: 1 }} /> */}
                </div>
              )}
              {(backId || prevIdBack) && (
                <div style={{ display: "flex", flexWrap: "wrap" ,flexDirection:'column',width:"100%"}}>
                  {backId && (
                    <img
                      className={styles.idCardFront}
                      src={URL.createObjectURL(backId)}
                    />
                  )}
                  {!backId && prevIdBack && (
                    <img
                      className={styles.idCardFront}
                      src={`data:image/jpeg;base64, ${prevIdBack}`}
                    />
                  )}
                  <button
                    className={"button"}
                    onClick={() => {
                      if (backId) setBackId(undefined);
                      if (prevIdBack) {
                        dispatch(resetPrevIdCard());
                      }
                    }}
                  >
                    <div className={"buttonText"}>{t("retake")}</div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          className={
            (frontId && backId && token && !isProcessing) ||
            (prevIdFront && prevIdBack && token && !isProcessing)
              ? "button bottomMargin"
              : "disabledButton bottomMargin"
          }
          onClick={async () => {
            if (
              !token ||
              !merchantId ||
              !profileId ||
              !((frontId && backId) || (prevIdFront && prevIdBack))
            )
              return;
            if (!locale) {
              toast.warn(
                "System cannot identity your national ID region. Please go back to homepage and retry."
              );
              return;
            }
            if (frontId && backId) {
              const res = await dispatch(
                sendIdcardsThunk(
                  locale,
                  token,
                  merchantId,
                  profileId,
                  frontId,
                  backId,
                  setisOpen,
                  setPercentage,
                  path
                )
              );
              res && toast.error(t(res?.toString()));
            } else if (prevIdFront && prevIdBack) {
              dispatch(push(`${path}/form/0`));
            }
          }}
          disabled={
            !(
              (frontId && backId && token && !isProcessing) ||
              (prevIdFront && prevIdBack && token && !isProcessing)
            )
          }
        >
          <div className="buttonText">{t("Submit")}</div>
        </button>
      </div>
      <div id="upload_modal"></div>
      <UploadModal
        title={t("doc_id_error_title")}
        contents={modalContent}
        buttonText={t("ok")}
        isOpen={isOpen}
        setisOpen={(v) => setisOpen(v)}
      />
    </>
  );
};
