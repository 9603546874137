import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { ISettingActions } from "./redux/language/actions";
import { settingReducers } from "./redux/language/reducer";
import { ISettingState } from "./redux/language/state";
import thunk, { ThunkDispatch as OldThunkDispatch } from "redux-thunk";
import { IOTPState } from "./redux/otp/state";
import { otpReducers } from "./redux/otp/reducer";
import { IOTPActions } from "./redux/otp/actions";
import { IFormState } from "./redux/form/state";
import { IFormActions } from "./redux/form/actions";
import { formReducers } from "./redux/form/reducer";
import {
  RouterState,
  connectRouter,
  routerMiddleware,
  CallHistoryMethodAction,
} from "connected-react-router";
import { createHashHistory } from "history";
// import { createBrowserHistory } from 'history';
import { bioReducers } from "./redux/bio/reducer";
import { IBioState } from "./redux/bio/state";
import { IBioActions } from "./redux/bio/actions";
import { documentReducers } from "./redux/document/reducer";
import { IDocumentActions } from "./redux/document/actions";
import { IDocumentState } from "./redux/document/state";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//@ts-ignore
import expireIn from "redux-persist-transform-expire-in";
import { qaReducers } from "./redux/qa/reducer";
import { IQAState } from "./redux/qa/state";
import { IQAActions } from "./redux/qa/actions";
import { IPrevMediaState } from "./redux/media/state";
import { IPrevMediaActions } from "./redux/media/actions";
import { mediaReducers } from "./redux/media/reducer";
import { ImportantDisclosureReducers } from "./redux/important_disclosure/reducer";
import { IImportantDisclosureState } from "./redux/important_disclosure/state";
import { IImportantDisclosureActions } from "./redux/important_disclosure/acitons";

// export const history = createBrowserHistory();
export const history = createHashHistory();

const persistOtpConfig = {
  key: "caseToken",
  storage: storage,
  whitelist: ["caseToken", "agreementTime"], // which reducer want to store
};

const persistSettingConfig = {
  key: "locale",
  storage: storage,
  whitelist: ["locale"], // which reducer want to store
};

export interface IRootState {
  setting: ISettingState;
  otp: IOTPState;
  form: IFormState;
  qa: IQAState;
  document: IDocumentState;
  bio: IBioState;
  router: RouterState;
  media: IPrevMediaState;
  importantDisclosure: IImportantDisclosureState;
}

type IRootAction =
  | ISettingActions
  | IOTPActions
  | IFormActions
  | IQAActions
  | IBioActions
  | IDocumentActions
  | IPrevMediaActions
  | IImportantDisclosureActions
  | CallHistoryMethodAction;

const rootReducer = combineReducers<IRootState>({
  setting: persistReducer<any, any>(
    {
      ...persistSettingConfig,
      transforms: [expireIn(60 * 60 * 24 * 1000, "localeExpiration", [])],
    },
    settingReducers
  ),
  otp: persistReducer<any, any>(
    {
      ...persistOtpConfig,
      transforms: [expireIn(60 * 60 * 24 * 1000, "caseTokenExpiration", [])],
    },
    otpReducers
  ),
  form: formReducers,
  qa: qaReducers,
  importantDisclosure: ImportantDisclosureReducers,
  document: documentReducers,
  media: mediaReducers,
  bio: bioReducers,
  router: connectRouter(history),
});

const appReducer = (state: IRootState | undefined, action: any) => {
  if (action.type === "RESET_STATE") {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
}

export type ThunkDispatch = OldThunkDispatch<IRootState, null, IRootAction>;
// const composeEnhancers =  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = compose;

const store = createStore<IRootState, IRootAction, {}, {}>(
  // export default createStore<IRootState, IRootAction, {}, {}>(
  appReducer,
  // pReducer,
  composeEnhancers(
    applyMiddleware(thunk),
    applyMiddleware(routerMiddleware(history))
  )
);
const persistor = persistStore(store);

export { persistor, store };
