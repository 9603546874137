import React, { useEffect, useState } from "react";
import { Navbar } from "../components/navbar";
import { PageTitle } from "../components/PageTitle";
import "../css/index.css";
import { useDispatch, useSelector } from "react-redux";
import styles from "../css/face.module.css";
import { LogoButton } from "../components/LogoButton";
import {
  faCamera,
  faCheck,
  faTimes,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { UploadModal } from "../components/UploadModal";
import { IRootState } from "../store";
import faceExample from "../assets/img/face_example.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  resetBio,
  resetFaceDynamicVerify,
  resetFaceVerify,
  sendFaceSuccess,
} from "../redux/bio/actions";
import { sendFaceCheckThunk, sendFaceThunk } from "../redux/bio/thunk";
import LoadingOverlay from "../components/LoadingOverlay";
import { push } from "connected-react-router";
import { resetPrevDynamicFace, resetPrevFace } from "../redux/media/actions";

const instructionList = [
  { icon: faCheck, text: "bio_face__face_center" },
  { icon: faTimes, text: "bio_face__no_wear_glasses" },
  { icon: faTimes, text: "bio_face__no_wear_accessories" },
  { icon: faTimes, text: "bio_face__no_wear_headwear" },
  { icon: faTimes, text: "bio_face__no_cover" },
  { icon: faTimes, text: "bio_face__no_shadow" },
];

interface Props {
  page: number;
  path: string;
  index: number;
  facePages: number;
}

export const Face: React.FC<Props> = ({ page, path, index, facePages }: Props) => {
  const dispatch = useDispatch();
  const token = useSelector((state: IRootState) => state.otp.caseToken);

  const isFaceVerified = useSelector(
    (state: IRootState) => state.bio.isFaceVerified
  );
  const isDynamicFaceVerified = useSelector(
    (state: IRootState) => state.bio.isDynamicFaceVerified
  );

  const prevFace = useSelector((state: IRootState) => state.media.prevFace);
  const prevDynamicFace = useSelector(
    (state: IRootState) => state.media.prevDynamicFace
  );

  const isSuccess = useSelector((state: IRootState) => state.bio.isSuccess);
  const isProcessing = useSelector(
    (state: IRootState) => state.bio.isProcessing
  );
  const errorMessage = useSelector(
    (state: IRootState) => state.bio.errorMessage
  );
  const locale = useSelector((state: IRootState) => state.setting.locale);
  const merchantId = useSelector((state: IRootState) => state.form.merchantId);

  const uploadedFace = useSelector((state: IRootState) => state.bio.face);
  const uploadedDynamicFace = useSelector(
    (state: IRootState) => state.bio.dynamicFace
  );

  const [face, setFace] = useState<File>();
  const [dynamicFace, setDynamicFace] = useState<File>();

  const [isOpen, setisOpen] = useState(false);
  const [ResponseText, setResponseText] = useState("");
  const { t } = useTranslation();

  const ModalContent = [{ title: "The face should place into the frame." }];

  const buttonFaceOnClick = (v: File) => {
    setFace(v);
  };

  const buttonDynamceFaceOnClick = (v: File) => {
    setDynamicFace(v);
  };

  useEffect(() => {
    if (!token || Object.values(token).length <= 0) {
      dispatch(push(`${path}`));
      return;
    }
  });

  useEffect(() => {
    if (uploadedFace) {
      setFace(uploadedFace);
    }

    if (uploadedDynamicFace) {
      setDynamicFace(uploadedDynamicFace);
    }
  }, [uploadedFace, uploadedDynamicFace]);

  useEffect(() => {
    if (!token || !merchantId) return;
    if (isFaceVerified) return;
    if (face) {
      dispatch(
        sendFaceThunk(
          token,
          merchantId,
          "face",
          face,
          (open: boolean, title: string) => {
            setisOpen(open);
            setResponseText(title);
          },
          path,
          t
        )
      );
    }
  }, [face]);

  useEffect(() => {
    if (!token || !merchantId) return;
    if (isDynamicFaceVerified) return;
    if (dynamicFace) {
      // dispatch(sendFaceCheckThunk(token, merchantId, 1, 'eyeClose', dynamicFace, setisOpen ));
      // dispatch(sendFaceCheckThunk(token, merchantId, 1, 'pitchUpward', dynamicFace, setisOpen ));
      dispatch(
        sendFaceCheckThunk(
          token,
          merchantId,
          1,
          "pitchUpward",
          dynamicFace,
          (open: boolean, title: string) => {
            setisOpen(open);
            setResponseText(title);
          },
          path,
          t
        )
      );
    }
  }, [dynamicFace]);

  useEffect(() => {
    if (isSuccess) {
      setFace(undefined);
      setDynamicFace(undefined);
      dispatch(resetBio());
      index >= facePages - 1 ? dispatch(push(`${path}/qa/0`)) : dispatch(push(`${path}/face/${index + 1}`))
    }
  }, [isSuccess, errorMessage]);

  return (
    <>
      <Navbar canGoBack={true} />
      <div className="container" id="id_card">
        <PageTitle page={page} title={t("bio_face__title")} />
        <div>
          <div className={styles.example_container}>
            <div className="contentText">{t("bio_face__example")}</div>
            <div className={styles.example}>
              <div className={styles.example_photo}>
                <img src={faceExample} />
              </div>
              <div className={styles.example_text}>
                {instructionList &&
                  instructionList.map((instruction, index) => (
                    <div key={index} className="modal_text">
                      <FontAwesomeIcon
                        icon={instruction.icon}
                        className="modal_icon"
                      />
                      {t(instruction.text)}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className={styles.image_container}>
            <div className={styles.subtitle}>{t("bio_face__step1")}</div>
            <div className="contentText">
              {t("bio_face__step1_instruction_before_bold")}
              <strong>{t("bio_face__step1_instruction_bold")}</strong>
              {t("bio_face__step1_instruction_after_bold")}
            </div>
            <div className={styles.dashedContainer}>
              <div className={styles.subtitle}>
                {t("bio_face__front_side_version")}
              </div>
              {!face && !prevFace && (
                <div style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%",
                }}>
                  <LogoButton
                    name={"bio_face__takephoto"}
                    icon={faCamera}
                    type={"front_side"}
                    onPress={buttonFaceOnClick}
                  />
                  {/* <div style={{ flex: 1 }} /> */}
                </div>
              )}
              {face && (
                <div style={{
                  display: "flex",
                  flexDirection:"column",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%",
                }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexWrap: "wrap",
                      width:"100px",
                      alignSelf:"center",
                    }}
                  >
                    <img
                      className={styles.faceImage}
                      src={URL.createObjectURL(face)}
                    />
                    {isFaceVerified === null ? (
                      <div className={styles.loading}>
                        <FontAwesomeIcon
                          icon={faCircleNotch}
                          color="white"
                          spin
                        ></FontAwesomeIcon>
                      </div>
                    ) : isFaceVerified ? (
                      <div className={styles.isVerified}>
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="white"
                        ></FontAwesomeIcon>
                      </div>
                    ) : (
                      <div className={styles.isNotVerified}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="white"
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                  <button
                    disabled={isProcessing}
                    className={isProcessing ? "disabledButton" : "button"}
                    onClick={() => {
                      dispatch(resetFaceVerify());
                      setFace(undefined);
                    }}
                  >
                    <div className={"buttonText"}>{t("retake")}</div>
                  </button>
                </div>
              )}
              {!face && prevFace && (
                <div style={{
                  display: "flex",
                  flexDirection:"column",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%"
                }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexWrap: "wrap",
                      width:"100px",
                      alignSelf:"center",
                    }}
                  >
                    <img
                      className={styles.faceImage}
                      src={`data:image/jpeg;base64, ${prevFace.base64}`}
                    />
                    <div className={styles.isVerified}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        color="white"
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                  <button
                    disabled={isProcessing}
                    className={isProcessing ? "disabledButton" : "button"}
                    onClick={() => {
                      dispatch(resetPrevFace());
                    }}
                  >
                    <div className={"buttonText"}>{t("retake")}</div>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.image_container}>
            <div className={styles.subtitle}>{t("bio_face__step2")}</div>
            <div className="contentText">
              {t("bio_face__step2_instruction_before_bold")}
              <strong>{t("bio_face__step2_instruction_up_bold")}</strong>
              {t("bio_face__step2_instruction_after_bold")}
            </div>
            <div className={styles.dashedContainer}>
              <div className={styles.subtitle}>
                {t("bio_face__head_up_version")}
              </div>
              {!dynamicFace && !prevDynamicFace && (
                <div style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%"
                }}>
                  <LogoButton
                    name={"bio_face__takephoto"}
                    icon={faCamera}
                    type={"eye-close"}
                    onPress={buttonDynamceFaceOnClick}
                  />
                  {/* <div style={{ flex: 1 }} /> */}
                </div>
              )}
              {dynamicFace && (
                <div style={{
                  display: "flex",
                  flexDirection:"column",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%",
                }}>
                  <div
                    style={{
                      position: "relative",
                      alignSelf:"center",
                      display: "flex",
                      flexWrap: "wrap",
                      width:"100px"
                    }}
                  >
                    <img
                      className={styles.faceImage}
                      src={URL.createObjectURL(dynamicFace)}
                    />
                    {isDynamicFaceVerified === null ? (
                      <div className={styles.loading}>
                        <FontAwesomeIcon
                          icon={faCircleNotch}
                          color="white"
                          spin
                        ></FontAwesomeIcon>
                      </div>
                    ) : isDynamicFaceVerified ? (
                      <div className={styles.isVerified}>
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="white"
                        ></FontAwesomeIcon>
                      </div>
                    ) : (
                      <div className={styles.isNotVerified}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="white"
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                  <button
                    disabled={isProcessing}
                    // className={
                    //   token &&
                    //   !isProcessing &&
                    //   (isFaceVerified || prevFace) &&
                    //   (isDynamicFaceVerified || prevDynamicFace)
                    //     ? "nextButton bottomMargin"
                    //     : "disabledButton bottomMargin"
                    // }
                    className={isProcessing ? "disabledButton" : "button"}
                    onClick={() => {
                      dispatch(resetFaceDynamicVerify());
                      setDynamicFace(undefined);
                    }}
                  >
                    <div className={"buttonText"}>{t("retake")}</div>
                  </button>
                </div>
              )}
              {!dynamicFace && prevDynamicFace && (
                <div style={{
                  display: "flex",
                  flexDirection:"column",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%"
                }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100px",
                      alignSelf: "center"
                    }}
                  >
                    <img
                      className={styles.faceImage}
                      src={`data:image/jpeg;base64, ${prevDynamicFace.base64}`}
                    />
                    <div className={styles.isVerified}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        color="white"
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                  <button
                    disabled={isProcessing}
                    className={isProcessing ? "disabledButton" : "button"}
                    onClick={() => {
                      dispatch(resetPrevDynamicFace());
                    }}
                  >
                    <div className={"buttonText"}>{t("retake")}</div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <button
          className={
            token &&
              !isProcessing &&
              (isFaceVerified || prevFace) &&
              (isDynamicFaceVerified || prevDynamicFace)
              ? "button"
              : "disabledButton"
          }
          onClick={() => {
            if (
              !(isFaceVerified || prevFace) ||
              !(isDynamicFaceVerified || prevDynamicFace) ||
              !token ||
              isProcessing
            )
              return;
            dispatch(sendFaceSuccess());
          }}
          disabled={
            !(
              (isFaceVerified || prevFace) &&
              (isDynamicFaceVerified || prevDynamicFace) &&
              token &&
              !isProcessing
            )
          }
        >
          <div className="buttonText">{t("next")}</div>
        </button>
      </div>
      <div id="upload_modal"></div>
      <UploadModal
        title={t(ResponseText)}
        buttonText={t("ok")}
        isOpen={isOpen}
        setisOpen={(v) => setisOpen(v)}
      />
      {/* <UploadModal title={"The face should place into the frame"} buttonText={t('ok')} isOpen={isOpen} setisOpen={(v) => setisOpen(v)} /> */}
    </>
  );
};
