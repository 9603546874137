import { CallHistoryMethodAction, push } from "connected-react-router";
import { Dispatch } from "react";
import { toast } from "react-toastify";
import { IOTPActions, clearToken } from "../otp/actions";
import {
  IImportantDisclosureActions,
  updateAgreeementProcessing,
  updateAgreeementSuccess,
  updateAgreementFailed,
} from "./acitons";
import { TFunction } from "react-i18next";

export function updateAgreementThunk(caseToken: string, path: string, t: TFunction<"translation">) {
  return async (
    dispatch: Dispatch<
      IImportantDisclosureActions | CallHistoryMethodAction | IOTPActions
    >
  ) => {
    try {
      dispatch(updateAgreeementProcessing());
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/case/${caseToken}/agreement`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      if (res.status == 200) {
        dispatch(updateAgreeementSuccess());
      } else if (res.status != 200) {
        dispatch(updateAgreementFailed("Server Unknown Problem"));
        toast.error(t(data.message));
        if (data.isExpired) {
          localStorage.clear();
          dispatch(clearToken());
          dispatch(push(`${path}`));
        }
      }
    } catch (err) {
      dispatch(updateAgreementFailed("Server Unknown Problem"));
    }
  };
}
