// import Compress from 'compress.js';

// export const imageResize = async (file: File) => {
//     const compress = new Compress();
//     const resizedImage = await compress.compress([file], {
//         size: 2, // the max size in MB, defaults to 2MB
//         quality: 0.75, // the quality of the image, max is 1,
//         maxWidth: 500, // the max width of the output image, defaults to 1920px
//         maxHeight: 500, // the max height of the output image, defaults to 1920px
//         resize: true // defaults to true, set false if you do not want to resize the image width and height
//     })
//     console.log('resizedimage', resizedImage)
//     const img = resizedImage[0];
//     const base64str = img.data
//     // console.log('base64str', base64str)
//     const imgExt = img.ext
//     const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
//     return resizedFiile;
// }

//@ts-ignore
import Compress from 'client-compress';
const compress = new Compress({targetSize: 2, maxWidth: 500, maxHeight:500})

export const imageResize = async (file: File) => {
    const resizedFile = await compress.compress([file])
    return resizedFile?.[0]?.photo?.data
}