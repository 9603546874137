import React from "react";

interface Props {
  page?: number;
  title?: string;
}

export const PageTitle: React.FC<Props> = ({ page, title }: Props) => {
  return (
    <div className="titleBox">
      {page && (
        <div className="page">
          {page}/{10}
        </div>
      )}
      {title && <div className="title">{title}</div>}
    </div>
  );
};
