import { toast } from "react-toastify";
import React from "react";
import { config } from "../../config";
import { ThunkDispatch } from "../../store";
import {
  sendFaceFailure,
  sendFaceProcessing,
  verifyFace,
  verifyFaceDynamic,
} from "./actions";
import { imageResize } from "../../utils/imageResize";
import * as dotenv from "dotenv";
import { clearToken } from "../otp/actions";
import { push } from "connected-react-router";
import { TFunction } from "react-i18next";
dotenv.config();

export function sendFaceThunk(
  token: string,
  merchantId: number,
  type: string,
  file: File,
  setIsOpen: (open: boolean, title: string) => void,
  path: string,
  t: TFunction<"translation">
) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(sendFaceProcessing());
      const resizedFile = await imageResize(file);
      const formData = new FormData();
      formData.append("token", token);
      formData.append("merchantId", JSON.stringify(merchantId));
      formData.append("type", type);
      formData.append("file", resizedFile);

      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/bio/face`, {
        method: "POST",
        body: formData,
      });
      const data = await res.json();
      if (res.status == 200) {
        dispatch(verifyFace(true, file));
      } else if (res.status != 200) {
        dispatch(verifyFace(false, null));
        dispatch(sendFaceFailure("Internal Server Error"));
        setIsOpen(true, data.message);
        if (data.isExpired) {
          localStorage.clear();
          dispatch(clearToken());
          toast.error(t(data.message));
          dispatch(push(`${path}`));
        }
      }
    } catch (err) {
      dispatch(sendFaceFailure("Internal Server Error"));
    }
  };
}

export function sendFaceCheckThunk(
  token: string,
  merchantId: number,
  profileId: number,
  type: string,
  file: File,
  setIsOpen: (open: boolean, title: string) => void,
  path: string,
  t: TFunction<"translation">
) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(sendFaceProcessing());
      const resizedFile = await imageResize(file);
      const formData = new FormData();
      formData.append("token", token);
      formData.append("merchantId", JSON.stringify(merchantId));
      formData.append("profileId", JSON.stringify(profileId));
      formData.append("type", type);
      formData.append("file", resizedFile);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/bio/face/check`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await res.json();
      if (res.status == 200) {
        dispatch(verifyFaceDynamic(true, file));
        // if(type === "pitch-downward") dispatch(verifyFacePitchHead(true));
        // if(type === "eye-close") dispatch(verifyFaceCloseEye(true));
      } else if (res.status != 200) {
        dispatch(sendFaceFailure("Internal Server Error"));
        dispatch(verifyFaceDynamic(false, null));
        // if(type === "pitch-downward") dispatch(verifyFacePitchHead(false));
        // if(type === "eye-close") dispatch(verifyFaceCloseEye(false));
        setIsOpen(true, data.message);
        if (data.isExpired) {
          localStorage.clear();
          dispatch(clearToken());
          toast.error(t(data.message));
          dispatch(push(`${path}`));
        }
      }
    } catch (err) {
      dispatch(sendFaceFailure("Internal Server Error"));
    }
  };
}
