import "../../App.css";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { IRootState } from "../../store";
import { ReactivateOTP } from "./reactivateOtp";
import "react-toastify/dist/ReactToastify.css";
// import { Helmet } from "react-helmet";

//multiple language
import "../../i18n";
import { useTranslation } from "react-i18next";
import { Maintenance } from "../maintainance";
import React, { useEffect } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { Loading } from "../loading";
import { verifyTokenWithProfile } from "../../redux/otp/thunk";
import { useLocation } from "react-router-dom";

function ReactivateRoutes() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const forms = useSelector((state: IRootState) => state.form.form);
  const qa = useSelector((state: IRootState) => state.form.qa);
  const importantDisclosure = useSelector(
    (state: IRootState) => state.form.importantDisclosure
  );
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const isProcesssing = useSelector(
    (state: IRootState) => state.media.isProcessing
  );
  const isFormProcessing = useSelector(
    (state: IRootState) => state.form.isProcessing
  );

  const { hash } = useParams<Record<string, string | undefined>>();
  const profileId = useSelector((state: IRootState) => state.form.profileId);
  let { path, url } = useRouteMatch();

  const { pathname } = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  return (
      <Switch>
        <Route
          path={`${path}`}
          render={() => <ReactivateOTP />}
        />
      </Switch>
  );
}

export default ReactivateRoutes;
