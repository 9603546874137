import React, { useEffect, useState } from "react";
import { Navbar } from "../components/navbar";
import { push } from "connected-react-router";
import { PageTitle } from "../components/PageTitle";
import "../css/index.css";
import { useDispatch, useSelector } from "react-redux";
import idFrontSample from "../assets/img/id_front.png";
import idBackSample from "../assets/img/id_back.png";
import styles from "../css/idCard.module.css";
import { LogoButton } from "../components/LogoButton";
import { faVideo, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { UploadModal } from "../components/UploadModal";
import { IRootState } from "../store";
import { resetDocument } from "../redux/document/actions";
import { VideoButton } from "../components/VideoButton";
import { sendVideoThunk } from "../redux/document/thunk";
import { toast } from "react-toastify";
import LoadingOverlay from "../components/LoadingOverlay";
import { resetPrevVideo } from "../redux/media/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import demoVideo from "../assets/video/face/face_demo.mp4";

interface Props {
  page: number;
  path: string;
}

export const Video: React.FC<Props> = ({ page, path }: Props) => {
  const dispatch = useDispatch();
  const token = useSelector((state: IRootState) => state.otp.caseToken);
  const isSuccess = useSelector(
    (state: IRootState) => state.document.isSuccess
  );
  const isProcessing = useSelector(
    (state: IRootState) => state.document.isProcessing
  );
  const errorMessage = useSelector(
    (state: IRootState) => state.document.errorMessage
  );
  const uploadedvideo = useSelector(
    (state: IRootState) => state.document.video
  );
  const prevVideo = useSelector(
    (state: IRootState) => state.media.prevVideo?.base64
  );
  const profileId = useSelector((state: IRootState) => state.form.profileId);
  const merchantId = useSelector((state: IRootState) => state.form.merchantId);
  const locale = useSelector((state: IRootState) => state.setting.locale);

  const [video, setVideo] = useState<File>();
  const [isOpen, setisOpen] = useState(false);
  const [videoDur, setVideoDur] = useState(0);
  const [percentage, setPercentage] = useState<number | string>(0);

  const { t } = useTranslation();

  const buttonOnClick = (v: File) => {
    setVideo(v);
  };

  useEffect(() => {
    if (!token || Object.values(token).length <= 0) {
      dispatch(push(`${path}`));
      return;
    }
  });

  useEffect(() => {
    if (uploadedvideo) {
      setVideo(uploadedvideo);
    }
  }, [uploadedvideo]);

  useEffect(() => {
    if (!video) return;
    if (video.size > 0) {
      let videoElement = document.createElement("video");
      let fileURL = URL.createObjectURL(video);
      videoElement.src = fileURL;
      videoElement.onloadedmetadata = function () {
        if (videoElement.duration) setVideoDur(videoElement.duration);
      };
    }
  }, [video]);

  useEffect(() => {
    if (isSuccess) {
      setVideo(undefined);
      dispatch(resetDocument());
      dispatch(push(`${path}/face/0`));
    }
  }, [isSuccess, errorMessage]);

  useEffect(() => {
    if (videoDur > 5) {
      toast.error(t("video_warning_too_long"));
      setVideo(undefined);
      setVideoDur(0);
    }
  }, [videoDur]);

  return (
    <>
      <LoadingOverlay isProcessing={isProcessing} />
      <Navbar canGoBack={true} />
      <div className="container" id="id_card">
        <PageTitle page={page} title={t("doc_vidoe__title")} />
        <div>
          <div className="contentText">{t("video__chn_id_title")}</div>
          <div className="contentText">{t("doc_video__example")}</div>
          <div className={styles.dashedContainer}>
            <div className={styles.subtitle}>{t("example__video")}</div>
            <video
              style={{ alignSelf: "center" }}
              width="250"
              controls
              muted
              playsInline
            >
              <source
                src={demoVideo}
                type="video/mp4"
              ></source>
            </video>
          </div>
          <div className="contentText">
            {t("doc_video__self_video_with_colon")}
          </div>
          <div className={styles.dashedContainer}>
            <div className={styles.subtitle}>{t("doc_video__self_video")}</div>
            {!video && !prevVideo && (
              <div style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                width: "100%",
              }}>
                <VideoButton
                  name={"doc_video__record"}
                  icon={faVideo}
                  type={"video"}
                  onPress={buttonOnClick}
                />
                {/* <div style={{ flex: 1 }} /> */}
              </div>
            )}
            {video && (
              <div
                style={{
                  display: "flex",
                  flexDirection:'column',
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignContent:'center',
                  width: "100%",
                  maxWidth: "300px"
                }}
              >
                <video
                  style={{ alignSelf: "center" }}
                  width="250"
                  controls
                  muted
                  playsInline
                >
                  <source
                    src={URL.createObjectURL(video)}
                    type="video/mp4"
                  ></source>
                </video>
                <button
                  className={"button"}
                  onClick={() => setVideo(undefined)}
                >
                  <div className={"buttonText"}>{t("retake")}</div>
                </button>
              </div>
            )}
            {!video && prevVideo && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%",
                  maxWidth: "300px"
                }}
              >
                <video
                  style={{ alignSelf: "flex-start" }}
                  width="250"
                  controls
                  muted
                  playsInline
                >
                  <source src={`data:image/jpeg;base64, ${prevVideo}`} type="video/mp4"></source>
                </video>
                <button
                  className={"button"}
                  onClick={() =>
                    // setVideo(undefined)
                    dispatch(resetPrevVideo())
                  }
                >
                  <div className={"buttonText"}>{t("retake")}</div>
                </button>
              </div>
            )}
          </div>
        </div>

        <button
          className={
            (video || prevVideo) && token && !isProcessing
              ? "button"
              : "disabledButton"
          }
          onClick={() => {
            if (!(video || prevVideo) || !token || !profileId || !merchantId)
              return;
            if (video)
              dispatch(
                sendVideoThunk(
                  token,
                  merchantId,
                  profileId,
                  video,
                  setisOpen,
                  setPercentage,
                  path,
                  t
                )
              );
            if (prevVideo) dispatch(push(`${path}/face/0`));
          }}
          disabled={!((video || prevVideo) && token && !isProcessing)}
        >
          <div className="buttonText">{t("next")}</div>
        </button>
      </div>
      <div id="upload_modal"></div>
    </>
  );
};
