
export function updateLanguageSuccess(language: any){
    return {
        type: "@@setting/UPDATE_LANGUAGE_SUCCESS" as "@@setting/UPDATE_LANGUAGE_SUCCESS",
        language: language
    }  
}

export function updateLocaleSuccess(locale: string){
    return {
        type: "@@setting/UPDATE_LOCALE_SUCCESS" as "@@setting/UPDATE_LOCALE_SUCCESS",
        isSuccess: true,
        locale: locale
    } 
}

export function updateLocaleFinish(){
    return {
        type: "@@setting/UPDATE_LOCALE_FINISH" as "@@setting/UPDATE_LOCALE_FINISH",
        isSuccess: false,
    } 
}

type FAILED = "@@setting/UPDATE_LANGUAGE_FAIL"  | "@@setting/UPDATE_LOCALE_FAIL"

export function failed(type: FAILED, message: string) {
    return {
        type,
        message
    }
}

type SettingActionCreators =
typeof updateLanguageSuccess |
typeof updateLocaleSuccess |
typeof updateLocaleFinish |
typeof failed

export type ISettingActions = ReturnType<SettingActionCreators>
