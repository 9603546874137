import { Case } from "../../types/index";
import { Form } from "../../types/index";

export function sendCaseProcessing() {
  return {
    type: "@@form/SEND_CASE_PROCESSING" as "@@form/SEND_CASE_PROCESSING",
    isProcessing: true,
    isSuccess: false,
  };
}

export function sendCaseSuccess(caseData: Partial<Case>) {
  return {
    type: "@@form/SEND_CASE_SUCCESS" as "@@form/SEND_CASE_SUCCESS",
    caseData: caseData,
    isProcessing: false,
    isSuccess: true,
  };
}

export function sendCaseFailure(errorMessage: string) {
  return {
    type: "@@form/SEND_CASE_FAILURE" as "@@form/SEND_CASE_FAILURE",
    isProcessing: false,
    isSuccess: false,
    errorMessage: errorMessage,
  };
}

export function loadCaseProcessing() {
  return {
    type: "@@form/LOAD_CASE_PROCESSING" as "@@form/LOAD_CASE_PROCESSING",
    isProcessing: true,
  };
}

export function loadCaseSuccess(caseData: Partial<Case>) {
  return {
    type: "@@form/LOAD_CASE_SUCCESS" as "@@form/LOAD_CASE_SUCCESS",
    caseData: caseData,
    isProcessing: false,
  };
}

export function loadCaseFailure() {
  return {
    type: "@@form/LOAD_CASE_FAILURE" as "@@form/LOAD_CASE_FAILURE",
    isProcessing: false,
    isSuccess: false,
  };
}

export function loadFormProcessing() {
  return {
    type: "@@form/LOAD_FORM_PROCESSING" as "@@form/LOAD_FORM_PROCESSING",
    isProcessing: true,
  };
}

export function loadFormSuccess({
  form,
  qa,
  profileId,
  merchantId,
  merchantName,
  icon,
  importantDisclosure,
  idText,
  idMedia,
  contentPageOfOTP,
  contentPageOfBiometric
}: {
  form: Form[];
  qa: Form[];
  profileId: number;
  merchantId: number;
  merchantName: string;
  icon: string;
  importantDisclosure: any;
  idText: any[];
  idMedia: any[];
  contentPageOfOTP: any;
  contentPageOfBiometric: any;
}) {
  return {
    type: "@@form/LOAD_FORM_SUCCESS" as "@@form/LOAD_FORM_SUCCESS",
    form: form,
    qa: qa,
    isProcessing: false,
    formTotalPage: form.length,
    qaTotalPage: qa.length,
    profileId: profileId,
    merchantId: merchantId,
    merchantName: merchantName,
    icon: icon,
    importantDisclosure: importantDisclosure,
    idText: idText,
    idMedia: idMedia,
    contentPageOfOTP: contentPageOfOTP,
    contentPageOfBiometric: contentPageOfBiometric,
  };
}

export function loadFormFailure() {
  return {
    type: "@@form/LOAD_FORM_FAILURE" as "@@form/LOAD_FORM_FAILURE",
    isProcessing: false,
    isSuccess: false,
  };
}

export function submitFormSuccess() {
  return {
    type: "@@form/SUBMIT_FORM_SUCCESS" as "@@form/SUBMIT_FORM_SUCCESS",
    isProcessing: false,
    isSubmitted: true,
  };
}

export function submitFormFailure() {
  return {
    type: "@@form/SUBMIT_FORM_FAILURE" as "@@form/SUBMIT_FORM_FAILURE",
    isProcessing: false,
    isSubmitted: false,
  };
}

export function submitFormProcessing() {
  return {
    type: "@@form/SUBMIT_FORM_PROCESSING" as "@@form/SUBMIT_FORM_PROCESSING",
    isProcessing: true,
  };
}

export function resetForm() {
  return {
    type: "@@form/RESET" as "@@form/RESET",
    isProcessing: false,
    isSuccess: false,
  };
}

// type FAILED = "@@otp/SEND_OTP_FAIL"

// export function failed(type: FAILED, errorMessage: string) {
//     return {
//         type,
//         isProcessing: false,
//         isVerified: false,
//         errorMessage
//     }
// }

type FormActionCreators =
  | typeof loadCaseProcessing
  | typeof loadCaseSuccess
  | typeof loadCaseFailure
  | typeof sendCaseProcessing
  | typeof sendCaseSuccess
  | typeof sendCaseFailure
  | typeof loadFormProcessing
  | typeof loadFormSuccess
  | typeof loadFormFailure
  | typeof submitFormProcessing
  | typeof submitFormSuccess
  | typeof submitFormFailure
  | typeof resetForm;

export type IFormActions = ReturnType<FormActionCreators>;
