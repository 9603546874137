import React from 'react';
import { Navbar } from '../components/navbar';
import { PageTitle } from '../components/PageTitle';
import '../css/index.css'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IRootState } from '../store';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';

export const Complete: React.FC = () =>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const CaseData = useSelector((state:IRootState)=>state.form.caseData)
    return (
        <>
            <Navbar canGoBack={false}/>
            <div className="container">
                <PageTitle title={''}/>
                <div className="completeContainer">
                    <div className="isVerified">
                        <FontAwesomeIcon icon={faCheck} color="white"></FontAwesomeIcon>
                    </div>
                    <div className="title">{t("completed_title")}</div>
                    <div className="contentText"> {t('completed_content')}</div>
                </div>
                {/* <button 
                    className="button"
                    onClick={()=>{
                        dispatch(push('/'));
                    }}> 
                    <div className="buttonText">{t("OK")}</div>    
                </button> */}
            </div>
        </>
    )
}
