import { Dispatch } from "react";
import { config } from "../../config";
import { ThunkDispatch } from "../../store";
import {
  loadQAProcessing,
  loadQASuccess,
  sendQAProcessing,
  sendQASuccess,
  sendQAFailure,
  IQAActions,
  loadQAFailure,
  // loadQAFormProcessing, loadQAFormSuccess, loadQAFormFailure
} from "./actions";
import { Case } from "../../types";
import { toast } from "react-toastify";

import { IOTPActions, clearToken } from "../otp/actions";

import * as dotenv from "dotenv";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { TFunction } from "react-i18next";
dotenv.config();

export function QASubmitThunk(
  caseToken: string,
  caseData: Partial<Case>,
  currentPage: number,
  path: string,
  t: TFunction<"translation">
) {
  return async (
    dispatch: Dispatch<IQAActions | CallHistoryMethodAction | IOTPActions>
  ) => {
    try {
      dispatch(sendQAProcessing());
      const body = JSON.stringify({ ...caseData, current_page: currentPage });
      const encrpted = encrytion(body);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/case/${caseToken}/qa`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ value: encrpted }),
        }
      );
      const data = await res.json();
      if (res.status == 200) {
        let qaData: any = {};
        data?.form?.forEach(
          (data: {
            name: string;
            value: string;
            label?: string;
            element?: string;
            selected_label?: string;
          }) => {
            let selected_label = null;
            if (data?.selected_label)
              selected_label = JSON.parse(data?.selected_label);
            if (data?.element == "Checkboxes") {
              if (qaData[data.name]) {
                qaData[data.name] = [
                  ...qaData[data.name],
                  {
                    value: data.value,
                    label: data?.label,
                    element: data?.element,
                    selected_label: selected_label,
                  },
                ];
              } else {
                qaData[data.name] = [
                  {
                    value: data.value,
                    label: data?.label,
                    element: data?.element,
                    selected_label: selected_label,
                  }
                ];
              }
            } else {
              qaData[data.name] = {
                value: data.value,
                label: data?.label,
                element: data?.element,
                selectedValue: data?.selected_label,
              };
            }
          }
        );
        dispatch(sendQASuccess(qaData));
      } else if (res.status != 200) {
        dispatch(sendQAFailure("Internal Server Error"));
        toast.error(t(data.message));
        if (data.isExpired) {
          localStorage.clear();
          dispatch(clearToken());
          dispatch(push(`${path}`));
        }
      }
    } catch (err) {
      dispatch(sendQAFailure("Server Connection Problem"));
    }
  };
}

export function loadQAThunk(caseToken: string) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(loadQAProcessing());
      // const res = await fetch(`${config.BACKEND_URL}/case/${caseToken}/qa`, {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/case/${caseToken}/qa`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      let qaData: any = {};
      data.forEach(
        (data: {
          name: string;
          value: string;
          label?: string;
          element?: string;
          selected_label?: string;
        }) => {
          let selected_label = null;
          if (data?.selected_label)
            selected_label = JSON.parse(data?.selected_label);
          if (!qaData[data.name]) {
            qaData[data.name] = {
              value: data.value,
              label: data?.label,
              element: data?.element,
              selected_label: selected_label,
            };
          } else {
            //for same field name , eg. dropboxes and checkboxes
            if (Array.isArray(qaData[data.name])) {
              qaData[data.name] = [
                ...qaData[data.name],
                {
                  value: data.value,
                  label: data?.label,
                  element: data?.element,
                  selected_label: selected_label,
                },
              ];
            } else {
              qaData[data.name] = [
                qaData[data.name],
                {
                  value: data.value,
                  label: data?.label,
                  element: data?.element,
                  selected_label: selected_label,
                },
              ];
            }
          }
        }
      );
      if (res.status == 200) {
        dispatch(loadQASuccess(qaData));
      } else if (res.status != 200) {
        dispatch(loadQAFailure());
        toast.error("cannot connect to server.");
      }
    } catch (err) {
      dispatch(loadQAFailure());
    }
  };
}

const encrytion = (message: any) => {
  return Buffer.from(message).toString("base64");
};
