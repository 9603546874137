import React, { useEffect, useState } from "react";
import {
  FieldValues,
  useForm,
  UseFormRegister,
  UseFormSetValue,
  useFormState,
  UseFormUnregister,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { QASubmitThunk } from "../../redux/qa/thunk";
import styles from "../../css/form.module.css";
import { Case, Option, Element, Question } from "../../types/index";
import { Navbar } from "../navbar";
import { IRootState } from "../../store";
import { useLocation } from "react-router-dom";
import { push } from "connected-react-router";
import { resetForm } from "../../redux/qa/actions";
import { TextInput } from "./TextInput";
import { Selector } from "./Selector";
import { CheckBoxes } from "./CheckBoxes";
import Download from "./Download";
import { RadioButtons } from "./RadioButtons";
import { Label } from "./Label";
import { LineBreak } from "./LineBreak";
import { FormDatePicker } from "./DatePicker";
import { Header } from "./Header";
import LoadingOverlay from "../LoadingOverlay";
import { PageTitle } from "../PageTitle";
import Signature from "./Signature";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NumberInput } from "./NumberInput";
import { TextArea } from "./TextArea";
import { useTranslation } from "react-i18next";
import { AdditionalQuestion } from "./AdditionalQuestion";
import { AdditionalQuestionCheckbox } from "./AdditionalQuestionCheckbox";
import { FormDateSelector } from "./DateSelector";

interface Props {
  page: number;
  form?: any;
  index: number;
  path: string;
}

export const QA: React.FC<Props> = ({ page, form, index, path }: Props) => {
  let pageNumber = index;
  //@ts-ignore
  // let { pageNumber } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
    setValue,
    control,
    trigger,
    unregister,
    watch,
    setError,
  } = useForm({ shouldFocusError: true , mode: 'onChange', reValidateMode: 'onChange'});
  const { isValid } = useFormState({ control });
  const { t } = useTranslation();

  const totalPage = useSelector((state: IRootState) => state.form.qaTotalPage);
  const qaLength = useSelector((state: IRootState) => state.form.qa);

  const isSuccess = useSelector((state: IRootState) => state.qa.isSuccess);
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const qaData = useSelector((state: IRootState) => state.qa.qaData);
  const [questions, setQuestions] =
    useState<Question[][] | Question[] | null>(null);
  const [repQuestions, setRepQuestions] = useState<Question[][]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const isProcesing = useSelector((state: IRootState) => state.qa.isProcessing);

  useEffect(() => {
      const err = Object.keys(errors);
      if (err.length > 0) {
          const input = document.querySelector(
              `input[name="${err[0]}"]`
          );
          const closeLabel = input?.nextElementSibling ? input?.nextElementSibling : input?.parentElement;
          
          if (closeLabel) {
            closeLabel.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
      };
  }, [formState])

  useEffect(() => {
    let currentQAData: any = {};
    const keys = Object.keys(qaData);
    keys.forEach((key: string) => {
      currentQAData[key] = qaData[key]?.value;
    });
    reset({ ...currentQAData });
    if (!totalPage) return;
    if (pageNumber <= totalPage - 1 && qaLength?.[0]?.data != "[]") {
      setQuestions(JSON.parse(form?.data));
    } else if (pageNumber >= totalPage - 1 && qaLength && qaLength.length == 0) {
      dispatch(push(`${path}/preview`));
    } else {
      dispatch(push(`${path}/preview`));

    }
  }, []);

  useEffect(() => {
    let currentQAData: any = {};
    const keys = Object.keys(qaData);
    keys.forEach((key: string) => {
      currentQAData[key] = Array.isArray(qaData[key])
        ? qaData[key].map((field: any) => field.value)
        : qaData[key]?.value;
    });
    reset({ ...currentQAData });
  }, [qaData]);

  useEffect(() => {
    reset();
  }, [questions]);

  useEffect(() => {
    if (!totalPage) return;
    if (pageNumber <= totalPage - 1) {
      setQuestions(JSON.parse(form?.data));
    }
  }, [form, pageNumber]);

  useEffect(() => {
    const nextPage = pageNumber + 1;
    if (isSuccess) {
      dispatch(resetForm());
      if (!totalPage) return;
      if (pageNumber >= totalPage - 1) {
        dispatch(push(`${path}/preview`));
      } else {
        dispatch(push(`${path}/qa/${nextPage}`));
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!totalPage) return;
    if (pageNumber > totalPage - 1) dispatch(push(`${path}/preview`));
  }, [totalPage]);

  const onSubmit = (data: Partial<Case>) => {
    if (!caseToken) return;
    const currentPage = pageNumber + 1;
    dispatch(QASubmitThunk(caseToken, data, currentPage, path, t));
  };

  const ElementGenerator = (
    question: Question,
    index: number,
    register: UseFormRegister<FieldValues>,
    unregister: UseFormUnregister<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    component: boolean,
    repetitive?: boolean,
  ) => {
    switch (question.element) {
      case "Header":
        return <Header question={question} index={index} />;
      case "Label":
        return <Label question={question} index={index} />;
      case "Checkboxes":
        return (
          <CheckBoxes
            question={question}
            index={index}
            unregister={unregister}
            register={register}
            setValue={setValue}
            watch={watch}
          />
        );
      case "LineBreak":
        return <LineBreak />;
      case "TextInput":
        return (
          <TextInput
            question={question}
            index={index}
            register={register}
            unregister={unregister}
            setValue={setValue}
            errors={errors}
            component={component}
          />
        );
      case "RadioButtons":
        return (
          <RadioButtons
            question={question}
            setValue={setValue}
            watch={watch}
            setError={setError}
            register={register}
            unregister={unregister}
          />
        );
      case "Dropdown":
        return (
          <Selector
            question={question}
            index={index}
            register={register}
            unregister={unregister}
            elementGenerator={ElementGenerator}
            watch={watch}
            setValue={setValue}
            repetitive={repetitive}
          />
        );
      // case "DatePicker":
      //   return (
      //     <FormDatePicker
      //       question={question}
      //       index={index}
      //       // minDate={question.minDate}
      //       // maxDate={question.maxDate}
      //       register={register}
      //       setValue={setValue}
      //       errors={errors}
      //       unregister={unregister}
      //       component={component}
      //       setError={setError}
      //     />
      //   );
      case "DatePicker":
        return question.beforeToday || question.afterToday ? (
          <FormDatePicker
            question={question}
            index={index}
            // minDate={question.minDate}
            // maxDate={question.maxDate}
            register={register}
            setValue={setValue}
            errors={errors}
            unregister={unregister}
            component={component}
            setError={setError}
          />
        ) : (
          <FormDateSelector
            question={question}
            index={index}
            // minDate={question.minDate}
            // maxDate={question.maxDate}
            register={register}
            setValue={setValue}
            errors={errors}
            unregister={unregister}
            component={component}
            setError={setError}
          />
        );
      case "Signature":
        return (
          <Signature
            question={question}
            setValue={setValue}
            register={register}
            watch={watch}
          />
        );
      case "NumberInput":
        return (
          <NumberInput
            question={question}
            index={index}
            register={register}
            unregister={unregister}
            setValue={setValue}
          />
        );
      case "TextArea":
        return (
          <TextArea
            question={question}
            index={index}
            register={register}
            unregister={unregister}
            setValue={setValue}
          />
        );
      case "Download":
        return (
          <Download
            uploadedFileName={""}
            setIsProcessing={(isProcessing: boolean) => {
              setIsUploading(isProcessing);
            }}
            register={register}
            setValue={setValue}
            watch={watch}
            question={question}
            onChange={() => {
            
            }}
          />
        );
      case "AdditionalQuestion":
        return (
          <AdditionalQuestion
            question={question}
            elementGenerator={ElementGenerator}
            setValue={setValue}
            watch={watch}
            setError={setError}
            register={register}
            unregister={unregister}
          />
        )
      case "AdditionalQuestionCheckbox":
        return (
          <AdditionalQuestionCheckbox
            question={question}
            elementGenerator={ElementGenerator}
            setValue={setValue}
            watch={watch}
            setError={setError}
            register={register}
            unregister={unregister}
          />
        )
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar isValid={isValid} trigger={trigger} canGoBack={true} />
      <div className="container">
        <PageTitle page={page} title={t("qa__title")} />
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          {questions &&
            questions.map((question: Question[] | Question, index: number) => {
              //repeat Question
              if (Array.isArray(question)) {
                return (
                  <React.Fragment key={index}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        type="button"
                        style={{ paddingLeft: "10px" }}
                        onClick={() => {
                          let tempRepQuestions = [...repQuestions];
                          tempRepQuestions.push([...question]);
                          setRepQuestions(tempRepQuestions);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
                      </button>
                      <button
                        type="button"
                        style={{ paddingLeft: "10px" }}
                        onClick={() => {
                          let tempRepQuestions = [...repQuestions];
                          tempRepQuestions.pop();
                          setRepQuestions(tempRepQuestions);
                        }}
                      >
                        <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
                      </button>
                    </div>
                    {repQuestions?.map((quest, index) =>
                      quest?.map((addQuest, idx) => (
                        <React.Fragment key={idx}>
                          {ElementGenerator(
                            {
                              ...addQuest,
                              id: `additional[${index}].${addQuest.id}`,
                            },
                            index,
                            register,
                            unregister,
                            setValue,
                            false,
                            true
                          )}
                          {errors?.["additional"]?.[index]?.[addQuest.id] &&
                            errors?.["additional"]?.[index]?.[addQuest.id]
                              ?.type == "required" && (
                              <div
                                key={`${addQuest.id}_${index}_error_${index}`}
                                className={styles.errorText}
                              >
                                {t("warning__required")}
                              </div>
                            )}
                          {errors?.["additional"]?.[index]?.[addQuest.id] &&
                            errors?.["additional"]?.[index]?.[addQuest.id]
                              ?.type == "pattern" && (
                              <div
                                key={`${addQuest.id}_${index}_error_${index}`}
                                className={styles.errorText}
                              >
                                {t("warning__invalid__format")}
                              </div>
                            )}
                          {errors?.["additional"]?.[index]?.[addQuest.id] &&
                            errors?.["additional"]?.[index]?.[addQuest.id]
                              ?.type == "maxLength" && (
                              <div
                                key={`${addQuest.id}_${index}_error_${index}`}
                                className={styles.errorText}
                              >
                                {t("warning__need__maximum")}{" "}
                                {addQuest.maxLength} {t("warning__characters")}
                              </div>
                            )}
                          {errors?.["additional"]?.[index]?.[addQuest.id] &&
                            errors?.["additional"]?.[index]?.[addQuest.id]
                              ?.type == "minLength" && (
                              <div
                                key={`${addQuest.id}_${index}_error_${index}`}
                                className={styles.errorText}
                              >
                                {t("warning__need__minimum")}{" "}
                                {addQuest.minLength} {t("warning__characters")}
                              </div>
                            )}
                        </React.Fragment>
                      ))
                    )}
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    {ElementGenerator(
                      question,
                      index,
                      register,
                      unregister,
                      setValue,
                      false
                    )}
                    {errors[`${question.id}`] &&
                      errors[`${question.id}`].type == "required" && (
                        <div
                          key={`${question.id}_error_${index}`}
                          className={styles.errorText}
                        >
                          {t("warning__required")}
                        </div>
                      )}
                    {errors[`${question.id}`] &&
                      errors[`${question.id}`].type == "pattern" && (
                        <div
                          key={`${question.id}_error_${index}`}
                          className={styles.errorText}
                        >
                          {t("warning__invalid__format")}
                        </div>
                      )}
                    {errors[`${question.id}`] &&
                      errors[`${question.id}`].type == "maxLength" && (
                        <div
                          key={`${question.id}_error_${index}`}
                          className={styles.errorText}
                        >
                          {t("warning__need__maximum")}{" "}
                          {question.maxLength} {t("warning__characters")}
                        </div>
                      )}
                    {errors[`${question.id}`] &&
                      errors[`${question.id}`].type == "minLength" && (
                        <div
                          key={`${question.id}_error_${index}`}
                          className={styles.errorText}
                        >
                          {t("warning__need__minimum")}{" "}
                          {question.minLength} {t("warning__characters")}
                        </div>
                      )}
                  </React.Fragment>
                );
              }
            })}
          <button
            className={isProcesing || isUploading ? "disabledButton" : "button"}
            disabled={isProcesing || isUploading}
            style={{ marginTop: "20px" }}
            onClick={handleSubmit(onSubmit)}
          >
            <div className="buttonText">{t("next")}</div>
          </button>
          <LoadingOverlay isProcessing={isProcesing} isSuccess={isSuccess} />
        </form>
      </div>
    </>
  );
};
