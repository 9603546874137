import React, { Fragment, useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import { push } from "connected-react-router";
import { PageTitle } from "../../components/PageTitle";
import "../../css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "../../store";
import {
  readAllTextFailed,
  readAllTextSuccess,
} from "../../redux/important_disclosure/acitons";

interface Props {
  content: any;
  index: number;
  totalPage: number;
  page: number;
  path: string;
  onSubmit: () => void;
}

export const IDText: React.FC<Props> = ({ content, index, totalPage, page, path, onSubmit }: Props) => {
  const dispatch = useDispatch();
  const token = useSelector((state: IRootState) => state.otp.caseToken);
  // const [Ended, setEnded] = useState<Boolean>(false);
  const readAllText = useSelector(
    (state: IRootState) => state.importantDisclosure.readAllText
  );
  const [isSubmit, setIsSubmit] = useState(false);
  const language = useSelector((state: IRootState) => state.setting.language);
  const importantDisclosures = useSelector(
    (state: IRootState) => state.form.importantDisclosure
  );
  const { t } = useTranslation();

  const isSuccess = useSelector(
    (state: IRootState) => state.importantDisclosure.isSuccess
  );

  const handleNext = () => {
    dispatch(readAllTextFailed())
    dispatch(push(`${path}/important_disclosures/id_text/${index + 1}`));
  }

  // const isBottom = (el: HTMLElement) => {
  //   console.log("el.scrollHeight", el.scrollHeight);
  //   console.log("el.scrollTop", el.scrollTop);
  //   console.log("window.scrollY", window.scrollY);
  //   console.log("el.clientHeight", el.clientHeight);
  // return el.scrollHeight - Math.abs(el.scrollTop) === el.clientHeight;
  // return window.scrollY + window.innerHeight === document.body.offsetHeight;
  // };

  // const trackScrolling = () => {
  //   const wrappedElement = document.getElementById("content-wrapper");
  //   if (!wrappedElement) return;
  //   if (isBottom(wrappedElement)) {
  //     console.log("Bottom reached");
  //     document.removeEventListener("scroll", trackScrolling);
  //     setEnded(true);
  //   }
  // };

  useEffect(() => {
    // trackScrolling();
    // document.addEventListener("scroll", trackScrolling);
    if (!token || Object.values(token).length <= 0) {
      dispatch(push(`${path}`));
      return;
    }
    return () => {
      // document.removeEventListener("scroll", trackScrolling);
    };
  }, []);

  useEffect(() => {
    if (isSuccess && isSubmit) dispatch(push(`${path}/idCard`));
  }, [isSuccess, isSubmit]);

  return (
    <>
      <Navbar canGoBack={true} />
      <div className="container" id="id_card">
        <PageTitle page={page} title={t("disclosure__title")} />
        <div id="content-wrapper">
          {/* <div className="contentText">{t("doc_id__import")}</div> */}
          {/* {importantDisclosures &&
            importantDisclosures.map(
              (importantDisclosure: any, index: number) => {
                if (importantDisclosure.feature_key === "id_text")
                  return (
                    <Fragment key={index}>
                      {language === "en" && (
                        <div
                          style={{wordWrap: "break-word"}}
                          dangerouslySetInnerHTML={{
                            __html: importantDisclosure.feature_content,
                          }}
                        />
                      )}
                      {language === "cn" && (
                        <div
                          style={{wordWrap: "break-word"}}
                          dangerouslySetInnerHTML={{
                            __html: importantDisclosure.feature_content_cn,
                          }}
                        />
                      )}
                      {language === "zh" && (
                        <div
                          style={{wordWrap: "break-word"}}
                          dangerouslySetInnerHTML={{
                            __html: importantDisclosure.feature_content_zh,
                          }}
                        />
                      )}
                    </Fragment>
                  );
                if (importantDisclosure.feature_key === "content")
                  return (
                    <Fragment key={index}>
                      {language === "en" && (
                        <div
                          style={{wordWrap: "break-word"}}
                          dangerouslySetInnerHTML={{
                            __html: importantDisclosure.feature_content,
                          }}
                        />
                      )}
                      {language === "cn" && (
                        <div
                          style={{wordWrap: "break-word"}}
                          dangerouslySetInnerHTML={{
                            __html: importantDisclosure.feature_content_cn,
                          }}
                        />
                      )}
                      {language === "zh" && (
                        <div
                          style={{wordWrap: "break-word"}}
                          dangerouslySetInnerHTML={{
                            __html: importantDisclosure.feature_content_zh,
                          }}
                        />
                      )}
                    </Fragment>
                  );
              }
            )} */}

          {content && (
            <Fragment>
              {language === "en" && (
                <div
                  style={{ wordWrap: "break-word" }}
                  dangerouslySetInnerHTML={{
                    __html: content.feature_content.replaceAll("<pre>", "<p>"),
                  }}
                />
              )}
              {language === "cn" && (
                <div
                  style={{ wordWrap: "break-word" }}
                  dangerouslySetInnerHTML={{
                    __html: content.feature_content_cn.replaceAll("<pre>", "<p>"),
                  }}
                />
              )}
              {language === "zh" && (
                <div
                  style={{ wordWrap: "break-word" }}
                  dangerouslySetInnerHTML={{
                    __html: content.feature_content_zh.replaceAll("<pre>", "<p>"),
                  }}
                />
              )}
            </Fragment>
          )}
        </div>
        {index === totalPage - 1 ? (
          <>
            <div>
              <input
                type="checkBox"
                onChange={() => {
                  if (!readAllText) dispatch(readAllTextSuccess());
                  if (readAllText) dispatch(readAllTextFailed());
                }}
                checked={readAllText ? true : false}
              ></input>
              {t("tnc__cb_agree_1")}
            </div>
            <button
              className={
                token &&
                // Ended &&
                readAllText
                  ? "button bottomMargin"
                  : "disabledButton bottomMargin"
              }
              onClick={() => {
                setIsSubmit(true);
                onSubmit();
              }}
              disabled={
                !token ||
                // !Ended ||
                !readAllText
              }
            >
              <div className="buttonText">{t("Submit")}</div>
            </button>
          </>
        ) : (
          <>
            <div>
              <input
                type="checkBox"
                onChange={() => {
                  if (!readAllText) dispatch(readAllTextSuccess());
                  if (readAllText) dispatch(readAllTextFailed());
                }}
                checked={readAllText ? true : false}
              ></input>
              {t("tnc__cb_agree_1")}
            </div>
            <button
              className={
                token &&
                  readAllText
                  ? "button bottomMargin"
                  : "disabledButton bottomMargin"
              }
              onClick={() => {
                handleNext();
              }}
              disabled={
                !token ||
                !readAllText
              }
            >
              <div className="buttonText">{t("next")}</div>
            </button>
          </>
        )}

      </div>
    </>
  );
};
