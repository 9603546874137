import React, { useEffect, useState } from "react";
import { Question } from "../../types";
import styles from "../../css/form.module.css";
import {
  UseFormRegister,
  FieldValues,
  UseFormUnregister,
  UseFormTrigger,
  UseFormSetValue,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { IRootState } from "../../store";

interface Props {
  question: Question;
  index: number;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

export const NumberInput: React.FC<Props> = ({
  question,
  index,
  register,
  unregister,
}) => {
  const language = useSelector((state: IRootState) => state.setting.language);
  useEffect(() => {
    return () => {
      unregister(question.id);
    };
  }, []);

  return (
    <>
      {question.label?.[language] && (
        <label className={styles.formLabel}>
          <div
            key={`${question.id}_label_${index}`}
            dangerouslySetInnerHTML={{ __html: question.label?.[language] + (question.required ? "*" : "")}}
          />
        </label>
      )}
      <input
        type={"number"}
        key={`${question.id}_input_${index}`}
        className={styles.formInput}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        {...register(question.id, {
          required: question?.required,
          maxLength: question?.maxLength,
          minLength: question?.minLength,
          pattern: new RegExp(question?.pattern),
        })}
      ></input>
      {question.description && (
        <div
          key={`${question.id}_description_${index}`}
          className={styles.formDes}
        >
          {question.description?.[language]
            ? question.description?.[language]
            : question.description}
        </div>
      )}
    </>
  );
};
