import React, { useState, useEffect } from "react";
import posed from "react-pose";
import SignatureCanvas from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import jimp from "jimp";
import {
  UseFormRegister,
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { Question } from "../../types";
import { IRootState } from "../../store";
import { useSelector } from "react-redux";
import styles from "../../css/form.module.css";

interface Props {
  question: Question;
  index?: number;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  // label: string;
  // onValueChange: (label: string, value: string) => void;
  // // signaturePath: any;
  // signaturePath: string;
}

interface SignatureSize {
  width: number;
  height: number;
}

// export default ({ label, signaturePath, onValueChange }: Props) => {
export default ({ question, setValue, register }: Props) => {
  const signaturePath = "";
  const onValueChange = (_label: string, value: string) => {
    setValue(question.id, value);
  };
  const language = useSelector((state: IRootState) => state.setting.language);
  const [canvasWidth, setCanvasWidth] = useState(200);
  const [signatureSize, setSignatureSize] =
    useState<SignatureSize | null>(null);

  let sigCanvas: any = null;
  let canvasContainerRef: any = null;

  useEffect(() => {
    if (!canvasContainerRef) return;
    setCanvasWidth(canvasContainerRef.offsetWidth);
  }, [canvasContainerRef]);

  useEffect(() => {
    if (signaturePath) {
      sigCanvas.off();
      getPngDimensions(signaturePath);
    }
  }, [signaturePath]);

  useEffect(() => {
    if (!signatureSize) return;
    if (signatureSize.width && signatureSize.height) {
      sigCanvas.fromDataURL(signaturePath, {
        width: signatureSize.width,
        height: signatureSize.height,
      });
      sigCanvas.on();
    }
  }, [signatureSize]);

  const filterImage = (base64String: any) => {
    const jimpImage = jimp
      .read(base64String)
      .then((img) => {
        img.brightness(-0.5, (_err, cb) => {
          if (cb) {
            cb.getBase64(jimp.MIME_PNG, (_err, res) => {
              onValueChange(
                "Sign",
                JSON.stringify({
                  url: sigCanvas.getCanvas().toDataURL("image/png"),
                  trimmeUrl: res,
                })
              );
            });
          }
        });
      })
  };

  const onEnd = () => {
    const filteredImage = filterImage(
      sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    );
  };

  const getPngDimensions = (base64: string) => {
    var img = document.createElement("img");
    img.setAttribute("src", base64);
    setTimeout(function () {
      setSignatureSize({ width: img.width, height: img.height });
    }, 1000);
  };
  return (
    <Container>
      {question.label?.[language] && (
        <label className={styles.formLabel}>
          <div
            key={`${question.id}_label_${question.id}`}
            dangerouslySetInnerHTML={{ __html: question.label?.[language] + (question.required ? "*" : "")}}
          />
        </label>
      )}
      <CanvasContainer
        ref={(ref: any) => {
          canvasContainerRef = ref;
        }}
      >
        <SignatureCanvas
          ref={(ref: any) => {
            sigCanvas = ref;
          }}
          penColor="#f19e38"
          canvasProps={{
            width: canvasWidth,
            height: 200,
            className: "sigCanvas",
          }}
          onEnd={onEnd}
        />
        <input
          key={`${question.id}_signature`}
          type={"hidden"}
          {...register(question.id, {
            required: question?.required,
            maxLength: question?.maxLength,
            minLength: question?.minLength,
            pattern: new RegExp(question?.pattern),
          })}
        ></input>
        <FontAwesomeIcon
          onClick={() => {
            sigCanvas.clear();
            onValueChange(question.id, "");
          }}
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
          }}
          icon={faTimesCircle}
        />
      </CanvasContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: 10px 0px;
  width: 100%;
`;

const Label = styled.div`
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
`;

const CanvasContainer = styled.div`
  position: relative;
  border: 1px solid #f19e38;
  border-radius: 5px;
`;

const SignatureContainer = styled.div`
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
`;

const SignatureContainerPosed = posed(SignatureContainer)({
  open: { x: "0%" },
  close: { x: "-100%" },
});

const CloseImg = styled.img`
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 10;
  height: 30px;
  width: 30px;
`;
