import React, { useEffect } from "react";
import { Question } from "../../types";

import { useSelector } from "react-redux";
import { IRootState } from "../../store";

export const LineBreak: React.FC = () => {
  const language = useSelector((state: IRootState) => state.setting.language);
  return (
    <>
      <div className="titleBox">
        <div
          style={{
            borderBottomWidth: "2px",
            borderBottomColor: "#E5E5E5",
            borderBottomStyle: "solid",
            margin: "10px 0",
          }}
        />
      </div>
    </>
  );
};
