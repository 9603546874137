import React, { useEffect, useState } from "react";
import {
  UseFormRegister,
  FieldValues,
  useWatch,
  UseFormWatch,
  UseFormUnregister,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { Question } from "../../types";
import styles from "../../css/form.module.css";
import { useSelector } from "react-redux";
import { IRootState } from "../../store";

interface Props {
  question: Question;
  index: number;
  unregister: UseFormUnregister<FieldValues>;
  register: UseFormRegister<FieldValues>;
  elementGenerator: (
    question: Question,
    index: number,
    register: UseFormRegister<FieldValues>,
    unregister: UseFormUnregister<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    component: boolean
  ) => JSX.Element | null;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  repetitive?: boolean;
}

interface Option {
  label: string;
  value: string | number;
  component: any;
}

export const Selector: React.FC<Props> = ({
  question,
  index,
  register,
  unregister,
  watch,
  elementGenerator,
  setValue,
  repetitive = false,
}) => {
  const language = useSelector((state: IRootState) => state.setting.language);
  useEffect(() => {
    return () => {
      unregister(question.id);
    };
  }, []);

  return (
    <>
      {question.label?.[language] && (
        <label className={styles.formLabel}>
          <div
            key={`${question.id}_label_${index}`}
            dangerouslySetInnerHTML={{ __html: question.label?.[language] + (question.required ? "*" : "")}}
          />
        </label>
      )}
      <select
        key={`${question.id}_selector_key_${index}`}
        className={styles.selector}
        {...register(question.id, {
          required: question?.required,
          maxLength: question?.maxLength,
          minLength: question?.minLength,
          pattern: new RegExp(question?.pattern),
        })}
      >
        {question.options.map((option) => (
          <option key={option.value + `${index}`} value={option.value}>
            {option.text[language]}
          </option>
        ))}
      </select>
      {repetitive
        ? question.options.map(
            (option, index) =>
              (watch(question.id) === "other" ||
                (watch(question.id) === "IdCard" &&
                  option.component.id === "identity_issue_date") ||
                (watch(question.id) === "Passport" &&
                  option.component.id === "identity_expiry_date")) &&
              option.component && (
                <React.Fragment key={option.value + index}>
                  {elementGenerator(
                    {
                      ...option.component,
                      id: `additional[${index}].${option.component.id}`,
                    },
                    index,
                    register,
                    unregister,
                    setValue,
                    true
                  )}
                </React.Fragment>
              )
          )
        : question.options.map(
            (option, index) =>
              (watch(question.id) === "other" ||
                (watch(question.id) === "IdCard" &&
                  option.component.id === "identity_issue_date") ||
                (watch(question.id) === "Passport" &&
                  option.component.id === "identity_expiry_date")) &&
              option.component && (
                <React.Fragment key={option.value + index}>
                  {elementGenerator(
                    {
                      ...option.component,
                      id: `${option.component.id}`,
                    },
                    index,
                    register,
                    unregister,
                    setValue,
                    true
                  )}
                </React.Fragment>
              )
          )}
      {question.description && (
        <div
          key={`${question.id}_selector_description_${index}`}
          className={styles.formDes}
        >
          {question.description?.[language]
            ? question.description?.[language]
            : question.description}
        </div>
      )}
    </>
  );
};
