import { IBioState } from './state';
import { IBioActions } from './actions';

export const initBioState: IBioState = {
    isProcessing: false,
    isFaceVerified : null,
    isDynamicFaceVerified: null,
    // isPitchHeadVerified: undefined,
    // isCloseEyeVerified: undefined,
    face: null,
    dynamicFace: null,
    isSuccess: false,
    errorMessage: null, 
}

export const bioReducers = (state: IBioState = initBioState, action: IBioActions):IBioState => {
    switch(action.type){
        case "@@bio/SEND_FACE_PROCESSING":
            return{
                ...state,
                isProcessing: action.isProcessing,
            }
        case "@@bio/VERIFY_FACE_SUCCESS":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isFaceVerified : action.isFaceVerified,
                face: action.face
            }
        case "@@bio/SEND_FACE_FAILURE":
            return{
                ...state,
                isProcessing:action.isProcessing,
                isSuccess:action.isSuccess,
                errorMessage: action.errorMessage
            }
        // case "@@bio/VERIFY_FACE_PITCHHEAD_SUCCESS":
        //     return{
        //         ...state,
        //         isProcessing: action.isProcessing,
        //         isPitchHeadVerified : action.isPitchHeadVerified
        //     }
        // case "@@bio/VERIFY_FACE_CLOSEEYE_SUCCESS":
        //     return{
        //         ...state,
        //         isProcessing: action.isProcessing,
        //         isCloseEyeVerified : action.isCloseEyeVerified
        //     }
        case "@@bio/VERIFY_FACE_DYNAMIC_SUCCESS":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isDynamicFaceVerified : action.isDynamicFaceVerified,
                dynamicFace: action.dynamicFace
            }
        case "@@bio/SEND_FACE_SUCCESS":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isSuccess : action.isSuccess,
            }
        case "@@bio/RESET_FACE_VERIFY":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isFaceVerified : action.isFaceVerify
            }
        case "@@bio/RESET_FACE_DYNAMIC_VERIFY":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isDynamicFaceVerified : action.isDynamicFaceVerified
            }
        case "@@bio/RESET":
            return{
                ...state,
                isProcessing:false,
                isSuccess:false,
                errorMessage: null
            }
        default:
            return state
    }
} 
