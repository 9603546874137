import React, { useState } from "react";
import { Navbar } from "../components/navbar";
import { push } from "connected-react-router";
import { PageTitle } from "../components/PageTitle";
import "../css/index.css";
import { useDispatch, useSelector } from "react-redux";
import ReactCodeInput from "react-code-input";
import { Selector } from "../components/Selector";
import styles from "../css/otp.module.css";
import { sendOTPThunk, verifyOTPThunk } from "../redux/otp/thunk";
import { IRootState } from "../store";
import { sendOTPAnotherNumber } from "../redux/otp/actions";
import { useEffect } from "react";
import LoadingOverlay from "../components/LoadingOverlay";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { IDMedia } from "./important_disclosures/idMedia";
import { useQuery } from "../utils/Utils";

interface Props {
  page: number;
  path: string;
  index: number;
  otpPages: number;
}

export const OTP: React.FC<Props> = ({ page, path, index, otpPages }: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [prefix, setPrefix] = useState<number>(852);
  const [mobile, setMobile] = useState<string>("");
  const [second, setSecond] = useState(60);
  const [resetable, setResetable] = useState(false);
  const [code, setCode] = useState<string>();
  // const [isPhone, setIsPhone] = useState(false);
  const isProcessing = useSelector(
    (state: IRootState) => state.otp.isProcessing
  );
  const isSent = useSelector((state: IRootState) => state.otp.isSent);
  const isVerified = useSelector((state: IRootState) => state.otp.isVerified);
  const totalPage = useSelector(
    (state: IRootState) => state.form.formTotalPage
  );
  const QAtotalPage = useSelector(
    (state: IRootState) => state.form.qaTotalPage
  );

  const locale = useSelector((state: IRootState) => state.setting.locale);
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const currentPage = useSelector((state: IRootState) => state.otp.currentPage);
  const profileId = useSelector((state: IRootState) => state.form.profileId);
  const merchantId = useSelector((state: IRootState) => state.form.merchantId);

  const idText = useSelector((state: IRootState) => state.form.idText);
  const idMedia = useSelector((state: IRootState) => state.form.idMedia);
  const agreementTime = useSelector(
    (state: IRootState) => state.otp.agreementTime
  );
  const language = useSelector((state: IRootState) => state.setting.language);

  const [visitorId, setVisitorId] = useState<string>();
  const [userAgent, setUserAgent] = useState<string>();
  //get fingerprint

  let history = useHistory();
  const historyArray = ["/form/0", "/video", "/face/0", "/qa/0"];

  let query = useQuery();

  useEffect(() => {
    const getVisitor = async () => {
      let visitorId = (await (await FingerprintJS.load()).get()).visitorId;
      setVisitorId(visitorId);
    };
    getVisitor();
    setUserAgent(navigator.userAgent);
    return () => {
      dispatch(sendOTPAnotherNumber());
      setResetable(false);
      setSecond(60);
    };
  }, []);

  const getPrefix = (locale: string) => {
    switch (locale) {
      case "hk":
        return 852;
      case "mc":
        return 853;
      case "cn":
        return 86;
      case "sg":
        return 65;
      case "my":
        return 60;
      default:
        return 86;
    }
  };

  const sendOnClick = async () => {
    if (isProcessing) return;
    setSecond(60);
    if (!(mobile && mobile.toString().length > 0) || !profileId) return;
    // //thunk
    const res = await dispatch(sendOTPThunk(profileId, prefix, mobile, language));
    res && toast.error(t(res?.toString()));
  };
  const verifyOnClick = async (code: string) => {
    if (code.length != 6) return;
    if (!locale)
      return () => {
        toast.error(t("otp_warning_location_missing"));
        dispatch(push(`${path}/`));
      };
    if (!profileId || !merchantId) return;
    let refString = query.get("ref");
    const res = await dispatch(
      verifyOTPThunk(
        code,
        prefix,
        mobile,
        profileId,
        merchantId,
        locale,
        userAgent,
        visitorId,
        refString
      )
    );

    res && toast.error(t(res?.toString()));
  };

  // useEffect(() => {
  //   if (!caseToken || Object.values(caseToken).length <= 0) return;
  //   // if (agreementTime) dispatch(push(`${path}/idCard`));
  //   // else dispatch(push(`${path}/important_disclosures`));
  // }, [caseToken, currentPage]);

  useEffect(() => {
    if (!isVerified) return;

    if (index < otpPages - 1) {
      dispatch(push(`${path}/otp/${index + 1}`));
      return;
    }

    if (idText)
      dispatch(push(`${path}/important_disclosures/id_text/0`));
    else if (idMedia)
      dispatch(push(`${path}/important_disclosures/id_media`));
    else dispatch(push(`${path}/idCard`));

    // let destination = { module: currentPage, page: "" };

    // if (currentPage?.startsWith("Form")) {
    //   destination.module = "Form";
    //   destination.page = currentPage.slice(-1);
    // } else if (currentPage?.startsWith("Q&A:")) {
    //   destination.module = "Qa";
    //   destination.page = currentPage.slice(-1);
    // }
    // switch (destination.module) {
    //   case "OTP":
    //     dispatch(push(`${path}/important_disclosures`));
    //     break;
    //   case "Important Disclosure":
    //     dispatch(push(`${path}/idCard`));
    //     break;
    //   case "Id Card":
    //     dispatch(push(`${path}/form/0`));
    //     break;
    //   case "Form":
    //     if (totalPage && parseInt(destination.page) <= totalPage - 1) {
    //       dispatch(push(`${path}/form/${destination.page}`));
    //     } else {
    //       historyArray
    //         .slice(0, 1)
    //         .forEach((currentPath: string) =>
    //           history.push(`${path}${currentPath}`)
    //         );
    //       dispatch(push(`${path}/video`));
    //     }
    //     break;
    //   case "Video":
    //     historyArray
    //       .slice(0, 1)
    //       .forEach((currentPath: string) =>
    //         history.push(`${path}${currentPath}`)
    //       );
    //     dispatch(push(`${path}/video`));
    //     break;
    //   case "Biometric":
    //     historyArray
    //       .slice(0, 2)
    //       .forEach((currentPath: string) =>
    //         history.push(`${path}${currentPath}`)
    //       );
    //     dispatch(push(`${path}/face/0`));
    //     break;
    //   case "Qa":
    //     historyArray
    //       .slice(0, 3)
    //       .forEach((currentPath: string) =>
    //         history.push(`${path}${currentPath}`)
    //       );
    //     if (QAtotalPage && parseInt(destination.page) <= QAtotalPage - 1) {
    //       dispatch(push(`${path}/qa/${destination.page}`));
    //     } else {
    //       dispatch(push(`${path}/qa/0`));
    //     }
    //     break;
    //   default:
    //     if (agreementTime) dispatch(push(`${path}/idCard`));
    //     else if (idText)
    //       dispatch(push(`${path}/important_disclosures/id_text/0`));
    //     else if (idMedia)
    //       dispatch(push(`${path}/important_disclosures/id_media`));
    //     else dispatch(push(`${path}/idCard`));
    //     break;
    // }
  }, [isVerified]);

  useEffect(() => {
    if (!locale) return;
    setPrefix(getPrefix(locale));
  }, []);

  const codeInputProps = {
    inputStyle: {
      width: "35px",
      borderRadius: "3px",
      fontSize: "20px",
      height: "40px",
      marginLeft: "5px",
      backgroundColor: "white",
      color: "black",
      align: "center",
      border: "1px solid #D6D6D6",
    },
    inputStyleInvalid: {
      width: "35px",
      borderRadius: "3px",
      fontSize: "20px",
      height: "40px",
      marginLeft: "5px",
      backgroundColor: "white",
      color: "black",
      align: "center",
      border: "1px solid #D6D6D6",
    },
  };

  useEffect(() => {
    const countDown = setInterval(() => {
      setSecond((second) => second - 1);
    }, 1000);

    if (second <= 0) {
      setSecond(0);
      // setResetable(!resetable);
      setResetable(true);
      clearInterval(countDown);
    }

    return () => {
      if (countDown) clearInterval(countDown);
      if (!second) {
        setResetable(false);
        setSecond(60);
      }
    };
  }, [second]);

  return (
    <>
      <Navbar canGoBack={true} />
      {!isSent && (
        <div className="container">
          <PageTitle title={t("otp_title")} page={page} />
          <div>
            <div className="contentText" >{t("otp_content")}</div>
          </div>
          <br></br>
          <div>
            <div className="contentText" style={{ marginBottom: "10px" }}>{t("otp_phone_no")}</div>
            <div className={styles.codeBox}>
              <Selector
                defaultValue={prefix}
                className={styles.codeSelector}
                value={prefix}
                onChange={setPrefix}
                options={[
                  { label: "852", value: 852 },
                  { label: "86", value: 86 },
                  { label: "853", value: 853 },
                  { label: "886", value: 886 },
                  { label: "61", value: 61 },
                  { label: "91", value: 91 },
                  { label: "81", value: 81 },
                  { label: "60", value: 60 },
                  { label: "64", value: 64 },
                  { label: "65", value: 65 },
                  { label: "82", value: 82 },
                  { label: "66", value: 66 },
                  { label: "44", value: 44 },
                  { label: "1", value: 1 },
                  { label: "84", value: 84 },
                ]}
              ></Selector>
              <input
                type="text"
                value={mobile}
                inputMode={"numeric"}
                onChange={(e) => setMobile(e.target.value)}
                className={styles.codeInput}
              ></input>
            </div>
            <div style={{ display: "flex", justifyContent: 'center' }}>
              <button
                className={
                  isProcessing || mobile.length <= 0 ? "disabledButton" : "button"
                }
                onClick={sendOnClick}
                disabled={isProcessing || mobile.length <= 0}
              >
                <div className="buttonText">{t("otp_send")}</div>
              </button>
            </div>
          </div>
        </div>
      )}
      {isSent && (
        <div className="container">
          <PageTitle title={t("otp_title")} page={page} />
          <div>
            <div className="contentText" style={{ marginBottom: "10px" }}></div>
          </div>
          <ReactCodeInput
            name=""
            inputMode="numeric"
            type="number"
            fields={6}
            onChange={(value) => {
              setCode(value);
              verifyOnClick(value);
            }}
            {...codeInputProps}
          />
          <div className={styles.resendText}>
            <div>{t("otp_notReceived")}</div>
            <button
              className={"resend"}
              disabled={!resetable}
              onClick={() => {
                if (resetable && profileId) {
                  dispatch(sendOTPThunk(profileId, prefix, mobile, language));
                  // setResetable(!resetable);
                  setResetable(false);
                  setSecond(60);
                }
              }}
            >
              <div
                style={
                  resetable
                    ? { color: "#4665FA", borderStyle: "none" }
                    : { color: "#D6D6D6", borderStyle: "none" }
                }
              >
                {t("otp_sned_again")}({second})
              </div>
            </button>
          </div>
        </div>
      )}
      <LoadingOverlay isProcessing={isProcessing} isSuccess={isVerified} />
    </>
  );
};
