import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../store";
import { updateLanguageSuccess } from "../redux/language/actions";

interface Props {
  options: Option[];
  className?: string;
  onChange: (...event: any[]) => void;
  value: any;
  defaultValue?: string | number;
}
interface Option {
  label: string;
  value: string | number;
}

export const Selector: React.FC<Props> = ({
  options,
  className,
  defaultValue,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0].value);
  useEffect(() => {
    if (defaultValue) setSelectedOption(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    onChange(selectedOption);
  }, [selectedOption]);

  return (
    <select
      className={className}
      value={selectedOption}
      onChange={(e) => setSelectedOption(e.target.value)}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
