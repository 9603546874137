import React, { useEffect, useState } from "react";
import {
  UseFormRegister,
  FieldValues,
  UseFormSetValue,
  DeepMap,
  FieldError,
  UseFormUnregister,
  UseFormWatch,
  UseFormSetError,
} from "react-hook-form";
import { Question } from "../../types";
import styles from "../../css/form.module.css";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../store";
//@ts-ignore
import SelectDatePicker from "@netojose/react-select-datepicker";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';

interface Props {
  question: Question;
  index: number;
  // minDate?: string;
  // maxDate?: string;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  unregister: UseFormUnregister<FieldValues>;
  component?: boolean;
  setError?: UseFormSetError<FieldValues>;
}

export const FormDateSelector: React.FC<Props> = ({
  question,
  index,
  setValue,
  register,
  errors,
  unregister,
  component = false,
  // setError
  // minDate,
  // maxDate,
}) => {
  const caseData = useSelector((state: IRootState) => state.form.caseData);
  const qaData = useSelector((state: IRootState) => state.qa.qaData);
  const idCardData = useSelector(
    (state: IRootState) => state.document.idCardData
  );
  const language = useSelector((state: IRootState) => state.setting.language);
  const { t } = useTranslation();

  const [year, setYear] = useState<number>(1980); // set default year 1980
  const [month, setMonth] = useState<number>(-1);
  const [day, setDay] = useState<number>(-1);
  const [selectedDay, setSelectedDay] = useState<Date>();

  useEffect(() => {
    if (year != -1 && month != -1 && day != -1) {
      setSelectedDay(new Date(year, month, day))
    }
  }, [year, month, day])

  useEffect(() => {
    if (selectedDay) {
      if (question.id === "birthday") {
        setValue("birth_year", selectedDay.getFullYear());
        setValue("birth_month", selectedDay.getMonth() + 1);
        setValue("birth_day", selectedDay.getDate());
      } else {
        setValue(question.id, Math.round(selectedDay.getTime() / 1000));
      }
    } else {
    }
  }, [selectedDay])

  useEffect(() => {
    return () => {
      if (question.id === "birthday") {
        unregister("birth_day");
        unregister("birth_year");
        unregister("birth_month");
      } else {
        unregister(question.id);
      }
    };
  }, []);

  useEffect(() => {
    if (question.id === "birthday") {
      register("birth_day", { required: true });
      register("birth_year", { required: true });
      register("birth_month", { required: true });
    } else {
      // register(question.id, { required: question.required ,
      //   validate: { 
      //     futureDataOnly: (v) => question.afterToday ? v > Math.round(new Date().getTime()/ 1000 ) : true ,
      //     pastDataOnly: (v) => question.beforeToday ? v < Math.round(new Date().getTime()/ 1000 ) : true 
      //   }
      // });
    }
  }, [register]);

  // load previous data
  useEffect(() => {
    if (!caseData) return;
    if (question.id === "birthday") {
      if (
        caseData["birth_year"] &&
        caseData["birth_month"] &&
        caseData["birth_day"]
      ) {
        setYear(caseData["birth_year"]);
        setMonth(caseData["birth_month"] - 1);
        setDay(caseData["birth_day"]);
      }
    } else {
      if (caseData[question.id]) {
        try {
          setYear(new Date(JSON.parse(caseData[question.id]) * 1000).getFullYear());
          setMonth(new Date(JSON.parse(caseData[question.id]) * 1000).getMonth());
          setDay(new Date(JSON.parse(caseData[question.id]) * 1000).getDate());
        } catch (error) {
          console.log("json parse error.")
        }
      }
    }
    if (qaData[question.id]) {
      try {
        setYear(new Date(JSON.parse(qaData[question.id]?.value) * 1000).getFullYear());
        setMonth(new Date(JSON.parse(qaData[question.id]?.value) * 1000).getMonth());
        setDay(new Date(JSON.parse(qaData[question.id]?.value) * 1000).getDate());
      } catch (error) {
        console.log("json parse error.")
      }
    }
  }, [caseData, qaData]);

  //load id Card OCR
  useEffect(() => {
    if (!idCardData) return;
    if (question.id === "birthday") {
      if (
        idCardData["birth_year"] &&
        idCardData["birth_month"] &&
        idCardData["birth_day"]
      ) {
        setYear(parseInt(idCardData["birth_year"]));
        setMonth(parseInt(idCardData["birth_month"]) - 1);
        setDay(parseInt(idCardData["birth_day"]));
      }
    }
  }, []);

  useEffect(() => {
    if (question.defaultToday) {
      setSelectedDay(new Date());
      setYear(new Date().getFullYear());
      setMonth(new Date().getMonth());
      setDay(new Date().getDate());
    }
  }, [question.defaultToday]);

  return (
    <>
      {question.label?.[language] && (
        <label className={styles.formLabel}>
          <div
            key={`${question.id}_label_${index}`}
            dangerouslySetInnerHTML={{ __html: question.label?.[language] + (question.required ? "*" : "") }}
          />
        </label>
      )}
      <DatePickerContainer
        style={question.readOnly ? { pointerEvents: "none" } : {}}
        //@ts-ignore
        color={question?.readOnly.toString()}
      >

        <div className={styles.datePicker}>
          <div>
            <label>{t("datePicker_year")}
              <YearPicker
                defaultValue={t("datePicker_year")}
                start={1930}
                end={2070}
                value={year}
                onChange={(year: number) => {
                  setYear(year);
                }}
                id={'year'}
                name={'year'}
              // classes={styles.datePickerComponent}
              />
            </label>
            <label>{t("datePicker_month")}
              <MonthPicker
                defaultValue={t("datePicker_month")}
                numeric   // to get months as numbers
                short
                caps
                endYearGiven
                year={year}
                value={month}
                onChange={(month: number) => {
                  setMonth(month);
                }}
                id={'month'}
                name={'month'}
              // classes={styles.datePickerComponent}
              /></label>
            <label>{t("datePicker_day")}
              <DayPicker
                defaultValue={t("datePicker_day")}
                year={year}
                month={month}
                endYearGiven
                value={day}
                onChange={(day: number) => {
                  setDay(day);
                }}
                id={'day'}
                name={'day'}
              // classes={styles.datePickerComponent}
              />
            </label></div>
        </div>

      </DatePickerContainer>
      {(errors["birth_day"] ||
        errors["birth_month"] ||
        errors["birth_year"]) && (
          <div key={"birthday_error"} className={styles.errorText}>
            {t("warning__required")}
          </div>
        )}
      {question.id !== "birthday" && <input type={"hidden"} {...register(question.id, {
        required: question.required,
        validate: {
          futureDataOnly: (v) => question.afterToday ? v > Math.round(new Date().getTime() / 1000) : true,
          pastDataOnly: (v) => question.beforeToday ? v < Math.round(new Date().getTime() / 1000) : true
        }
      })} />}

      {question.id == "birthday" && <input type={"hidden"} {...register("birth_day", {
        required: true,
        validate: {
          hasValue: (v) => v != -1,
        }
      })} />}

      {component && errors?.[question.id] && (
        <div key={"component_birthday_error"} className={styles.errorText}>
          {t("warning__required")}
        </div>
      )}
      {question.description && (
        <div
          key={`${question.id}_description_${index}`}
          className={styles.formDes}
        >
          {question.description?.[language]
            ? question.description?.[language]
            : question.description}
        </div>
      )}
    </>
  );
};

const DatePickerContainer = styled.div`
  select {
    ${(props) =>
    props.color === "true"
      ? "background-color: whitesmoke!important;"
      : "background-color: transparent;"}
  }
`;
