import React, { useEffect, useState } from "react";
import "../css/index.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navbar } from "../components/navbar";
import { IRootState } from "../store";
import { PageTitle } from "../components/PageTitle";
import { push } from "connected-react-router";
import { useQuery } from "../utils/Utils";

interface Props {
  title: string;
  index: number;
  page: number;
  subPath: string;
  path: string;
  contentDetail: any;
  totalPages: number;
  //   onSubmit: () => void;
}

export const ContentPage: React.FC<Props> = ({
  title,
  page,
  path,
  subPath,
  index,
  contentDetail,
  totalPages,
}: Props) => {
  const dispatch = useDispatch();
  const language = useSelector((state: IRootState) => state.setting.language);
  const { t } = useTranslation();
  let query = useQuery();
  const idText = useSelector((state: IRootState) => state.form.idText);
  const idMedia = useSelector((state: IRootState) => state.form.idMedia);

  const handleNext = () => {
    if (index < totalPages - 1) {
      if (subPath === "otp" && query.get("ref")) {
        dispatch(push(`${path}/${subPath}/${index + 1}` + `?ref=${query.get("ref")}`));
      } else {
        dispatch(push(`${path}/${subPath}/${index + 1}`));
      }
    } else {
      if (subPath === "otp") {
        if (idText) {
          dispatch(push(`${path}/important_disclosures/id_text/0`));
        } else if (idMedia) {
          dispatch(push(`${path}/important_disclosures/id_media`));
        } else {
          dispatch(push(`${path}/idCard`));
        }
      } else if (subPath === "face") {
        dispatch(push(`${path}/qa/0`));
      }
    }
  };

  return (
    <>
      <Navbar canGoBack={true} />
      <div className="container" id="id_card">
        <PageTitle page={page} title={t(title)} />
        <div id="content-wrapper">
          {contentDetail && (
            <React.Fragment>
              {language === "en" && (
                <div
                  style={{wordWrap: "break-word"}}
                  dangerouslySetInnerHTML={{
                    __html: contentDetail.feature_content,
                  }}
                />
              )}
              {language === "cn" && (
                <div
                  style={{wordWrap: "break-word"}}
                  dangerouslySetInnerHTML={{
                    __html: contentDetail.feature_content_cn,
                  }}
                />
              )}
              {language === "zh" && (
                <div
                  style={{wordWrap: "break-word"}}
                  dangerouslySetInnerHTML={{
                    __html: contentDetail.feature_content_zh,
                  }}
                />
              )}
            </React.Fragment>
          )}
        </div>
        <button
          className={"button bottomMargin"}
          onClick={() => {
            handleNext();
          }}
        >
          <div className="buttonText">{t("next")}</div>
        </button>
      </div>
    </>
  );
};
