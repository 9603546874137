import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "../css/index.css";
import { IRootState } from "../store";
import { ContentPage } from "./contentPage";
import { Face } from "./face";
import { NotFound } from "./notFound";

interface Props {
  page: number;
  path: string;
}

export const FaceRoute: React.FC<Props> = ({ page, path }: Props) => {
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const dispatch = useDispatch();

  const contentPageOfBiometric = useSelector(
    (state: IRootState) => state.form.contentPageOfBiometric
  );

  useEffect(() => {
    if (!caseToken || Object.values(caseToken).length <= 0) {
      dispatch(push(`${path}`));
      return;
    }
  });
  
  return (
    <>
      <Switch>
        {contentPageOfBiometric &&
          contentPageOfBiometric.map((contentDetail: any, index: number) => {
            if (contentDetail.feature_key === "content") {
              return (
                <Route
                  key={"face_content" + index}
                  path={`${path}/face/${index}`}
                >
                  <ContentPage
                    page={page}
                    contentDetail={contentDetail}
                    title="bio_face__title"
                    index={index}
                    path={path}
                    subPath="face"
                    totalPages={contentPageOfBiometric.length}
                  />
                </Route>
              );
            } else if (contentDetail.feature_key === "face") {
              return (
                <Route key={"face" + index} path={`${path}/face/${index}`}>
                  <Face
                    page={page}
                    path={path}
                    index={index}
                    facePages={contentPageOfBiometric.length}
                  />
                </Route>
              );
            }
          })}
        <Route path={`${path}/face`} component={NotFound}></Route>
      </Switch>
    </>
  );
};
