import { ISettingState } from './state';
import { ISettingActions } from './actions';

export const initSettingState: ISettingState = {
    language: 'zh',
    locale: null,
    isSuccess: false
}

export const settingReducers = (state: ISettingState = initSettingState, action: ISettingActions):ISettingState => {
    switch(action.type){
        case "@@setting/UPDATE_LANGUAGE_SUCCESS":
            return{
                ...state,
                language: action.language,
            }
        case "@@setting/UPDATE_LOCALE_SUCCESS":
            return{
                ...state,
                locale: action.locale,
                isSuccess: action.isSuccess
            }
        case "@@setting/UPDATE_LOCALE_FINISH":
            return{
                ...state,
                isSuccess: action.isSuccess
            }
        default:
            return state
    }
} 
