import React, { useEffect, useState } from "react";
import {
  FieldValues,
  useFieldArray,
  useForm,
  UseFormRegister,
  UseFormSetValue,
  useFormState,
  UseFormUnregister,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { formSubmitThunk } from "../../redux/form/thunk";
import styles from "../../css/form.module.css";
import { Case, Option, Element, Question } from "../../types/index";
import { Navbar } from "../navbar";
import { IRootState } from "../../store";
import { useLocation } from "react-router-dom";
import { push } from "connected-react-router";
import { resetForm, sendCaseProcessing } from "../../redux/form/actions";
import { TextInput } from "./TextInput";
import { Selector } from "./Selector";
import { CheckBoxes } from "./CheckBoxes";
import { RadioButtons } from "./RadioButtons";
import { Label } from "./Label";
import { LineBreak } from "./LineBreak";
import { FormDatePicker } from "./DatePicker";
import { Header } from "./Header";
import LoadingOverlay from "../LoadingOverlay";
import { PageTitle } from "../PageTitle";
import Signature from "./Signature";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NumberInput } from "./NumberInput";
import { TextArea } from "./TextArea";
import { useTranslation } from "react-i18next";
import Download from "./Download";
import { FormDateSelector } from "./DateSelector";

interface Props {
  page: number;
  form?: any;
  path: string;
  index: number;
}

export const Form: React.FC<Props> = ({ page, form, index, path }: Props) => {
  let pageNumber = index;
  //@ts-ignore
  // let { pageNumber } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
    setValue,
    control,
    trigger,
    unregister,
    watch,
    setError
  } = useForm({ shouldFocusError: true });
  const { isValid } = useFormState({ control });

  const forms = useSelector((state: IRootState) => state.form.form);
  const totalPage = useSelector(
    (state: IRootState) => state.form.formTotalPage
  );
  const isSuccess = useSelector((state: IRootState) => state.form.isSuccess);
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const caseData = useSelector((state: IRootState) => state.form.caseData);
  const [questions, setQuestions] =
    useState<Question[][] | Question[] | null>(null);
  const [repQuestions, setRepQuestions] = useState<Question[][]>([]);
  const [prevRepQuestions, setPrevRepQuestions] = useState<Question[][]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    const err = Object.keys(errors);
    if (err.length > 0) {
        const input = document.querySelector(
            `input[name="${err[0]}"]`
        );
        const closeLabel = input?.nextElementSibling ? input?.nextElementSibling : input?.parentElement;
        
        if (closeLabel) {
          closeLabel.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
    };
}, [formState])

  const isProcesing = useSelector(
    (state: IRootState) => state.form.isProcessing
  );
  const idCardInfo = useSelector(
    (state: IRootState) => state.document.idCardData
  );

  useEffect(() => {
    reset({ ...idCardInfo, ...caseData });
    if (!totalPage) return;
    if (pageNumber <= totalPage - 1) {
      setQuestions(JSON.parse(form?.data));
    }
  }, []);

  useEffect(() => {
    reset({ ...idCardInfo, ...caseData });
    if (caseData["additional"]?.length > 0) {
      let tempRepQuestion: Question[][] = [];
      tempRepQuestion.length = caseData["additional"].length;
      setPrevRepQuestions(tempRepQuestion);
    }
  }, [caseData]);

  useEffect(() => {
    reset();
    if (!questions) return;
    //for repetitive quesiton, eg : other bank
    questions?.forEach((question: Question[] | Question) => {
      if (Array.isArray(question)) {
        let tempRepQuestion = [...prevRepQuestions];
        tempRepQuestion.fill(question);
        setRepQuestions(tempRepQuestion);
      }
    });
  }, [questions]);

  useEffect(() => {
    if (!forms || !totalPage) return;
    if (pageNumber <= totalPage - 1) {
      setQuestions(JSON.parse(form?.data));
    }
  }, [form, pageNumber]);

  useEffect(() => {
    const nextPage = pageNumber + 1;
    if (isSuccess) {
      dispatch(resetForm());
      if (!totalPage) return;
      if (pageNumber >= totalPage - 1) {
        dispatch(push(`${path}/video`));
      } else {
        dispatch(push(`${path}/form/${nextPage}`));
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!totalPage) return;
    if (pageNumber > totalPage - 1) dispatch(push(`/video`));
  }, [totalPage]);

  const onSubmit = (data: Partial<Case>) => {
    if (!caseToken) return;
    dispatch(sendCaseProcessing());
    const currentPage = pageNumber + 1;
    dispatch(formSubmitThunk(caseToken, data, currentPage, path, t));
  };

  const ElementGenerator = (
    question: Question,
    index: number,
    register: UseFormRegister<FieldValues>,
    unregister: UseFormUnregister<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    component: boolean,
    repetitive?: boolean,
  ) => {
    switch (question.element) {
      case "Header":
        return <Header question={question} index={index} />;
      case "Label":
        return <Label question={question} index={index} />;
      case "Checkboxes":
        return (
          <CheckBoxes
            question={question}
            index={index}
            register={register}
            unregister={unregister}
            setValue={setValue}
            watch={watch}
          />
        );
      case "LineBreak":
        return <LineBreak />;
      case "TextInput":
        return (
          <TextInput
            question={question}
            index={index}
            register={register}
            unregister={unregister}
            setValue={setValue}
            errors={errors}
            component={component}
          />
        );
      case "RadioButtons":
        return (
          <RadioButtons
            question={question}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
          />
        );
      case "Dropdown":
        return (
          <Selector
            question={question}
            index={index}
            register={register}
            unregister={unregister}
            elementGenerator={ElementGenerator}
            watch={watch}
            setValue={setValue}
            repetitive={repetitive}
          />
        );
      // case "DatePicker":
      //   return (
      //     <FormDatePicker
      //       question={question}
      //       index={index}
      //       // minDate={question.minDate}
      //       // maxDate={question.maxDate}
      //       register={register}
      //       setValue={setValue}
      //       errors={errors}
      //       unregister={unregister}
      //       component={component}
      //     />
      //   );
      case "DatePicker":
        return (
          <FormDateSelector
            question={question}
            index={index}
            // minDate={question.minDate}
            // maxDate={question.maxDate}
            register={register}
            setValue={setValue}
            errors={errors}
            unregister={unregister}
            component={component}
          />
        );
      case "Signature":
        return (
          <Signature
            question={question}
            setValue={setValue}
            register={register}
            watch={watch}
          />
        );
      case "NumberInput":
        return (
          <NumberInput
            question={question}
            index={index}
            register={register}
            unregister={unregister}
            setValue={setValue}
          />
        );
      case "TextArea":
        return (
          <TextArea
            question={question}
            index={index}
            register={register}
            unregister={unregister}
            setValue={setValue}
          />
        );
      case "Download":
        return (
          <Download
            uploadedFileName={""}
            setIsProcessing={(isProcessing: boolean) => {
              setIsUploading(isProcessing);
            }}
            register={register}
            setValue={setValue}
            watch={watch}
            question={question}
            onChange={() => {
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar isValid={isValid} trigger={trigger} canGoBack={true} />
      <div className="container">
        <PageTitle page={page} />
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          {questions &&
            questions.map((question: Question[] | Question, index: number) => {
              //repeat Question
              if (Array.isArray(question)) {
                return (
                  <React.Fragment key={index}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        type="button"
                        style={{ paddingLeft: "10px" }}
                        onClick={() => {
                          let tempRepQuestions = [...repQuestions];
                          tempRepQuestions.push([...question]);
                          setRepQuestions(tempRepQuestions);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
                      </button>
                      <button
                        type="button"
                        style={{ paddingLeft: "10px" }}
                        onClick={() => {
                          let tempRepQuestions = [...repQuestions];
                          tempRepQuestions.pop();
                          setRepQuestions(tempRepQuestions);
                        }}
                      >
                        <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
                      </button>
                    </div>
                    {repQuestions?.map((quest, index) =>
                      quest?.map((addQuest, idx) => (
                        <React.Fragment key={idx}>
                          {ElementGenerator(
                            {
                              ...addQuest,
                              id: `additional[${index}].${addQuest.id}`,
                            },
                            index,
                            register,
                            unregister,
                            setValue,
                            false,
                            true
                          )}
                          {errors?.["additional"]?.[index]?.[addQuest.id] &&
                            errors?.["additional"]?.[index]?.[addQuest.id]
                              ?.type == "required" && (
                              <div
                                key={`${addQuest.id}_${index}_error_${index}`}
                                className={styles.errorText}
                              >
                                {t("warning__required")}
                              </div>
                            )}
                          {errors?.["additional"]?.[index]?.[addQuest.id] &&
                            errors?.["additional"]?.[index]?.[addQuest.id]
                              ?.type == "pattern" && (
                              <div
                                key={`${addQuest.id}_${index}_error_${index}`}
                                className={styles.errorText}
                              >
                                {t("warning__invalid__format")}
                              </div>
                            )}
                          {errors?.["additional"]?.[index]?.[addQuest.id] &&
                            errors?.["additional"]?.[index]?.[addQuest.id]
                              ?.type == "maxLength" && (
                              <div
                                key={`${addQuest.id}_${index}_error_${index}`}
                                className={styles.errorText}
                              >
                                {t("warning__need__maximum")}{" "}
                                {addQuest.maxLength} {t("warning__characters")}
                              </div>
                            )}
                          {errors?.["additional"]?.[index]?.[addQuest.id] &&
                            errors?.["additional"]?.[index]?.[addQuest.id]
                              ?.type == "minLength" && (
                              <div
                                key={`${addQuest.id}_${index}_error_${index}`}
                                className={styles.errorText}
                              >
                                {t("warning__need__minimum")}{" "}
                                {addQuest.minLength} {t("warning__characters")}
                              </div>
                            )}
                        </React.Fragment>
                      ))
                    )}
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    {ElementGenerator(
                      question,
                      index,
                      register,
                      unregister,
                      setValue,
                      false,
                    )}
                    {errors[`${question.id}`] &&
                      errors[`${question.id}`].type == "required" && (
                        <div
                          key={`${question.id}_error_${index}`}
                          className={styles.errorText}
                        >
                          {t("warning__required")}
                        </div>
                      )}
                    {errors[`${question.id}`] &&
                      errors[`${question.id}`].type == "pattern" && (
                        <div
                          key={`${question.id}_error_${index}`}
                          className={styles.errorText}
                        >
                          {t("warning__invalid__format")}
                        </div>
                      )}
                    {errors[`${question.id}`] &&
                      errors[`${question.id}`].type == "maxLength" && (
                        <div
                          key={`${question.id}_error_${index}`}
                          className={styles.errorText}
                        >
                          {t("warning__need__maximum")}{" "}
                          {question.maxLength} {t("warning__characters")}
                        </div>
                      )}
                    {errors[`${question.id}`] &&
                      errors[`${question.id}`].type == "minLength" && (
                        <div
                          key={`${question.id}_error_${index}`}
                          className={styles.errorText}
                        >
                          {t("warning__need__minimum")}{" "}
                          {question.minLength} {t("warning__characters")}
                        </div>
                      )}
                  </React.Fragment>
                );
              }
            })}
          <button
            className="button"
            style={{ marginTop: "20px" }}
            onClick={handleSubmit(onSubmit)}
          >
            <div className="buttonText">{t("next")}</div>
          </button>
          <LoadingOverlay isProcessing={isProcesing} isSuccess={isSuccess} />
        </form>
      </div>
    </>
  );
};
