import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import LoadingOverlay from "../../components/LoadingOverlay";
import { useSelector } from "react-redux";
import { IRootState } from "../../store";
import { Question } from "../../types";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import styles from "../../css/form.module.css";
import {
  UseFormRegister,
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import * as dotenv from "dotenv";
dotenv.config();

interface Props {
  question: Question;
  //   isUploading: boolean;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  setIsProcessing: (value: boolean) => void;
  setValue: UseFormSetValue<FieldValues>;
  uploadedFileName: string;
  onChange?: (name: string, value: string) => void;
}

export default ({
  question,
  uploadedFileName,
  setIsProcessing,
  register,
  setValue,
  watch,
}: Props) => {
  const [files, setFiles] = useState([] as any[]);
  //   const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [savedFileName, setSavedFileName] = useState(uploadedFileName);
  const [currentFileName, setCurrentFileName] = useState("");
  const token = useSelector((state: IRootState) => state.otp.caseToken);
  const element = question?.element;
  const label = JSON.stringify(question?.content);
  const name = question?.id;
  const language = useSelector((state: IRootState) => state.setting.language);
  const qaData = useSelector((state: IRootState) => state.qa.qaData);

  const { t } = useTranslation();

  useEffect(() => {}, [watch(question.id)]);

  useEffect(() => {
    if (qaData[question?.id] && Array.isArray(qaData[question?.id])) {
      setCurrentFileName(
        qaData[question?.id][qaData[question?.id].length - 1]?.value
      );
      setValue(
        question.id,
        qaData[question?.id][qaData[question?.id].length - 1]?.value
      );
    }

    if (qaData[question?.id]?.value) {
      setCurrentFileName(qaData[question?.id]?.value);
      setValue(question.id, qaData[question?.id]?.value);
    }
  }, [qaData]);

  useEffect(() => {
    setIsProcessing(isUploading);
  }, [isUploading]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg, .pdf, .doc, .docx",
    onDrop: async (acceptedFiles: File[]) => {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      formData.append("label", label);
      formData.append("element", element);
      formData.append("name", name);
      setIsUploading(true);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/case/${token}/media`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await res.json();
      if (data && data.success) {
        setFiles(
          acceptedFiles.map((file: File) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setValue(question.id, data.path);
        setIsUploading(false);
        // onChange(acceptedFiles[0].name, data.path);
      } else {
        toast.error("Problem has occured, please try again.");
        setIsUploading(false);
      }
    },
  });

  const removeFile = (file: any) => () => {
    if (file) {
      const removefiles = [...files];
      removefiles.splice(removefiles.indexOf(file), 1);
      setFiles(removefiles);
    } else {
      setCurrentFileName("");
      setSavedFileName("");
      setValue(question.id, null);
    }
  };

  const thumbs = () => {
    if (currentFileName) {
      return (
        <Thumbdiv key={savedFileName}>
          <Thumbinsidediv>
            {/* <a href={currentPath}>{t('uploaded file')}</a> */}
            <div>
              <div>{currentFileName}</div>
            </div>
            <RemoveButton onClick={removeFile(null)}>x</RemoveButton>
          </Thumbinsidediv>
        </Thumbdiv>
      );
    } else if (files && files.length > 0) {
      return files.map((file) => (
        <Thumbdiv key={file.name}>
          <Thumbinsidediv>
            <p>{file.name}</p>
            <RemoveButton onClick={removeFile(file)}>x</RemoveButton>
          </Thumbinsidediv>
        </Thumbdiv>
      ));
    } else if (savedFileName.length > 0)
      return (
        <Thumbdiv key={savedFileName}>
          <Thumbinsidediv>
            <p>{savedFileName}</p>
            <RemoveButton onClick={removeFile(null)}>x</RemoveButton>
          </Thumbinsidediv>
        </Thumbdiv>
      );
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Container>
      {
        <>
          <Label
            className={styles.formLabel}
          // dangerouslySetInnerHTML={{ __html: JSON.parse(label)?.[language] + (question.required ? "*" : "")}}
          >
            <div dangerouslySetInnerHTML={{ __html: JSON.parse(label)?.[language] + (question.required ? "*" : "") }} />
          </Label>
          {files.length === 0 &&
            savedFileName.length === 0 &&
            currentFileName == "" && (
              <DropZoneStyle {...getRootProps({ className: "dropzone" })}>
                {isUploading && (
                  <p>
                    {t("qa__uploading_doc")}
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      color="blue"
                      spin
                    ></FontAwesomeIcon>
                  </p>
                )}
                {!isUploading && (
                  <>
                    <input {...getInputProps()} />
                    <p>{t("qa__upload_doc")}</p>
                  </>
                )}
              </DropZoneStyle>
            )}
          <ThumbsContainer>{thumbs()}</ThumbsContainer>
          <input
            key={`${question.id}_signature`}
            type={"hidden"}
            {...register(question.id, {
              required: question?.required,
              maxLength: question?.maxLength,
              minLength: question?.minLength,
              pattern: new RegExp(question?.pattern),
            })}
          ></input>
        </>
      }
    </Container>
  );
};

const Thumbdiv = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 2px solid #3d76f8;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  border-style: dashed;
  border-width: 1px;
  border-color: #3d76f8;
`;

const CurrentImageContainer = styled.img`
  object-fit: cover;
  width: 90%;
  max-width: 400px;
`;

const DropZoneStyle = styled.div`
  margin: 15px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 1px;
  border-style: dashed;
  border-width: 1px;
  border-color: #3d76f8;
  margin-bottom: 20;
  align-items: center;
  padding: 10px;
  color: #808080;
`;

const Thumbinsidediv = styled.div`
  display: flex;
  overflow: hidden;
  color: #3d76f8;
  margin: 10px 0;
`;

const Thumbimg = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;
const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const RemoveButton = styled.a`
  display: block;
  color: #fff;
  background-color: #3d76f8;
  border-color: #3d76f8;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 20px;
  height: 20px;
`;
const Container = styled.div`
  margin: 10px 0px;
  width: 100%;
`;
const Label = styled.div`
  width: 100%;
  font-size: 14px;
`;
