import { toast } from "react-toastify";
import { config } from "../../config";
import { ThunkDispatch } from "../../store";
import { getIdCardInfo } from "../../utils/getIdCardInfo";
import {
  sendIdCardsSuccess,
  sendIdCardsProcessing,
  sendIdCardsFailure,
  resetDocument,
  sendVideoSuccess,
  sendVideoProcessing,
  sendVideoFailure,
} from "./actions";
import axios from "axios";
import { imageResize } from "../../utils/imageResize";

import * as dotenv from "dotenv";
import { clearToken } from "../otp/actions";
import { push } from "connected-react-router";
import { TFunction } from "react-i18next";
dotenv.config();

axios.defaults.timeout = 30000;
axios.defaults.timeoutErrorMessage = "axios_connection_timeout";

export function sendIdcardsThunk(
  country: string,
  token: string,
  merchantId: number,
  profileId: number,
  idFront: File,
  idBack: File,
  setIsOpen: (v: boolean) => void,
  setPercentage: (v: number | string) => void,
  path: string
) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(sendIdCardsProcessing());
      const resizedIdFront = await imageResize(idFront);
      const resizedIdBack = await imageResize(idBack);

      const formData = new FormData();
      formData.append("token", token);
      formData.append("idFront", resizedIdFront);
      formData.append("idBack", resizedIdBack);
      formData.append("merchantId", merchantId.toString());
      formData.append("profileId", profileId.toString());
      if (country !== "cn" && country !== "my") country = "hk";
      const options = {
        onUploadProgress: function (progressEvent: any) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted >= 100) {
            setPercentage("Progressing");
          } else {
            setPercentage(`${percentCompleted}%`);
          }
        },
      };
      return axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/id/${country}`,
          formData,
          options
        )
        .then((res) => {
          if (res.status == 200) {
            const idInfo = getIdCardInfo(res.data.data, country);
            dispatch(sendIdCardsSuccess(idInfo, resizedIdFront, resizedIdBack));
          }
        })
        .catch((error) => {
          if (error.message === "axios_connection_timeout") {
            dispatch(sendIdCardsFailure("Connection Time Out"));
            return "Connection Time Out";
          } else if (error.response.status != 200) {
            dispatch(sendIdCardsFailure("Internal Server Error"));
            if (error.response.data.isExpired) {
              localStorage.clear();
              dispatch(clearToken());
              dispatch(push(`${path}`));
              return error.response.data.message
            }
            setIsOpen(true);
            return error.response.data.message           
          }
        });
    } catch (err) {
      dispatch(sendIdCardsFailure("Internal Server Error"));
    }
  };
}

export function sendVideoThunk(
  token: string,
  merchantId: number,
  profileId: number,
  video: File,
  setIsOpen: (v: boolean) => void,
  setPercentage: (v: number | string) => void,
  path: string,
  t: TFunction<"translation">
) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(sendVideoProcessing());
      const formData = new FormData();
      formData.append("token", token);
      formData.append("file", video);
      formData.append("merchantId", merchantId.toString());
      formData.append("profileId", profileId.toString());
      formData.append("type", "video");

      const options = {
        onUploadProgress: function (progressEvent: any) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted >= 100) {
            setPercentage("Progressing");
          } else {
            setPercentage(`${percentCompleted}%`);
          }
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/id/video`,
          formData,
          options
        )
        .then((res) => {
          if (res.status == 200) {
            dispatch(sendVideoSuccess(video));
          }
        })
        .catch((error) => {
          if (error.message === "axios_connection_timeout") {
            dispatch(sendVideoFailure("Connection Time Out"));
            toast.error("Connection Time Out");
          } else if (error.response && error.response.status != 200) {
            dispatch(sendVideoFailure("Internal Server Error"));
            if (error.response.data.isExpired) {
              localStorage.clear();
              dispatch(clearToken());
              toast.error(t(error.response.data.message));
              dispatch(push(`${path}`));
            }
            setIsOpen(true);
            toast.error(t(error.response.data.message));
          } else {
            dispatch(sendVideoFailure("Internal Server Error"));
            toast.error("Internal Server Error");
          }
        });
    } catch (err) {
      dispatch(sendVideoFailure("Internal Server Error"));
    }
  };
}
