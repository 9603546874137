import React, { useEffect, useState } from "react";
import {
  UseFormRegister,
  FieldValues,
  UseFormUnregister,
  UseFormSetValue,
  UseFormWatch,
  UseFormSetError,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { IRootState } from "../../store";
import { AdditionalFields, Question } from "../../types";
import styles from "../../css/form.module.css";

interface Props {
  question: Question;
  index?: number;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  elementGenerator: (
    question: Question,
    index: number,
    register: UseFormRegister<FieldValues>,
    unregister: UseFormUnregister<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    component: boolean
  ) => JSX.Element | null;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setError?: UseFormSetError<FieldValues>;
  }

interface Option {
  key: string;
  text: { en: string; zh: string; cn: string };
  value: string;
  condition? : boolean;
}

export const AdditionalQuestionCheckbox: React.FC<Props> = ({
  register,
  unregister,
  question,
  elementGenerator,
  setValue,
  index,
}) => {
  const [checked, setChecked] = useState<string[]>([]);
  const [condition, setCondition] = useState<boolean>();
  const language = useSelector((state: IRootState) => state.setting.language);
  const qaData = useSelector((state: IRootState) => state.qa.qaData);

  useEffect(() => {
      if (condition == false) {
            question.additionalFields?.map(field => unregister(field.key));
      }
  }, [condition]);

  const onClick = (option : Option) => () => {
    const tempCheck = [...checked];
    if (tempCheck.includes(option.value)) {
      tempCheck.splice(tempCheck.indexOf(option.value), 1);
    } else tempCheck.push(option.value);
    setChecked(tempCheck);
    const uniqueCheck = tempCheck.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    setValue(question.id, uniqueCheck);
  };

  useEffect(() => {
    const tempCheck = [...checked];
    if (qaData[question.id]) {
      if (Array.isArray(qaData[question.id])) {
        qaData[question.id].forEach((checkboxes: any) => {
          if (!tempCheck.includes(checkboxes.value)) 
            tempCheck.push(checkboxes.value);
        });
      } else {
        tempCheck.push(qaData[question.id].value);
      }
    }
    setChecked(tempCheck);
  }, [qaData]);

  useEffect(() => {
    const conditionOptions = question.options.filter((option: Option) => option.condition);
    // if (checked){
    setCondition(conditionOptions.some(option => {return checked.includes(option.value)}))
  },[checked])

  return (
    <>
      {question.label?.[language] && (
        <label className={styles.formLabel}>
          <div
            key={`${question.id}_label_${index}`}
            dangerouslySetInnerHTML={{ __html: question.label?.[language] + (question.required ? "*" : "")}}
          />
        </label>
      )}
      <div
        className={styles.checkboxes}
        style={question.inline ? {} : { display: "block" }}
      >
        {question.options.map((option, index) => (
          <div
            onClick={onClick(option)}
            key={index}
            style={{ minWidth: "30vw" }}
            className={styles.button}
          >
            <div
              className={
                checked.includes(option.value)
                  ? styles.checkedOption
                  : styles.uncheckedOption
              }
            >
              <div className={styles.checkBoxOptionBox}>
                <div
                  className={
                    checked.includes(option.value)
                      ? styles.checkedOptionBall
                      : styles.optionBall
                  }
                ></div>
              </div>
              <div className={styles.optionTextBox}>
                <div className={styles.optionText}>
                  {option.text?.[language]}
                </div>
              </div>
            </div>
          </div>
        ))}
        <input
          key={`${question.id}`}
          type={"hidden"}
          {...register(question.id, {
            required: question?.required,
            maxLength: question?.maxLength,
            minLength: question?.minLength,
            pattern: new RegExp(question?.pattern),
          })}
        ></input>
      </div>
      {condition && question.additionalFields?.map((field : any, index) => 
        <React.Fragment key={field.key + index}>
          {elementGenerator(
            { ...field, id: field.key },
            index,
            register,
            unregister,
            setValue,
            true
          )}
        </React.Fragment>
      )}
    </>
  );
};
