import React, { Fragment, useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import { push } from "connected-react-router";
import { PageTitle } from "../../components/PageTitle";
import "../../css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "../../store";
import LoadingOverlay from "../../components/LoadingOverlay";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import styled from "styled-components";
import "react-h5-audio-player/lib/styles.css";
import {
  faPlay,
  faStop,
  faVolumeUp,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  watchAllMediaFailed,
  watchAllMediaSuccess,
} from "../../redux/important_disclosure/acitons";
import { updateAgreementThunk } from "../../redux/important_disclosure/thunk";

interface Props {
  page: number;
  path: string;
  onSubmit: () => void;
}

export const IDMedia: React.FC<Props> = ({ page, path, onSubmit }: Props) => {
  const dispatch = useDispatch();
  const token = useSelector((state: IRootState) => state.otp.caseToken);
  const language = useSelector((state: IRootState) => state.setting.language);
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [mediaPlaying, setMediaPlaying] = useState<Array<boolean>>([]);
  const [mediaMuted, setMediaMuted] = useState<Array<boolean>>([]);

  const [mediaPlayed, setMediaPlayed] = useState<Array<boolean>>([]);
  const [mediaChecked, setMediaChecked] = useState<Array<boolean>>([]);

  const importantDisclosures = useSelector(
    (state: IRootState) => state.form.importantDisclosure
  );
  const [percentage, setPercentage] = useState<number | string>(0);
  const { t } = useTranslation();
  const [isSubmit, setIsSubmit] = useState(false);
  const isSuccess = useSelector(
    (state: IRootState) => state.importantDisclosure.isSuccess
  );

  useEffect(() => {
    if (!token || Object.values(token).length <= 0) {
      dispatch(push(`${path}`));
      return;
    }
  }, []);

  useEffect(() => {
    if (importantDisclosures?.length <= 0) return;
    let tempArr: boolean[] = [];
    tempArr.length = importantDisclosures.length;
    tempArr.fill(false);
    setMediaPlaying(tempArr);
    setMediaMuted(tempArr);
    importantDisclosures.forEach((importantDisclosure: any, index: number) => {
      if (
        importantDisclosure.feature_key !== "id_audio" &&
        importantDisclosure.feature_key !== "id_video"
      ) {
        tempArr[index] = true;
      }
    });
    setMediaPlayed(tempArr);
    setMediaChecked(tempArr);
  }, [importantDisclosures]);

  useEffect(() => {
    if (mediaChecked.length > 0 && mediaChecked.every(Boolean))
      dispatch(watchAllMediaSuccess());
  }, [mediaChecked]);

  useEffect(() => {
    if (isSuccess && isSubmit) dispatch(push(`${path}/idCard`));
  }, [isSuccess, isSubmit]);

  const selectSrcByLanguage = (
    importantDisclosure: any,
    language: "en" | "cn" | "zh"
  ) => {
    if (language === "en") return importantDisclosure.feature_content;
    if (language === "cn") return importantDisclosure.feature_content_cn;
    if (language === "zh") return importantDisclosure.feature_content_zh;
  };

  return (
    <>
      <LoadingOverlay percentage={percentage} />
      <Navbar canGoBack={true} />
      <div className="container" id="id_card">
        <PageTitle page={page} title={t("disclosure__title")} />
        <div>
          {/* <div className="contentText">{t("doc_id__import")}</div> */}
          {importantDisclosures &&
            importantDisclosures.map(
              (importantDisclosure: any, index: number) => {
                if (importantDisclosure.feature_key === "id_audio")
                  return (
                    <Fragment key={index}>
                      <p>{t("disclosure__audio_hint")}</p>
                      <div>
                        <AudioPlayer
                          // src={importantDisclosure.feature_content}
                          src={selectSrcByLanguage(
                            importantDisclosure,
                            language
                          )}
                          onEnded={() => {
                            let tempArr = [...mediaPlayed];
                            tempArr[index] = true;
                            setMediaPlayed(tempArr);
                          }}
                          showSkipControls={false}
                          showJumpControls={false}
                          showDownloadProgress={false}
                        ></AudioPlayer>
                      </div>
                      <br />
                      <div className={mediaPlayed[index] ? "" : "greyText"}>
                        <input
                          type="checkBox"
                          disabled={!mediaPlayed[index]}
                          required
                          onClick={() => {
                            let tempArr = [...mediaChecked];
                            tempArr[index] = !tempArr[index];
                            setMediaChecked(tempArr);
                          }}
                        ></input>
                        {t("disclosure__audio_agree")}
                      </div>
                      <br />
                    </Fragment>
                  );
                if (importantDisclosure.feature_key === "id_video")
                  return (
                    <Fragment key={index}>
                      <p>{t("disclosure__video_hint")}</p>
                      <div
                        key={index}
                        className="flex flexDirectionColumn alignitemsCenter"
                      >
                        <ReactPlayer
                          width={"100%"}
                          height={"100%"}
                          playing={mediaPlaying[index]}
                          muted={mediaMuted[index]}
                          style={{ pointerEvents: "none" }}
                          playsinline
                          onEnded={() => {
                            let tempArr = [...mediaPlayed];
                            tempArr[index] = true;
                            setMediaPlayed(tempArr);
                          }}
                          url={selectSrcByLanguage(
                            importantDisclosure,
                            language
                          )}
                          // url={importantDisclosure.feature_content}
                        ></ReactPlayer>
                        <br />
                        <VideoControlPanel>
                          <VideoButton
                            onClick={() => {
                              setPlaying(!playing);
                              let tempArr = [...mediaPlaying];
                              tempArr[index] = !tempArr[index];
                              setMediaPlaying(tempArr);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"lg"}
                              icon={mediaPlaying[index] ? faStop : faPlay}
                              className="video_icon"
                            />
                          </VideoButton>
                          <VideoButton
                            onClick={() => {
                              let tempArr = [...mediaMuted];
                              tempArr[index] = !tempArr[index];
                              setMediaMuted(tempArr);
                              setMuted(!muted);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"lg"}
                              icon={
                                mediaMuted[index] ? faVolumeMute : faVolumeUp
                              }
                              className="video_icon"
                            />
                          </VideoButton>
                        </VideoControlPanel>
                      </div>
                      <br />
                      <div className={mediaPlayed[index] ? "" : "greyText"}>
                        <input
                          type="checkBox"
                          required
                          disabled={!mediaPlayed[index]}
                          onClick={() => {
                            let tempArr = [...mediaChecked];
                            tempArr[index] = !tempArr[index];
                            setMediaChecked(tempArr);
                          }}
                          checked={mediaChecked[index] ? true : false}
                        ></input>
                        {t("disclosure__video_agree")}
                      </div>
                      <br />
                    </Fragment>
                  );
              }
            )}
        </div>
        <button
          className={
            token && mediaChecked.every(Boolean)
              ? "button bottomMargin"
              : "disabledButton bottomMargin"
          }
          disabled={!token || !mediaChecked.every(Boolean)}
          onClick={() => {
            if (!token) return;
            // dispatch(updateAgreementThunk(token, path));
            setIsSubmit(true);
            dispatch(watchAllMediaSuccess());
            onSubmit();
            // add thunk for agreement time (agreed all disclosure);
          }}
        >
          <div className="buttonText">{t("Submit")}</div>
        </button>
      </div>
    </>
  );
};

const VideoControlPanel = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  line-height: 1;
  justify-content: space-around;
  font-family: inherit;
  width: 100%;
  padding: 10px 15px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
`;

const VideoButton = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-family: inherit;
  height: 30px;
  width: 30px;
  font-size: 14px;
  border-radius: 25px;
  padding: 10px 15px;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
`;
