import React, { Fragment, useEffect, useState } from "react";
import {
  UseFormRegister,
  FieldValues,
  UseFormSetValue,
  DeepMap,
  FieldError,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { setConstantValue } from "typescript";
import { IRootState } from "../../store";
import { Question } from "../../types";
import styles from "../../css/form.module.css";

interface Props {
  question: Question;
  index: number;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  // errors: DeepMap<FieldValues, FieldError>
  unregister: UseFormUnregister<FieldValues>;
}

interface Option {
  // label: string,
  text: { en: string; zh: string; cn: string };
  value: string;
}

export const CheckBoxes: React.FC<Props> = ({
  question,
  index,
  setValue,
  register,
}) => {
  const language = useSelector((state: IRootState) => state.setting.language);
  const [checked, setChecked] = useState<string[]>([]);
  const qaData = useSelector((state: IRootState) => state.qa.qaData);

  useEffect(() => {
    const tempCheck = [...checked];
    if (qaData[question.id]) {
      if (Array.isArray(qaData[question.id])) {
        qaData[question.id].forEach((checkboxes: any) => {
          if (!tempCheck.includes(checkboxes.value))
            tempCheck.push(checkboxes.value);
        });
      } else {
        tempCheck.push(qaData[question.id].value);
      }
    }
    setChecked(tempCheck);
  }, [qaData]);

  const handleOnChecked = (value: string) => {
    const tempCheck = [...checked];
    if (tempCheck.indexOf(value) > -1) {
      tempCheck.splice(tempCheck.indexOf(value), 1);
    } else tempCheck.push(value);
    setChecked(tempCheck);
    const uniqueCheck = tempCheck.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    setValue(question.id, uniqueCheck);
  };

  return (
    <>
      {question.label?.[language] && (
        <label className={styles.formLabel}>
          <div
            key={`${question.id}_label_${index}`}
            dangerouslySetInnerHTML={{ __html: question.label?.[language] + (question.required ? "*" : "")}}
          />
        </label>
      )}
      <div
        className={styles.checkboxes}
        style={question.inline ? {} : { display: "block" }}
      >
        {question.options.map((option: Option, index: number) => (
          <div
            onClick={() => handleOnChecked(option.value)}
            key={index}
            className={styles.button}
          >
            <div
              className={
                checked.indexOf(option.value) > -1
                  ? styles.checkBoxCheckedOption
                  : styles.checkBoxUncheckedOption
              }
            >
              <div className={styles.checkBoxOptionBox}>
                <div
                  className={
                    checked.indexOf(option.value) > -1
                      ? styles.checkedOptionBall
                      : styles.optionBall
                  }
                ></div>
              </div>
              <div className={styles.optionTextBox}>
                <div className={styles.optionText}>{option.text[language]}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <input
        key={`${question.id}`}
        type={"hidden"}
        {...register(question.id, {
          required: question?.required,
          maxLength: question?.maxLength,
          minLength: question?.minLength,
          pattern: new RegExp(question?.pattern),
        })}
      ></input>
    </>
  );
};
