import { IdCardData } from "../../types"

export function sendIdCardsProcessing(){
    return {
        type: "@@document/SEND_IDCARD_PROCESSING" as "@@document/SEND_IDCARD_PROCESSING",
        isProcessing: true,
        isSuccess:false,
    }  
}

export function sendIdCardsSuccess(idCardData:IdCardData, idFront:File, idBack:File){
    return {
        type: "@@document/SEND_IDCARD_SUCCESS" as "@@document/SEND_IDCARD_SUCCESS",
        isProcessing: false,
        isSuccess:true,
        idCardData: idCardData,
        idFront: idFront, 
        idBack: idBack
    }  
}

export function sendIdCardsFailure(errorMessage:string){
    return {
        type:"@@document/SEND_IDCARD_FAILURE" as "@@document/SEND_IDCARD_FAILURE",
        isProcessing: false,
        isSuccess: false,
        errorMessage: errorMessage
    }
}

export function sendVideoProcessing(){
    return {
        type: "@@document/SEND_VIDEO_PROCESSING" as "@@document/SEND_VIDEO_PROCESSING",
        isProcessing: true,
        isSuccess:false,
    }  
}

export function sendVideoSuccess(video:File){
    return {
        type: "@@document/SEND_VIDEO_SUCCESS" as "@@document/SEND_VIDEO_SUCCESS",
        isProcessing: false,
        isSuccess:true,
        video: video
    }  
}

export function sendVideoFailure(errorMessage:string){
    return {
        type:"@@document/SEND_VIDEO_FAILURE" as "@@document/SEND_VIDEO_FAILURE",
        isProcessing: false,
        isSuccess: false,
        errorMessage: errorMessage
    }
}

export function resetDocument(){
    return {
        type: "@@document/RESET" as "@@document/RESET",
    }
}

type DocumentActionCreators = 
    typeof sendIdCardsProcessing | 
    typeof sendIdCardsSuccess | 
    typeof sendIdCardsFailure | 
    typeof sendVideoProcessing | 
    typeof sendVideoSuccess | 
    typeof sendVideoFailure | 
    typeof resetDocument

export type IDocumentActions = ReturnType<DocumentActionCreators>

