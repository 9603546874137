import React, { useEffect, useState } from "react";
import "../css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { IRootState } from "../store";
import { push } from "connected-react-router";
import { NotFound } from "./notFound";
import { QA } from "../components/form/QA";
import { loadQAThunk } from "../redux/qa/thunk";

interface Props {
  page: number;
  path: string;
  forms: any;
}

export const QARoute: React.FC<Props> = ({ page, path, forms }: Props) => {
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!caseToken || Object.values(caseToken).length <= 0) {
      dispatch(push(`${path}`));
      return;
    }
    dispatch(loadQAThunk(caseToken));
  }, [pathname]);

  return (
    <>
      <Switch>
        {forms &&
          forms.map((form: any, index: number) => (
            <Route key={`QA_${index}`} path={`${path}/qa/${index}`}>
              <QA page={page} form={form} index={index} path={path} />
            </Route>
          ))}
        <Route path={`${path}/qa`} component={NotFound}></Route>
      </Switch>
    </>
  );
};
