import { PrevMedia } from './state'

export function loadPrevMediaProcessing(){
    return {
        type: "@@media/LOAD_PREV_MEDIA_PROCESSING" as "@@media/LOAD_PREV_MEDIA_PROCESSING",
        isProcessing: true,
    }  
}

export function loadPrevMediaSuccess(prevMedia: any){
    return {
        type: "@@media/LOAD_PREV_MEDIA_SUCCESS" as "@@media/LOAD_PREV_MEDIA_SUCCESS",
        isProcessing: false,
        prevIdFront: prevMedia?.idFront,
        prevIdBack: prevMedia?.idBack,
        prevVideo: prevMedia?.video,
        prevFace: prevMedia?.face,
        prevDynamicFace: prevMedia?.pitchDownward || prevMedia?.pitchUpward
    }  
}

export function loadPrevMediaFailure(){
    return{
        type: "@@media/LOAD_PREV_MEDIA_FAILURE" as "@@media/LOAD_PREV_MEDIA_FAILURE",
        isProcessing: false,
        isSuccess: false
    }
}

export function resetPrevIdCard(){
    return {
        type: "@@media/RESET_IDCARD" as "@@media/RESET_IDCARD",
        isProcessing: false,
        isSuccess: false,
        prevIdFront: null,
        prevIdBack: null
    }
}

export function resetPrevVideo(){
    return {
        type: "@@media/RESET_VIDEO" as "@@media/RESET_VIDEO",
        isProcessing: false,
        isSuccess: false,
        prevVideo: null,
    }
}

export function resetPrevFace(){
    return {
        type: "@@media/RESET_FACE" as "@@media/RESET_FACE",
        isProcessing: false,
        isSuccess: false,
        prevFace: null,
    }
}

export function resetPrevDynamicFace(){
    return {
        type: "@@media/RESET_DYNAMIC_FACE" as "@@media/RESET_DYNAMIC_FACE",
        isProcessing: false,
        isSuccess: false,
        prevDynamicFace: null,
    }
}

type PrevMediaActionCreators = 
    typeof loadPrevMediaProcessing | 
    typeof loadPrevMediaSuccess | 
    typeof loadPrevMediaFailure | 
    typeof resetPrevIdCard |
    typeof resetPrevVideo |
    typeof resetPrevFace |
    typeof resetPrevDynamicFace

export type IPrevMediaActions = ReturnType<PrevMediaActionCreators>
