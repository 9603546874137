import React, { useEffect } from "react";
import styles from "../css/navbar.module.css";
import "../css/index.css";
import { Logo } from "./Logo";
import { goBack } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { LanguagePicker } from "./LanguagePicker";
import { FieldValues, UseFormTrigger } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IRootState } from "../store";

interface Props {
  isValid?: boolean;
  trigger?: UseFormTrigger<FieldValues>;
  canGoBack?: boolean;
}

export const Navbar: React.FC<Props> = ({ isValid, trigger, canGoBack }) => {
  const { t } = useTranslation();
  const icon = useSelector((state: IRootState) => state.form.icon);
  const dispatch = useDispatch();
  const backOnClick = () => {
    if (trigger) {
      if (!isValid) return trigger();
      if (isValid) return dispatch(goBack());
    } else {
      return dispatch(goBack());
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div
          className={"blueText"}
          style={{ whiteSpace: "nowrap" }}
          onClick={backOnClick}
        >
          {canGoBack && t("back")}
        </div>
        {icon && (
          <img
            style={{
              objectFit: "contain",
              height: "50px",
            }}
            src={icon}
          />
        )}
        <LanguagePicker
          options={[
            { label: "EN", value: "en" },
            { label: "CN", value: "cn" },
            { label: "ZH", value: "zh" },
          ]}
        />
      </div>
    </>
  );
};
