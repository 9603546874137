import React, { useEffect } from 'react';
import { Navbar } from '../components/navbar';
import { push } from 'connected-react-router';
import { PageTitle } from '../components/PageTitle';
import '../css/index.css'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IRootState } from '../store';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from "../utils/Utils";

interface Props{
    page: number
    path: string
} 

export const TNC: React.FC<Props> = ({page, path}:Props) =>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const caseToken = useSelector((state:IRootState)=>state.otp.caseToken)
    // const [currentPage, setCurrentPage] = useState(1);
    // const { path, url } = useRouteMatch();
    let query = useQuery();
    
    // useEffect(()=>{
    //     if(!caseToken || caseToken.length <= 0) return
    //     dispatch(push(`${path}/idCard`));
    // },[caseToken])

    return (
        <>
            <Navbar canGoBack={true}/>
            <div className="container">
                <PageTitle title={t('read_terms_and_conditions')} page={page}/>
                <div>
                    <div className="tncText" >
                        <div>{t("tnc__1")}</div>
                        <div>{t("tnc__2")}</div>
                        <div>{t("tnc__3")}</div>
                        <div>{t("tnc__4")}</div>
                        <div>{t("tnc__5")}</div>
                        <div>{t("tnc__6")}</div>
                        <div>{t("tnc__7")}</div>
                        <div>{t("tnc__8")}</div>
                        <div>{t("tnc__8a")}</div>
                        <div>{t("tnc__8b")}</div>
                        <div>{t("tnc__8c")}</div>
                        <div>{t("tnc__8d")}</div>
                    </div>
                </div>
                <button 
                    className="button bottomMargin"
                    onClick={()=>{
                        if (query.get("ref")) {
                            dispatch(push(`${path}/otp/0?ref=${query.get("ref")}`));
                        } else {
                            dispatch(push(`${path}/otp/0`));
                        }
                    }}> 
                    <div className="buttonText">{t("agree")}</div>    
                </button>
            </div>
        </>
    )
}
