import { IOTPState } from "./state";
import { IOTPActions } from "./actions";

export const initOTPState: IOTPState = {
  isProcessing: false,
  isSent: false,
  isVerified: false,
  currentPage: null,
  agreementTime: null,
  caseToken: null,
  errorMessage: null,
  profileHash: null,
};

export const otpReducers = (
  state: IOTPState = initOTPState,
  action: IOTPActions
): IOTPState => {
  switch (action.type) {
    case "@@otp/SEND_OTP_PROCESSING":
      return {
        ...state,
        isProcessing: action.isProcessing,
      };
    case "@@otp/SEND_OTP_SUCCESS":
      return {
        ...state,
        isSent: action.isSent,
        isProcessing: action.isProcessing,
      };
    case "@@otp/SEND_OTP_ANOTHER_NUMBER":
      return {
        ...state,
        isSent: action.isSent,
        isVerified: action.isVerified,
        isProcessing: action.isProcessing,
      };
    case "@@otp/SEND_OTP_FAIL":
      return {
        ...state,
        isVerified: action.isVerified,
        isProcessing: action.isProcessing,
      };
    case "@@otp/VERIFY_OTP_SUCCESS":
      return {
        ...state,
        isProcessing: action.isProcessing,
        isVerified: action.isVerified,
        caseToken: action.caseToken,
        currentPage: action.currentPage,
        agreementTime: action.agreementTime,
      };
    case "@@otp/SEND_OTP_FAIL":
      return {
        ...state,
        errorMessage: action.errorMessage,
        isProcessing: action.isProcessing,
        isVerified: action.isVerified,
      };
    case "@@otp/RESET_OTP":
      return {
        isProcessing: initOTPState.isProcessing,
        isSent: initOTPState.isSent,
        isVerified: initOTPState.isVerified,
        currentPage: initOTPState.currentPage,
        agreementTime: initOTPState.agreementTime,
        caseToken: initOTPState.caseToken,
        errorMessage: initOTPState.errorMessage,
        profileHash: initOTPState.profileHash,
      };
    case "@@otp/CLEAR_TOKEN":
      return {
        ...state,
        isVerified: action.isVerified,
        isProcessing: action.isProcessing,
        caseToken: action.caseToken,
        currentPage: action.currentPage,
      };
    case "@@otp/VERIFY_REACTIVATE_OTP_SUCCESS":
      return {
        ...state,
        isVerified: action.isVerified,
        isProcessing: action.isProcessing,
        caseToken: action.caseToken,
        profileHash: action.profileHash,
      }
    default:
      return state;
  }
};
