import React, { useEffect, useState } from "react";
import {
  UseFormRegister,
  FieldValues,
  UseFormSetValue,
  DeepMap,
  FieldError,
  UseFormUnregister,
  UseFormWatch,
  UseFormSetError,
} from "react-hook-form";
import { Question } from "../../types";
import styles from "../../css/form.module.css";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../store";
//@ts-ignore
import SelectDatePicker from "@netojose/react-select-datepicker";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  question: Question;
  index: number;
  // minDate?: string;
  // maxDate?: string;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  unregister: UseFormUnregister<FieldValues>;
  component?: boolean;
  setError?: UseFormSetError<FieldValues>;
}

export const FormDatePicker: React.FC<Props> = ({
  question,
  index,
  setValue,
  register,
  errors,
  unregister,
  component = false,
  // setError
  // minDate,
  // maxDate,
}) => {
  const caseData = useSelector((state: IRootState) => state.form.caseData);
  const qaData = useSelector((state: IRootState) => state.qa.qaData);
  const idCardData = useSelector(
    (state: IRootState) => state.document.idCardData
  );
  const [startDate, setStartDate] = useState<Date>();
  const language = useSelector((state: IRootState) => state.setting.language);
  const { t } = useTranslation();
  const onChange = (date: Date) => {
    if (!date) return;
    if (question.id === "birthday") {
      setValue("birth_year", date.getFullYear());
      setValue("birth_month", date.getMonth() + 1);
      setValue("birth_day", date.getDate());
    } else {
      setValue(question.id, Math.round(date.getTime() / 1000));
    }
  };

  useEffect(() => {
    return () => {
      if (question.id === "birthday") {
        unregister("birth_day");
        unregister("birth_year");
        unregister("birth_month");
      } else {
        unregister(question.id);
      }
    };
  }, []);

  useEffect(() => {
    if (question.id === "birthday") {
      register("birth_day", { required: true });
      register("birth_year", { required: true });
      register("birth_month", { required: true });
    } else {
      // register(question.id, { required: question.required ,
      //   validate: { 
      //     futureDataOnly: (v) => question.afterToday ? v > Math.round(new Date().getTime()/ 1000 ) : true ,
      //     pastDataOnly: (v) => question.beforeToday ? v < Math.round(new Date().getTime()/ 1000 ) : true 
      //   }
      // });
    }
  }, [register]);

  useEffect(() => {
    if (!startDate) return;
    if (question.id === "birthday") {
      setValue("birth_year", startDate.getFullYear());
      setValue("birth_month", startDate.getMonth() + 1);
      setValue("birth_day", startDate.getDate());
    } else {
      setValue(question.id, Math.round(startDate.getTime() / 1000));
    }
  }, [startDate]);

  // load previous data
  useEffect(() => {
    if (!caseData) return;
    if (question.id === "birthday") {
      if (
        caseData["birth_year"] &&
        caseData["birth_month"] &&
        caseData["birth_day"]
      ) {
        setStartDate(
          new Date(
            Date.UTC(
              caseData["birth_year"],
              caseData["birth_month"] - 1,
              caseData["birth_day"]
            )
          )
        );
      }
    } else {
      if (caseData[question.id]) {
        try {
          setStartDate(new Date(JSON.parse(caseData[question.id]) * 1000));
        } catch (error) {
          console.log("json parse error");
        }
      }
    }
    if (qaData[question.id]) {
      try {
        setStartDate(new Date(JSON.parse(qaData[question.id]?.value) * 1000));
      } catch (error) {
        console.log("json parse error")
      } 
    }
  }, [caseData, qaData]);

  //load id Card OCR
  useEffect(() => {
    if (!idCardData) return;
    if (question.id === "birthday") {
      if (
        idCardData["birth_year"] &&
        idCardData["birth_month"] &&
        idCardData["birth_day"]
      ) {
        setStartDate(
          new Date(
            parseInt(idCardData["birth_year"]),
            parseInt(idCardData["birth_month"]) - 1,
            parseInt(idCardData["birth_day"])
          )
        );
      }
    } else {
      setStartDate(new Date());
    }
  }, []);

  useEffect(() => {
    if (question.defaultToday) {
      setStartDate(new Date());
      onChange(new Date());
    }
  }, [question.defaultToday]);

  return (
    <>
      {question.label?.[language] && (
        <label className={styles.formLabel}>
          <div
            key={`${question.id}_label_${index}`}
            dangerouslySetInnerHTML={{ __html: question.label?.[language] + (question.required ? "*" : "")}}
          />
        </label>
      )}
      <DatePickerContainer
        style={question.readOnly ? { pointerEvents: "none" } : {}}
        //@ts-ignore
        color={question?.readOnly.toString()}
      >
        <SelectDatePicker
          format={"year/month/day"}
          className={styles.datePicker}
          value={startDate}
          minDate={question.afterToday ? new Date() : new Date(1900, 0, 1)}
          maxDate={question.beforeToday ? new Date() : new Date(2070, 0, 1)}
          onDateChange={onChange}
          yearLabel={t("datePicker_year")}
          monthLabel={t("datePicker_month")}
          dayLabel={t("datePicker_day")}
          errorMin={"The Date is in the past"}
          errorMax={"The Date is in the future"}
          monthNames={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
        />
      </DatePickerContainer>
      {(errors["birth_day"] ||
        errors["birth_month"] ||
        errors["birth_year"]) && (
        <div key={"birthday_error"} className={styles.errorText}>
          {t("warning__required")}
        </div>
      )}
      { question.id !== "birthday" && <input type={"hidden"} {...register(question.id, { required: question.required ,
        validate: { 
          futureDataOnly: (v) => question.afterToday ? v > Math.round(new Date().getTime()/ 1000 ) : true ,
          pastDataOnly: (v) => question.beforeToday ? v < Math.round(new Date().getTime()/ 1000 ) : true 
        }})} />}

      {component && errors?.[question.id] && (
        <div key={"component_birthday_error"} className={styles.errorText}>
          {t("warning__required")}
        </div>
      )}
      {question.description && (
        <div
          key={`${question.id}_description_${index}`}
          className={styles.formDes}
        >
          {question.description?.[language]
            ? question.description?.[language]
            : question.description}
        </div>
      )}
    </>
  );
};

const DatePickerContainer = styled.div`
  select {
    ${(props) =>
      props.color === "true"
        ? "background-color: whitesmoke!important;"
        : "background-color: transparent;"}
  }
`;
