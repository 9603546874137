import { Dispatch } from "react";
import { config } from "../../config";
import {
  loadPrevMediaSuccess,
  loadPrevMediaFailure,
  loadPrevMediaProcessing,
  IPrevMediaActions,
} from "./actions";
import { toast } from "react-toastify";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { clearToken, IOTPActions } from "../otp/actions";
import * as dotenv from "dotenv";
import { TFunction } from "react-i18next";
dotenv.config();
export function getAllPrevMediaThunk(caseToken: string, path: string, t:TFunction<"translation">) {
  return async (
    dispatch: Dispatch<
      IPrevMediaActions | CallHistoryMethodAction | IOTPActions
    >
  ) => {
    try {
      dispatch(loadPrevMediaProcessing());
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/case/${caseToken}/media/old`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      if (res.status == 200) {
        dispatch(loadPrevMediaSuccess(data));
      } else if (res.status != 200) {
        dispatch(loadPrevMediaFailure());
        toast.error(t(data.message));
        if (data.isExpired) {
          dispatch(clearToken());
          dispatch(push(`${path}`));
        }
      }
    } catch (err) {
      dispatch(loadPrevMediaFailure());
    }
  };
}
