import React, { useEffect } from "react";
import { Question } from "../../types";
import styles from "../../css/form.module.css";
import { useSelector } from "react-redux";
import { IRootState } from "../../store";

interface Props {
  question: Question;
  index: number;
}

export const Label: React.FC<Props> = ({ question, index }) => {
  const language = useSelector((state: IRootState) => state.setting.language);
  return (
    <>
      <div className="titleBox" key={index}>
        {question.content?.[language] && (
          <label style={{color: "#6d6d6d", fontWeight: "bold", fontSize: 16, marginTop: 15, wordWrap: "break-word"}}>
            <div
              key={`${question.id}_label_${index}`}
              className={`label  
              ${question.bold ? "bold" : ""}
              ${question.italic ? "italic" : ""}
            `}
              dangerouslySetInnerHTML={{
                __html: question.content?.[language] as string + (question.required ? "*" : ""),
              }}
            />
          </label>
        )}
      </div>
    </>
  );
};
