import React, { useEffect } from "react";
import { Navbar } from "../components/navbar";
import { push } from "connected-react-router";
import { PageTitle } from "../components/PageTitle";
import "../css/index.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface Prop {
  path: string;
}

export const NotFound: React.FC<Prop> = ({ path }: Prop) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Navbar canGoBack={true} />
      <div className="container">
        <PageTitle title={""} />
        <div className="completeContainer">
          <div className="isNotVerified">
            <FontAwesomeIcon icon={faTimes} color="white"></FontAwesomeIcon>
          </div>
          <div className="title">{t("not_found_title")}</div>
          <div className="contentText">{t("not_found_content")}</div>
        </div>
        <button
          className="button"
          onClick={() => {
            dispatch(push(`${path}`));
          }}
        >
          <div className="buttonText">{t("Return")}</div>
        </button>
      </div>
    </>
  );
};
