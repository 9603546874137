import { IDocumentState } from './state';
import { IDocumentActions } from './actions';

export const initDocumentState: IDocumentState = {
    isProcessing: false,
    isSuccess: false,
    errorMessage: null, 
    idCardData: null,
    idFront: null,
    idBack: null,
    video: null
}

export const documentReducers = (state: IDocumentState = initDocumentState, action: IDocumentActions):IDocumentState => {
    switch(action.type){
        case "@@document/SEND_IDCARD_PROCESSING":
            return{
                ...state,
                isProcessing: action.isProcessing,
            }
        case "@@document/SEND_IDCARD_SUCCESS":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isSuccess: action.isSuccess,
                idFront: action.idFront,
                idBack: action.idBack,
                idCardData: action.idCardData
            }
        case "@@document/SEND_IDCARD_FAILURE":
            return{
                ...state,
                isProcessing:action.isProcessing,
                isSuccess:action.isSuccess,
                errorMessage: action.errorMessage
            }
        case "@@document/SEND_VIDEO_PROCESSING":
            return{
                ...state,
                isProcessing: action.isProcessing,
            }
        case "@@document/SEND_VIDEO_SUCCESS":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isSuccess:action.isSuccess,
                video: action.video
            }
        case "@@document/SEND_VIDEO_FAILURE":
            return{
                ...state,
                isProcessing:action.isProcessing,
                isSuccess:action.isSuccess,
                errorMessage: action.errorMessage
            }
        case "@@document/RESET":
            return{
                ...state,
                isProcessing:false,
                isSuccess:false,
                errorMessage: null
            }
        default:
            return state
    }
} 
