import React, { useCallback, useEffect, useState } from "react";
import "../css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { IRootState } from "../store";
import { push } from "connected-react-router";
import { NotFound } from "./notFound";
import { IDText } from "./important_disclosures/idText";
import { IDMedia } from "./important_disclosures/idMedia";
import { loadQAThunk } from "../redux/qa/thunk";
import { updateAgreementThunk } from "../redux/important_disclosure/thunk";
import { idText } from "typescript";
import { useTranslation } from "react-i18next";

interface Props {
  page: number;
  path: string;
  importantDiscloure: any;
}

export const ImportantDisclosureRoute: React.FC<Props> = ({
  page,
  path,
}: Props) => {
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const dispatch = useDispatch();
  const importantDisclosures = useSelector(
    (state: IRootState) => state.form.importantDisclosure
  );
  const idTexts = useSelector((state: IRootState) => state.form.idText);
  const idMedias = useSelector((state: IRootState) => state.form.idMedia);
  const agreementTime = useSelector(
    (state: IRootState) => state.otp.agreementTime
  );
  const watchedAllMedia = useSelector(
    (state: IRootState) => state.importantDisclosure.watchedAllMedia
  );
  const readAllText = useSelector(
    (state: IRootState) => state.importantDisclosure.readAllText
  );
  const isSuccess = useSelector(
    (state: IRootState) => state.importantDisclosure.isSuccess
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (!caseToken || Object.values(caseToken).length <= 0) {
      dispatch(push(`${path}`));
      return;
    }
    // if (agreementTime) dispatch(push(`${path}/idCard`));
    // if (isSuccess) dispatch(push(`${path}/idCard`));
  }, []);

  // useEffect(() => {
  //   if (agreementTime) dispatch(push(`${path}/idCard`));
  //   if (isSuccess) dispatch(push(`${path}/idCard`));
  // }, [isSuccess, agreementTime]);

  useEffect(() => {
    if (agreementTime || !caseToken) return;
    if (idTexts && idTexts.length > 0)
      dispatch(push(`${path}/important_disclosures/id_text/0`));
    else if (idMedias && idMedias.length > 0)
      dispatch(push(`${path}/important_disclosures/id_media`));
    else dispatch(push(`${path}/idCard`));
  }, []);

  const importantDisclosurerHandler = () => {
    if (!caseToken) return;
    if (
      idTexts &&
      idTexts?.length > 0 &&
      idMedias &&
      idMedias?.length > 0 &&
      readAllText
    ) {
      dispatch(push(`${path}/important_disclosures/id_media`));
    }
    if (
      (idTexts?.length && idMedias?.length && watchedAllMedia && readAllText) ||
      (idTexts?.length && readAllText && idMedias && idMedias?.length <= 0) ||
      (idMedias?.length && watchedAllMedia && idTexts && idTexts.length <= 0)
    ) {
      dispatch(updateAgreementThunk(caseToken, path, t));
    }
  };

  return (
    <>
      <Switch>
        {idTexts && idTexts.map((item: any, index: number) => {
          return (
            <Route key={`important_disclosures_${index}`} path={`${path}/important_disclosures/id_text/${index}`}>
              <IDText
                content={item}
                index={index}
                totalPage={idTexts.length}
                path={path}
                page={page}
                onSubmit={importantDisclosurerHandler}
              />
            </Route>
          )
        }
        )}
        {idMedias && (
          <Route path={`${path}/important_disclosures/id_media`}>
            <IDMedia
              path={path}
              page={page}
              onSubmit={importantDisclosurerHandler}
            />
          </Route>
        )}
        <Route
          path={`${path}/important_disclosures`}
          component={NotFound}
        ></Route>
      </Switch>
    </>
  );
};
