import "./App.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { store, history, persistor, IRootState } from "./store";
import { TNC } from "./pages/tnc";
import { Region } from "./pages/region";
import { OTP } from "./pages/otp";
import { ImportantDisclosureRoute } from "./pages/DisclosureRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IDCard } from "./pages/idCard";
import { Video } from "./pages/video";
import { NotFound } from "./pages/notFound";
import { Preview } from "./pages/preview";
// import { Helmet } from "react-helmet";

//multiple language
import "./i18n";
import { useTranslation } from "react-i18next";
import { FormRoute } from "./pages/formRoute";
import { Face } from "./pages/face";
import { Complete } from "./pages/complete";
import { Maintenance } from "./pages/maintainance";
import React, { useEffect } from "react";
import { loadFormThunk } from "./redux/form/thunk";
import { useParams, useRouteMatch } from "react-router-dom";
import { QARoute } from "./pages/QARoute";
import { getAllPrevMediaThunk } from "./redux/media/thunk";
import { Loading } from "./pages/loading";
import { verifyTokenWithProfile } from "./redux/otp/thunk";
import { useLocation } from "react-router-dom";
import { OtpRoute } from "./pages/OtpRoute";
import { FaceRoute } from "./pages/FaceRoute";

function Routes() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const forms = useSelector((state: IRootState) => state.form.form);
  const qa = useSelector((state: IRootState) => state.form.qa);
  const importantDisclosure = useSelector(
    (state: IRootState) => state.form.importantDisclosure
  );
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const isProcesssing = useSelector(
    (state: IRootState) => state.media.isProcessing
  );
  const isFormProcessing = useSelector(
    (state: IRootState) => state.form.isProcessing
  );

  const { hash } = useParams<Record<string, string | undefined>>();
  const profileId = useSelector((state: IRootState) => state.form.profileId);
  let { path, url } = useRouteMatch();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(loadFormThunk(hash));
  }, []);

  useEffect(() => {
    if (!caseToken || Object.values(caseToken).length <= 0) return;
    if (!hash) return;
    dispatch(verifyTokenWithProfile(url, caseToken, hash, t));
    dispatch(getAllPrevMediaThunk(caseToken, url, t));
  }, [caseToken]);


  return (
    <Switch>
      {
        //loading profile and previous media
        (!pathname.includes("complete") && (!profileId || isProcesssing)) && <Loading />
      }
      <Route
        path={`${path}`}
        exact={true}
        render={() => <Region page={1} path={url} />}
      />
      <Route
        path={`${path}/tnc`}
        exact={true}
        render={() => <TNC page={2} path={url} />}
      />
      {/* <Route
        path={`${path}/otp`}
        exact={true}
        render={() => <OTP page={3} path={url} />}
      /> */}
      <Route
        path={`${path}/otp`}
        render={() => <OtpRoute page={3} path={url} />}
      />
      <Route
        path={`${path}/important_disclosures`}
        render={() => (
          <ImportantDisclosureRoute
            page={4}
            path={url}
            importantDiscloure={importantDisclosure}
          />
        )}
      />
      <Route
        path={`${path}/idCard`}
        exact={true}
        render={() => <IDCard page={5} path={url} />}
      />
      <Route
        path={`${path}/form`}
        render={() => <FormRoute page={6} forms={forms} path={url} />}
      />
      <Route
        path={`${path}/video`}
        exact={true}
        render={() => <Video page={7} path={url} />}
      />
      {/* <Route
        path={`${path}/face`}
        exact={true}
        render={() => <Face page={8} path={url} />}
      /> */}
      <Route
        path={`${path}/face`}
        render={() => <FaceRoute page={8} path={url} />}
      />
      <Route
        path={`${path}/qa`}
        render={() => <QARoute page={9} forms={qa} path={url} />}
      />
      <Route
        path={`${path}/preview`}
        exact={true}
        render={() => <Preview page={10} path={url} />}
      />
      <Route path={`${path}/complete`} exact={true} component={Complete} />
      <Route
        path={`${path}/maintenance`}
        exact={true}
        component={Maintenance}
      />
      <Route path="*" render={() => <NotFound path={url} />} />
    </Switch>
  );
}

export default Routes;
