export function watchAllMediaSuccess() {
  return {
    type: "@@important_disclosure/WATCHED_ALL_MEDIA" as "@@important_disclosure/WATCHED_ALL_MEDIA",
    watchedAllMedia: true,
  };
}

export function watchAllMediaFailed() {
  return {
    type: "@@important_disclosure/WATCHED_ALL_MEDIA_FAILED" as "@@important_disclosure/WATCHED_ALL_MEDIA_FAILED",
    watchedAllMedia: false,
  };
}

export function readAllTextSuccess() {
  return {
    type: "@@important_disclosure/READ_ALL_TEXT" as "@@important_disclosure/READ_ALL_TEXT",
    readAllText: true,
  };
}

export function readAllTextFailed() {
  return {
    type: "@@important_disclosure/READ_ALL_TEXT_FAILED" as "@@important_disclosure/READ_ALL_TEXT_FAILED",
    readAllText: false,
  };
}

export function updateAgreeementSuccess() {
  return {
    type: "@@important_disclosure/UPDATE_AGREEMENT_SUCCESS" as "@@important_disclosure/UPDATE_AGREEMENT_SUCCESS",
    isSuccess: true,
    isProcessing: false,
  };
}

export function updateAgreeementProcessing() {
  return {
    type: "@@important_disclosure/UPDATE_AGREEMENT_PROCESSING" as "@@important_disclosure/UPDATE_AGREEMENT_PROCESSING",
    isProcessing: true,
  };
}

export function updateAgreementFailed(message: string) {
  return {
    type: "@@important_disclosure/UPDATE_AGREEMENT_FAILURE" as "@@important_disclosure/UPDATE_AGREEMENT_FAILURE",
    isProcessing: true,
    isSuccess: false,
    errorMessage: message,
  };
}

export function resetImportantDisclosure() {
  return {
    type: "@@important_disclosure/reset" as "@@important_disclosure/RESET",
  };
}

type ImportantDisclosureActionCreators =
  | typeof watchAllMediaSuccess
  | typeof readAllTextSuccess
  | typeof resetImportantDisclosure
  | typeof readAllTextFailed
  | typeof updateAgreeementSuccess
  | typeof updateAgreementFailed
  | typeof updateAgreeementProcessing;

export type IImportantDisclosureActions =
  ReturnType<ImportantDisclosureActionCreators>;
