import { Dispatch } from "react";
import { config } from "../../config";
import { ThunkDispatch } from "../../store";
import {
  loadCaseProcessing,
  loadCaseSuccess,
  sendCaseProcessing,
  sendCaseSuccess,
  sendCaseFailure,
  IFormActions,
  loadCaseFailure,
  loadFormProcessing,
  loadFormSuccess,
  loadFormFailure,
  submitFormProcessing,
  submitFormSuccess,
  submitFormFailure,
} from "./actions";
import { Case } from "../../types";
import { toast } from "react-toastify";
import { labels } from "../../utils/formConfig";
import "../../css/index.css";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { clearToken, IOTPActions, resetOtpToken } from "../otp/actions";
import * as dotenv from "dotenv";
import { TFunction } from "react-i18next";

dotenv.config();

export function formSubmitThunk(
  caseToken: string,
  caseData: Partial<Case>,
  currentPage: number,
  path: string,
  t: TFunction<"translation">
) {
  return async (
    dispatch: Dispatch<IFormActions | CallHistoryMethodAction | IOTPActions>
  ) => {
    try {
      dispatch(sendCaseProcessing());
      const body = JSON.stringify({ ...caseData, current_page: currentPage });
      const encrpted = encrytion(body);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/case/${caseToken}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ value: encrpted }),
        }
      );
      const data = await res.json();
      if (res.status == 200) {
        dispatch(
          sendCaseSuccess({ ...data.form, additional: data.form.additional })
        );
      } else if (res.status != 200) {
        dispatch(sendCaseFailure("Internal Server Error"));
        toast.error(t(data.message));
        if (data.isExpired) {
          localStorage.clear();
          dispatch(clearToken());
          dispatch(push(`${path}`));
        }
      }
    } catch (err) {
      dispatch(sendCaseFailure("Server Connection Problem"));
    }
  };
}

export function loadFormThunk(hash: string | undefined = undefined) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(loadFormProcessing());
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/form/${hash ? hash : ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      if (data.importantDisclosure) {
        const idText = data.importantDisclosure.filter(
          (value: any) =>
            value.feature_key === "id_text" || value.feature_key === "content"
        );
        const idMedia = data.importantDisclosure.filter(
          (value: any) =>
            value.feature_key === "id_video" || value.feature_key === "id_audio"
        );

        if (idText) data["idText"] = idText;
        if (idMedia) data["idMedia"] = idMedia;
      }

      if (res.status == 200) {
        dispatch(loadFormSuccess(data));
      } else if (res.status != 200) {
        dispatch(loadFormFailure());
      }
    } catch (err) {
      dispatch(loadFormFailure());
    }
  };
}

export function loadCaseThunk(caseToken: string) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(loadCaseProcessing());
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/case/${caseToken}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      if (res.status == 200) {
        dispatch(loadCaseSuccess(data));
      } else if (res.status != 200) {
        dispatch(loadCaseFailure());
        toast.error("cannot connect to server.");
      }
    } catch (err) {
      dispatch(loadCaseFailure());
    }
  };
}

export function formfinalConfirmThunk(hash: string | undefined = undefined, caseToken: string, path: string, t:TFunction<"translation">) {
  return async (
    dispatch: Dispatch<IFormActions | CallHistoryMethodAction | IOTPActions>
  ) => {
    try {
      dispatch(submitFormProcessing());
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/case/${caseToken}/submit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ current_page: "Submitted", hash: hash }),
        }
      );
      const data = await res.json();
      if (res.status == 200) {
        dispatch(submitFormSuccess());
        dispatch(resetOtpToken());
      } else if (res.status != 200) {
        dispatch(submitFormFailure());
        if (data.message == "failed") {
          if (data.isExpired) {
            localStorage.clear();
            dispatch(clearToken());
            dispatch(push(`${path}`));
          }
          if (data.missingFields.length > 0) {
            // let missingFieldsText = "";
            //Show all missing fields at once
            // missingFieldsText = data?.missingFields.map(
            //   (field: keyof typeof labels) => t(`preview_${field}`)
            // );
            //Show only the first missing field
            toast.error(`${t(`preview_${data?.missingFields?.[0]}`)} ${t('preview_submit_error_missing')}`)
            // toast.error(`${missingFieldsText} is missing`);
          } else {
            toast.error(t(data.message));
          }
        }
      }
    } catch (err) {
      dispatch(submitFormFailure());
    }
  };
}

const encrytion = (message: any) => {
  return Buffer.from(message).toString("base64");
};
