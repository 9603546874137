import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslate from "./translations/en/common.json";
import cnTranslate from "./translations/cn/common.json";
import hkTranslate from "./translations/hk/common.json";
// import zhTranslate from "./translations/zh/common.json";
import { store } from "./store";

const resources = {
  en: { translation: enTranslate },
  cn: { translation: cnTranslate },
  zh: { translation: hkTranslate },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: store.getState()["setting"]["language"],
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
