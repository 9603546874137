import { IFormState } from "./state";
import { IFormActions } from "./actions";

export const initFormState: IFormState = {
  isProcessing: false,
  isSuccess: false,
  form: null,
  qa: null,
  icon: null,
  importantDisclosure: null,
  idText: null,
  idMedia: null,
  caseData: {},
  errorMessage: null,
  currentPage: null,
  formTotalPage: null,
  qaTotalPage: null,
  isSubmitted: null,
  profileId: null,
  merchantId: null,
  merchantName: null,
  contentPageOfOTP: null,
  contentPageOfBiometric: null,
};

export const formReducers = (
  state: IFormState = initFormState,
  action: IFormActions
): IFormState => {
  switch (action.type) {
    case "@@form/LOAD_CASE_PROCESSING":
      return {
        ...state,
        isProcessing: action.isProcessing,
      };
    case "@@form/LOAD_CASE_SUCCESS":
      return {
        ...state,
        caseData: action.caseData,
        isProcessing: action.isProcessing,
      };
    case "@@form/LOAD_CASE_FAILURE":
      return {
        ...state,
        isProcessing: action.isProcessing,
        isSuccess: action.isSuccess,
      };
    case "@@form/SEND_CASE_PROCESSING":
      return {
        ...state,
        // isSuccess: action.isSuccess,
        isProcessing: action.isProcessing,
      };
    case "@@form/SEND_CASE_SUCCESS":
      return {
        ...state,
        caseData: action.caseData,
        isSuccess: action.isSuccess,
        isProcessing: action.isProcessing,
      };
    case "@@form/SEND_CASE_FAILURE":
      return {
        ...state,
        isSuccess: action.isSuccess,
        isProcessing: action.isProcessing,
        errorMessage: action.errorMessage,
      };
    case "@@form/LOAD_FORM_PROCESSING":
      return {
        ...state,
        isProcessing: action.isProcessing,
      };
    case "@@form/LOAD_FORM_SUCCESS":
      return {
        ...state,
        form: action.form,
        qa: action.qa,
        isProcessing: action.isProcessing,
        formTotalPage: action.formTotalPage,
        qaTotalPage: action.qaTotalPage,
        profileId: action.profileId,
        merchantId: action.merchantId,
        merchantName: action.merchantName,
        icon: action.icon,
        importantDisclosure: action.importantDisclosure,
        idText: action.idText,
        idMedia: action.idMedia,
        contentPageOfOTP: action.contentPageOfOTP,
        contentPageOfBiometric: action.contentPageOfBiometric,
      };
    case "@@form/LOAD_FORM_FAILURE":
      return {
        ...state,
        isProcessing: action.isProcessing,
        isSuccess: action.isSuccess,
      };
    case "@@form/SUBMIT_FORM_SUCCESS":
      return {
        ...state,
        isProcessing: action.isProcessing,
        isSubmitted: action.isSubmitted,
      };
    case "@@form/SUBMIT_FORM_FAILURE":
      return {
        ...state,
        isProcessing: action.isProcessing,
        isSuccess: action.isSubmitted,
      };
    case "@@form/SUBMIT_FORM_PROCESSING":
      return {
        ...state,
        isProcessing: action.isProcessing,
      };
    case "@@form/RESET":
      return {
        ...state,
        // form: initFormState.form,
        // isSuccess: initFormState.isSuccess,
        isSuccess: action.isSuccess,
        isProcessing: action.isProcessing,
        // isProcessing: initFormState.isProcessing,
        // errorMessage: initFormState.errorMessage
      };
    default:
      return state;
  }
};
