import React, { useEffect, useState } from "react";
import { Question } from "../../types";
import styles from "../../css/form.module.css";
import { useSelector } from "react-redux";
import { IRootState } from "../../store";

interface Props {
  question: Question;
  index: number;
}

export const Header: React.FC<Props> = ({ question, index }) => {
  const language = useSelector((state: IRootState) => state.setting.language);
  return (
    <>
      <div className="titleBox" key={index}>
        {question.content?.[language] && (
          <div
            className={`title  
            ${question.bold ? "bold" : ""}
            ${question.italic ? "italic" : ""}
          `}
            dangerouslySetInnerHTML={{ __html: question.content?.[language] }}
          />
        )}
      </div>
    </>
  );
};
