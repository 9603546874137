import React, { useState } from "react";
import styles from "./RadioButton.module.css";

interface Props {
  options: Option[];
  onValueChange: (value: any) => void;
}

interface Option {
  label: string;
  value: string | number;
}

export const RadioButton: React.FC<Props> = ({ options, onValueChange }) => {
  const [checked, setChecked] = useState<string | number | null>("");
  const onClick = (value: string | number) => () => {
    setChecked(value);
    onValueChange(value);
  };
  return (
    <>
      <div className={styles.options}>
        {options.map((option, index) => (
          <div
            onClick={onClick(option.value)}
            key={index}
            className={styles.button}
          >
            <div
              className={
                checked === option.value
                  ? styles.checkedOption
                  : styles.uncheckedOption
              }
            >
              <div className={styles.optionBox}>
                <div
                  className={
                    checked === option.value
                      ? styles.checkedOptionBall
                      : styles.optionBall
                  }
                ></div>
              </div>
              <div className={styles.optionTextBox}>
                <div className={styles.optionText}>{option.label}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
