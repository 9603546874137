import { IQAState } from './state';
import { IQAActions } from './actions';

export const initQAState: IQAState = {
    isProcessing: false,
    isSuccess: false,
    qaform: null,
    qaData: {},
    errorMessage: null, 
    currentPage: null,
    totalPage: null
}

export const qaReducers = (state: IQAState = initQAState, action: IQAActions):IQAState => {
    switch(action.type){
        case "@@qa/LOAD_QA_PROCESSING":
            return{
                ...state,
                isProcessing: action.isProcessing,
            }
        case "@@qa/LOAD_QA_SUCCESS":
            return{
                ...state,
                qaData: action.qaData,
                isProcessing: action.isProcessing,
            }
        case "@@qa/LOAD_QA_FAILURE":
            return{
                ...state,
                isProcessing:action.isProcessing,
                isSuccess:action.isSuccess
            }
        case "@@qa/SEND_QA_PROCESSING":
            return{
                ...state,
                // isSuccess: action.isSuccess,
                isProcessing: action.isProcessing,
            }
        case "@@qa/SEND_QA_SUCCESS":
            return{
                ...state,
                qaData: action.qaData,
                isSuccess: action.isSuccess,
                isProcessing: action.isProcessing,
            }
        case "@@qa/SEND_QA_FAILURE":
            return{
                ...state,
                isSuccess: action.isSuccess,
                isProcessing: action.isProcessing,
                errorMessage: action.errorMessage
            }
        // case "@@qa/LOAD_QAFORM_PROCESSING":
        //     return{
        //         ...state,
        //         isProcessing: action.isProcessing,
        //     }
        // case "@@qa/LOAD_QAFORM_SUCCESS":
        //     return{
        //         ...state,
        //         qaform: action.form,
        //         isProcessing: action.isProcessing,
        //         totalPage: action.totalPage
        //     }
        // case "@@qa/LOAD_QAFORM_FAILURE":
        //     return{
        //         ...state,
        //         isProcessing:action.isProcessing,
        //         isSuccess:action.isSuccess
        //     }
        case "@@qa/RESET":
            return{
                ...state,
                // form: initFormState.form,
                isSuccess: initQAState.isSuccess,
                isProcessing: initQAState.isProcessing,
                errorMessage: initQAState.errorMessage
            }
        default:
            return state
    }
} 
