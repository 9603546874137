export function sendFaceProcessing(){
    return {
        type: "@@bio/SEND_FACE_PROCESSING" as "@@bio/SEND_FACE_PROCESSING",
        isProcessing: true,
        isSuccess:false,
    }  
}

export function verifyFace(isVerify:boolean, face:File | null){
    return {
        type: "@@bio/VERIFY_FACE_SUCCESS" as "@@bio/VERIFY_FACE_SUCCESS",
        isProcessing: false,
        isFaceVerified : isVerify,
        face: face
    }  
}

export function sendFaceSuccess(){
    return {
        type: "@@bio/SEND_FACE_SUCCESS" as "@@bio/SEND_FACE_SUCCESS",
        isProcessing: false,
        isSuccess: true
    }  
}

export function sendFaceFailure(errorMessage:string){
    return {
        type:"@@bio/SEND_FACE_FAILURE" as "@@bio/SEND_FACE_FAILURE",
        isProcessing: false,
        isSuccess: false,
        errorMessage: errorMessage
    }
}

export function resetFaceVerify(){
    return {
        type: "@@bio/RESET_FACE_VERIFY" as "@@bio/RESET_FACE_VERIFY",
        isProcessing: false,
        isFaceVerify : null,
    }  
}

// export function verifyFacePitchHead(isVerify:boolean){
//     return {
//         type: "@@bio/VERIFY_FACE_PITCHHEAD_SUCCESS" as "@@bio/VERIFY_FACE_PITCHHEAD_SUCCESS",
//         isProcessing: false,
//         isPitchHeadVerified : isVerify,
//     }  
// }

// export function resetFacePitchHeadVerify(){
//     return {
//         type: "@@bio/RESET_FACE_PITCHHEAD_VERIFY" as "@@bio/RESET_FACE_PITCHHEAD_VERIFY",
//         isProcessing: false,
//         isPitchHeadVerified : undefined,
//     }  
// }

// export function verifyFaceCloseEye(isVerify:boolean){
//     return {
//         type:"@@bio/VERIFY_FACE_CLOSEEYE_SUCCESS" as "@@bio/VERIFY_FACE_CLOSEEYE_SUCCESS",
//         isProcessing: false,
//         isCloseEyeVerified : isVerify,
//     }
// }


// export function resetFaceCloseEyeVerify(){
//     return {
//         type: "@@bio/SEND_FACE_CLOSEEYE_FAILURE" as "@@bio/SEND_FACE_CLOSEEYE_FAILURE",
//         isProcessing: false,
//         isCloseEyeVerified : undefined,
//     }  
// }

export function verifyFaceDynamic(isVerify:boolean, dynamicFace:File | null){
    return {
        type:"@@bio/VERIFY_FACE_DYNAMIC_SUCCESS" as "@@bio/VERIFY_FACE_DYNAMIC_SUCCESS",
        isProcessing: false,
        isDynamicFaceVerified : isVerify,
        dynamicFace: dynamicFace
    }
}

export function resetFaceDynamicVerify(){
    return {
        type: "@@bio/RESET_FACE_DYNAMIC_VERIFY" as "@@bio/RESET_FACE_DYNAMIC_VERIFY",
        isProcessing: false,
        isDynamicFaceVerified : null,
    }  
}

export function resetBio(){
    return {
        type: "@@bio/RESET" as "@@bio/RESET",
    }
}

type BioActionCreators = 
    typeof sendFaceProcessing | 
    typeof verifyFace | 
    typeof sendFaceFailure | 
    typeof resetFaceVerify |
    typeof sendFaceSuccess |
    typeof verifyFaceDynamic |
    typeof resetFaceDynamicVerify |
    typeof resetBio 
    // typeof verifyFacePitchHead | 
    // typeof verifyFaceCloseEye|
    // typeof resetFacePitchHeadVerify |
    // typeof resetFaceCloseEyeVerify |

export type IBioActions = ReturnType<BioActionCreators>
