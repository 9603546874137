import React from 'react';
import { Navbar } from '../components/navbar';
import { PageTitle } from '../components/PageTitle';
import '../css/index.css'
import { useTranslation } from 'react-i18next';
import { faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Maintenance: React.FC = () =>{
    const { t } = useTranslation();
    return (
        <>
            <Navbar canGoBack={true}/>
            <div className="container">
                <PageTitle title={''} page={10}/>
                <div className="completeContainer">
                    <div className="isNotVerified">
                        <FontAwesomeIcon size={"3x"} icon={faTools} color="white"></FontAwesomeIcon>
                    </div>
                    <div className="title">{t("maintenance_title")}</div>
                    <div className="contentText">{t("maintenance_content")}</div>
                </div>
                <button 
                    className="button"
                    onClick={()=>{
                        return
                        // dispatch(push('/region'));
                    }}> 
                    <div className="buttonText">{t("OK")}</div>    
                </button>
            </div>
        </>
    )
}
