import { IPrevMediaState } from './state';
import { IPrevMediaActions } from './actions';

export const initPrevMediaState: IPrevMediaState = {
    isProcessing: false,
    isSuccess: false,
    errorMessage: null,
    prevIdFront: null,
    prevIdBack: null,
    prevVideo: null,
    prevFace: null,
    prevDynamicFace: null
}

export const mediaReducers = (state: IPrevMediaState = initPrevMediaState, action: IPrevMediaActions):IPrevMediaState => {
    switch(action.type){
        case "@@media/LOAD_PREV_MEDIA_PROCESSING":
            return{
                ...state,
                isProcessing: action.isProcessing,
            }
        case "@@media/LOAD_PREV_MEDIA_SUCCESS":
            return{
                ...state,
                prevIdFront: action.prevIdFront,
                prevIdBack: action.prevIdBack,
                prevVideo: action.prevVideo,
                prevFace: action.prevFace,
                prevDynamicFace: action.prevDynamicFace ,
                isProcessing: action.isProcessing,
            }
        case "@@media/LOAD_PREV_MEDIA_FAILURE":
            return{
                ...state,
                isProcessing:action.isProcessing,
                isSuccess:action.isSuccess
            }
        case "@@media/RESET_IDCARD":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isSuccess: action.isSuccess,
                prevIdFront: action.prevIdFront,
                prevIdBack: action.prevIdBack
            }
        case "@@media/RESET_VIDEO":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isSuccess: action.isSuccess,
                prevVideo: action.prevVideo
            }
        case "@@media/RESET_FACE":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isSuccess: action.isSuccess,
                prevFace: action.prevFace
            }
        case "@@media/RESET_DYNAMIC_FACE":
            return{
                ...state,
                isProcessing: action.isProcessing,
                isSuccess: action.isSuccess,
                prevDynamicFace: action.prevDynamicFace
            }
        default:
            return state
    }
} 
