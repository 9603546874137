export const getIdCardInfo = (data: any, idCountry: string) => {
  if (idCountry !== "cn") idCountry = "hk";
  switch (idCountry) {
    case "hk": {
      const [birth_day, birth_month, birth_year] =
        data?.dateOfBirth?.trim().length === 10
          ? data?.dateOfBirth?.split("-")
          : [];
      return {
        last_name: data?.surname?.trim(),
        first_name: data?.givenName?.trim(),
        other_name: data?.chineseName?.trim(),
        other_name_language: "English",
        birth_year: birth_year,
        birth_month: birth_month,
        birth_day: birth_day,
        identity_number: data?.idNo?.trim()?.replace(/[^a-zA-Z 0-9]+/g, ""),
        gender: data?.sex?.trim() === "M" ? "M" : "F",
      };
    }

    case "cn": {
      const birthday = data?.dateOfBirth?.trim();
      const [birth_year, birth_month, birth_day] =
        birthday.length === 8
          ? [
              birthday.substring(0, 4),
              birthday.substring(4, 6),
              birthday.substring(6, 8),
            ]
          : [];
      const address = data?.address?.replace(/\s+/g, "");
      let states: string | undefined = undefined;
      let city: string | undefined = undefined;
      if (address) {
        if (
          address.indexOf("省") > -1 &&
          (address.indexOf("市") > -1 || address.indexOf("县") > -1)
        ) {
          states = address.substring(0, address.indexOf("省") + 1);
          city =
            address.indexOf("市") > -1
              ? address.substring(
                  address.indexOf("省") + 1,
                  address.indexOf("市") + 1
                )
              : address.substring(
                  address.indexOf("省") + 1,
                  address.indexOf("县") + 1
                );
        } else if (address.indexOf("省") > -1) {
          states = address.substring(0, address.indexOf("省") + 1);
        } else if (address.indexOf("市") > -1) {
          city = address.substring(0, address.indexOf("市") + 1);
        } else if (address.indexOf("县") > -1) {
          city = address.substring(0, address.indexOf("县") + 1);
        }
      }

      return {
        other_name: data?.chineseName?.trim(),
        birth_year: birth_year,
        birth_month: birth_month,
        birth_day: birth_day,
        identity_number: data?.idNo?.trim(),
        gender: data?.sex?.trim(),
        living_address_line_1: city,
        living_address_line_2: states,
        living_address_line_3: "中国",
        nationality: data?.nationality,
        full_address: data?.address?.replace(/\s+/g, ""),
      };
    }
    default:
      return {
        last_name: undefined,
        first_name: undefined,
        other_name: undefined,
        birth_year: undefined,
        birth_month: undefined,
        birth_day: undefined,
        identity_number: undefined,
        gender: undefined,
        nationality: undefined,
        full_address: undefined,
      };
  }
};
