import React, { useEffect, useState } from "react";
import { Question } from "../../types";
import styles from "../../css/form.module.css";
import {
  UseFormRegister,
  FieldValues,
  UseFormUnregister,
  UseFormTrigger,
  UseFormSetValue,
  DeepMap,
  FieldError,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { IRootState } from "../../store";
import { useTranslation } from "react-i18next";

interface Props {
  question: Question;
  index: number;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  component?: boolean;
}

export const TextInput: React.FC<Props> = ({
  question,
  index,
  register,
  errors,
  component = false,
}) => {
  const language = useSelector((state: IRootState) => state.setting.language);
  const locale = useSelector((state: IRootState) => state.setting.locale);
  const { t } = useTranslation();

  return (
    <>
      {!question.hidden && !(question.id === "full_address" && locale != "cn") && (
        <>
          {question.label?.[language] && (
            <label className={styles.formLabel}>
              <div
                style={{display: 'inline'}}
                key={`${question.id}_label_${index}`}
                dangerouslySetInnerHTML={{ __html: question.label?.[language] + (question.required ? "*" : "")}}
              />
            </label>
          )}
          <input
            key={`${question.id}_input_${index}`}
            className={styles.formInput}
            {...register(question.id, {
              required: question?.required,
              maxLength: question?.maxLength,
              minLength: question?.minLength,
              pattern:
                question.email || question.id == "email"
                  ? new RegExp(
                      '(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))'
                    )
                  : undefined,
            })}
          ></input>
          {component &&
            errors?.[question.id] &&
            errors?.[question.id].type == "required" && (
              <div key={"text_error"} className={styles.errorText}>
                {t("warning__required")}
              </div>
            )}
          {component &&
            errors?.[question.id] &&
            errors?.[question.id].type == "pattern" && (
              <div key={"text_error"} className={styles.errorText}>
                {t("warning__invalid__format")}
              </div>
            )}
          {component &&
            errors?.[question.id] &&
            errors?.[question.id].type == "maxLength" && (
              <div key={"text_error"} className={styles.errorText}>
                {t("warning__need__maximum")} {question.maxLength}{" "}
                {t("warning__characters")}
              </div>
            )}
          {component &&
            errors?.[question.id] &&
            errors?.[question.id].type == "minLength" && (
              <div key={"text_error"} className={styles.errorText}>
                {t("warning__need__minimum")} {question.minLength}{" "}
                {t("warning__characters")}
              </div>
            )}
          {question.description && (
            <div
              key={`${question.id}_description_${index}`}
              className={styles.formDes}
            >
              {question.description?.[language]
                ? question.description?.[language]
                : question.description}
            </div>
          )}
        </>
      )}
    </>
  );
};
