import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../store';
import { updateLanguageSuccess } from '../redux/language/actions';
import { useTranslation } from 'react-i18next';

interface Props {
    options:Option[]
    className?: string
}

interface Option {
    label: string,
    value: string | number
}

export const LanguagePicker:React.FC<Props> = ({options, className})=>{
    const language = useSelector((state: IRootState)=>state.setting.language);
    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    
    useEffect(()=>{
        i18n.changeLanguage(language);
    },[language])
    
    return (
        <select className={className} value={language} onChange={e => dispatch(updateLanguageSuccess(e.target.value))}> 
            {options.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
            ))}
        </select>
    )
}