import "./App.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { store, history, persistor, IRootState } from "./store";
import { TNC } from "./pages/tnc";
import { Region } from "./pages/region";
import { OTP } from "./pages/otp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IDCard } from "./pages/idCard";
import { Video } from "./pages/video";
import { NotFound } from "./pages/notFound";
import { Preview } from "./pages/preview";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async'

//multiple language
import "./i18n";
import { useTranslation } from "react-i18next";
import { FormRoute } from "./pages/formRoute";
import { Face } from "./pages/face";
import { Complete } from "./pages/complete";
import { Maintenance } from "./pages/maintainance";
import { useEffect } from "react";
// import { loadFormThunk } from './redux/form/thunk';
import { useParams } from "react-router-dom";
import Routes from "./routes";
import Favicon from "react-favicon";
import logo from "./assets/img/icon.png";
import ReactivateRoutes from "./pages/reactivate/reactivateRoutes";

function App() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const forms = useSelector((state: IRootState) => state.form.form);
  const icon = useSelector((state: IRootState) => state.form.icon);
  const merchantName = useSelector((state: IRootState) => state.form.merchantName);

  return (
    <HelmetProvider>
      <div id="root">
        <div className="form">
          <Favicon url={icon ? icon : ""} />
          <Helmet>
            <title>{merchantName || 'ekyc'}</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Helmet>

          <Switch>
            <Route path="/reactivate/:hash">
              <ReactivateRoutes />
            </Route>
            <Route path="/:hash">
              <Routes />
            </Route>
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        ></ToastContainer>
      </div>
    </HelmetProvider>
  );
}

export default App;
