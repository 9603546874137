import React, { useEffect, useState } from "react";
import {
  UseFormRegister,
  FieldValues,
  UseFormUnregister,
  UseFormSetValue,
  UseFormWatch,
  UseFormSetError,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { IRootState } from "../../store";
import { Question } from "../../types";
import styles from "../../css/form.module.css";

interface Props {
  question: Question;
  index?: number;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setError?: UseFormSetError<FieldValues>;
}

interface Option {
  label: string;
  text: { en: string; zh: string; cn: string };
  value: string | number;
}

export const RadioButtons: React.FC<Props> = ({
  register,
  question,
  setValue,
  index,
}) => {
  const [checked, setChecked] = useState<string | number | null>("");
  const language = useSelector((state: IRootState) => state.setting.language);
  const qaData = useSelector((state: IRootState) => state.qa.qaData);

  const onClick = (value: string | number) => () => {
    setChecked(value);
    setValue(question.id, value);
  };

  useEffect(() => {
    if (qaData?.[question.id] && qaData?.[question.id]?.value) {
      setChecked(qaData?.[question.id]?.value);
    }
  }, [qaData]);

  return (
    <>
      {question.label?.[language] && (
        <label className={styles.formLabel}>
          <div
            key={`${question.id}_label_${index}`}
            dangerouslySetInnerHTML={{ __html: question.label?.[language] + (question.required ? "*" : "")}}
          />
        </label>
      )}
      <div
        className={styles.options}
        style={question.inline ? { display: "flex" } : {}}
      >
        {question.options.map((option, index) => (
          <div
            onClick={onClick(option.value)}
            key={index}
            style={{ minWidth: "30vw" }}
            className={styles.button}
          >
            <div
              className={
                checked === option.value
                  ? styles.checkedOption
                  : styles.uncheckedOption
              }
            >
              <div>
                <div className={styles.optionBox}>
                  <div
                    className={
                      checked === option.value
                        ? styles.checkedOptionBall
                        : styles.optionBall
                    }
                  ></div>
                </div>
              </div>
              <div className={styles.optionTextBox}>
                <div className={styles.optionText}>
                  {option.text?.[language]}
                </div>
              </div>
            </div>
          </div>
        ))}
        <input
          key={`${question.id}`}
          type={"hidden"}
          {...register(question.id, {
            required: question?.required,
            maxLength: question?.maxLength,
            minLength: question?.minLength,
            pattern: new RegExp(question?.pattern),
          })}
        ></input>
      </div>
    </>
  );
};
