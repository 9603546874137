import React from 'react';
import { faTimesCircle, faCheck, faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import '../css/index.css';
import { useTranslation } from 'react-i18next';

interface Content {
    title: string,
    contents?: {
        icon: IconDefinition,
        text: string
    }[],
    image?: string
}

interface Props {
    title?: string,
    contents?: Content[],
    buttonText: string
    isOpen: boolean
    setisOpen: (v: boolean) => void
}

Modal.setAppElement('#root')

export function UploadModal({ title, contents, buttonText, isOpen, setisOpen }: Props) {
    const { t } = useTranslation();
    function openModal() {
        setisOpen(true);
    }

    function closeModal() {
        setisOpen(false)
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className="modal"
                overlayClassName="overlay"
                contentLabel="Example Modal"
            >
                <FontAwesomeIcon icon={faTimesCircle} color="#FF5258" className="logoIcon" />
                <h2 className="modal_title">{title}</h2>
                <div className="modal_content">
                    { contents && contents.map((item, index)=>(
                          <React.Fragment key={index}>
                                <div className='modal_subtitle'>{item.title}</div>
                                {item.contents?.map((section, index)=>(
                                    <div key={index} className='modal_text'>
                                        <FontAwesomeIcon icon={section.icon} className="modal_icon" />
                                        {section.text}
                                    </div>)
                                )}
                                {item.image && <img className="modal_img" src={item.image} />
                    }
                        </React.Fragment>)
                    )}                    
                </div>
                <button className="button" onClick={closeModal}>
                    <div className="buttonText">{buttonText}</div>
                </button>
            </Modal>
        </div>
    );
}