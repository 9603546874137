import { IImportantDisclosureState } from "./state";
import { IImportantDisclosureActions } from "./acitons";

export const initImportantDisclosureState: IImportantDisclosureState = {
  isProcessing: false,
  isSuccess: false,
  readAllText: false,
  watchedAllMedia: false,
  errorMessage: null,
};

export const ImportantDisclosureReducers = (
  state: IImportantDisclosureState = initImportantDisclosureState,
  action: IImportantDisclosureActions
): IImportantDisclosureState => {
  switch (action.type) {
    case "@@important_disclosure/READ_ALL_TEXT":
      return {
        ...state,
        readAllText: action.readAllText,
      };
    case "@@important_disclosure/WATCHED_ALL_MEDIA":
      return {
        ...state,
        watchedAllMedia: action.watchedAllMedia,
      };
    case "@@important_disclosure/READ_ALL_TEXT_FAILED":
      return {
        ...state,
        readAllText: action.readAllText,
      };
    case "@@important_disclosure/UPDATE_AGREEMENT_SUCCESS":
      return {
        ...state,
        isProcessing: action.isProcessing,
        isSuccess: action.isSuccess,
      };
    case "@@important_disclosure/UPDATE_AGREEMENT_PROCESSING":
      return {
        ...state,
        isProcessing: action.isProcessing,
      };
    case "@@important_disclosure/UPDATE_AGREEMENT_FAILURE":
      return {
        ...state,
        isProcessing: action.isProcessing,
        isSuccess: action.isSuccess,
        errorMessage: action.errorMessage,
      };
    case "@@important_disclosure/RESET":
      return {
        ...state,
        readAllText: false,
        watchedAllMedia: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};
