import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RadioButton } from "../components/form/RadioButton";
import { Navbar } from "../components/navbar";
import { push } from "connected-react-router";
import LoadingOverlay from "../components/LoadingOverlay";
import { IRootState } from "../store";
import { useTranslation } from "react-i18next";
import { updateLanguageSuccess } from '../redux/language/actions';
import {
  updateLocaleFinish,
  updateLocaleSuccess,
} from "../redux/language/actions";
import { useParams, useRouteMatch } from "react-router-dom";
import { PageTitle } from "../components/PageTitle";
// import { Helmet } from 'react-helmet';
import { useQuery } from "../utils/Utils";

type Location = "hk" | "mc" | "cn" | "my" |"sg"| "other";

interface Props {
  page: number;
  path: string;
}

export const Region: React.FC<Props> = ({ page, path }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isProcessing = useSelector(
    (state: IRootState) => state.form.isProcessing
  );
  const isSuccess = useSelector((state: IRootState) => state.setting.isSuccess);
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const locale = useSelector((state: IRootState) => state.setting.locale);
  const [location, setLocation] = useState<Location>();

  let query = useQuery();

  const onsubmit = () => {
    if (!location) return;
    dispatch(updateLocaleSuccess(location));
    if(location=='cn'){
      dispatch(updateLanguageSuccess('cn'));
    }
    if(location=='my'||location=='sg'||location=='other'){
      dispatch(updateLanguageSuccess('en'));
    }
  };

  // useEffect(() => {
  //   if (!caseToken || Object.values(caseToken).length <= 0) return;
  //   dispatch(push(`${path}/idCard`));
  // }, [caseToken]);

  useEffect(() => {
    if (!isSuccess) return;
    let refString = query.get("ref");
    dispatch(updateLocaleFinish());
    if (refString) {
      dispatch(push(`${path}/tnc?ref=${refString}`));
    } else {
      dispatch(push(`${path}/tnc`));
    }
  }, [isSuccess]);

  return (
    <>
      <Navbar canGoBack={false} />
      <div className="container">
        <div>
          <PageTitle title={t("doc_id__region")} page={page} />
          <RadioButton
            onValueChange={(value: any) => setLocation(value)}
            options={[
              { label: t("China"), value: "cn" },
              { label: t("Hong_Kong"), value: "hk" },
              { label: t("Macau"), value: "mc" },
              { label: t("Malaysia"), value: "my" },
              { label: t("Singapore"), value: "sg" },
              { label: t("Other"), value: "other" },
            ]}
          />
        </div>
        <button className="button" onClick={onsubmit}>
          <div className="buttonText">{t("agree")}</div>
        </button>
      </div>
      <LoadingOverlay isProcessing={isProcessing} isSuccess={isSuccess} />
    </>
  );
};
