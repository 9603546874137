import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "../css/index.css";
import { IRootState } from "../store";
import { ContentPage } from "./contentPage";
import { NotFound } from "./notFound";
import { OTP } from "./otp";

interface Props {
  page: number;
  path: string;
}

export const OtpRoute: React.FC<Props> = ({ page, path }: Props) => {
  const dispatch = useDispatch();
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);

  const contentPageOfOTP = useSelector(
    (state: IRootState) => state.form.contentPageOfOTP
  );

//   useEffect(() => {
//     if (contentPageOfOTP.length > 0) {
//       dispatch(push(`${path}/otp/0`));
//     }
//   }, []);

  return (
    <>
      <Switch>
        {contentPageOfOTP &&
          contentPageOfOTP.map((contentDetail: any, index: number) => {
            if (contentDetail.feature_key === "content") {
              return (
                <Route
                  key={"otp_content" + index}
                  path={`${path}/otp/${index}`}
                >
                  <ContentPage
                    page={page}
                    contentDetail={contentDetail}
                    title="otp_title"
                    index={index}
                    path={path}
                    subPath="otp"
                    totalPages={contentPageOfOTP.length}
                  />
                </Route>
              );
            } else if (contentDetail.feature_key === "verify") {
              return (
                <Route key={"otp" + index} path={`${path}/otp/${index}`}>
                  <OTP
                    page={page}
                    path={path}
                    index={index}
                    otpPages={contentPageOfOTP.length}
                  />
                </Route>
              );
            }
          })}
        <Route path={`${path}/otp`} component={NotFound}></Route>
      </Switch>
    </>
  );
};
