import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faCamera, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "../css/index.css";
import { useTranslation } from "react-i18next";

interface Props {
  icon: IconProp;
  name: string;
  type: string;
  onPress?: (v: File) => void;
}

export const LogoButton: React.FC<Props> = ({
  icon,
  name,
  type,
  onPress,
}: Props) => {
  const { t } = useTranslation();
  const onClickHandle = () => {
    const imgInput = document.getElementById(`image-input_${icon}_${type}`);
    imgInput && imgInput.click();
  };

  return (
    <div
      style={{
        flex: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
      }}
    >
      <FontAwesomeIcon icon={icon} color="#007EF4" className="logoIcon" />
      <div className="button" onClick={onClickHandle}>
        <div className="buttonText">{t(name)}</div>
      </div>
      <input
        style={{ display: "none" }}
        id={`image-input_${icon}_${type}`}
        type="file"
        accept="image/*"
        capture={
          name === "doc_id_takephoto"
            ? "environment"
            : name === "bio_face__takephoto"
            ? "user"
            : false
        }
        onChange={async (e) => {
          if (!e.target.files) return;
          let file = e.target.files[0];
          onPress!(file);
        }}
      />
    </div>
  );
};
