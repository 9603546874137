import React from 'react';
export const LineBreaker:React.FC = ()=>{
    
    return (
        <div style={{
            borderBottomWidth: "2px",
            borderBottomColor:"#E5E5E5",
            borderBottomStyle:"solid",
            margin:"10px 0"
        }}/>
    )
}