export function sendOTPProcessing() {
  return {
    type: "@@otp/SEND_OTP_PROCESSING" as "@@otp/SEND_OTP_PROCESSING",
    isProcessing: true,
  };
}

export function sendOTPSuccess() {
  return {
    type: "@@otp/SEND_OTP_SUCCESS" as "@@otp/SEND_OTP_SUCCESS",
    isSent: true,
    isProcessing: false,
  };
}

export function sendOTPAnotherNumber() {
  return {
    type: "@@otp/SEND_OTP_ANOTHER_NUMBER" as "@@otp/SEND_OTP_ANOTHER_NUMBER",
    isSent: false,
    isVerified: false,
    isProcessing: false,
  };
}

export function sendOTPFail(errorMessage: string) {
  return {
    type: "@@otp/SEND_OTP_FAIL" as "@@otp/SEND_OTP_FAIL",
    isProcessing: false,
    isVerified: false,
    errorMessage: errorMessage,
  };
}

export function verifyOTPSuccess(
  caseToken: string,
  currentPage: string,
  agreementTime: string | null
) {
  return {
    type: "@@otp/VERIFY_OTP_SUCCESS" as "@@otp/VERIFY_OTP_SUCCESS",
    isVerified: true,
    isProcessing: false,
    caseToken: caseToken,
    currentPage: currentPage,
    agreementTime: agreementTime,
  };
}

export function resetOtpToken() {
  return {
    type: "@@otp/RESET_OTP" as "@@otp/RESET_OTP",
    isProcessing: false,
    isSent: false,
    isVerified: false,
    currentPage: null,
    agreementTime: null,
    caseToken: null,
    errorMessage: null,
  };
}

export function verifyTokenProcessing() {
  return {
    type: "@@otp/VERIFY_TOKEN_PROCESSING" as "@@otp/VERIFY_TOKEN_PROCESSING",
    isProcessing: false,
  };
}

export function verifyTokenSuccess() {
  return {
    type: "@@otp/VERIFY_TOKEN_SUCCESS" as "@@otp/VERIFY_TOKEN_SUCCESS",
    isProcessing: false,
  };
}

export function verifyTokenFail() {
  return {
    type: "@@otp/VERIFY_TOKEN_FAIL" as "@@otp/VERIFY_TOKEN_FAIL",
    isProcessing: false,
  };
}

export function clearToken() {
  return {
    type: "@@otp/CLEAR_TOKEN" as "@@otp/CLEAR_TOKEN",
    isVerified: false,
    isProcessing: false,
    agreementTime: null,
    caseToken: null,
    currentPage: null,
  };
}

// type FAILED = "@@otp/SEND_OTP_FAIL"

// export function failed(type: FAILED, errorMessage: string) {
//     return {
//         type,
//         isProcessing: false,
//         isVerified: false,
//         errorMessage
//     }
// }

export function verifyReactivateOTPSuccess(
  caseToken: string,
  profileHash: string
) {
  return {
    type: "@@otp/VERIFY_REACTIVATE_OTP_SUCCESS" as "@@otp/VERIFY_REACTIVATE_OTP_SUCCESS",
    isVerified: true,
    isProcessing: false,
    caseToken: caseToken,
    profileHash: profileHash,
  };
}

type OTPActionCreators =
  | typeof sendOTPProcessing
  | typeof sendOTPSuccess
  | typeof sendOTPAnotherNumber
  | typeof verifyOTPSuccess
  | typeof sendOTPFail
  | typeof resetOtpToken
  | typeof verifyTokenSuccess
  | typeof verifyTokenProcessing
  | typeof verifyTokenFail
  | typeof clearToken
  | typeof verifyReactivateOTPSuccess;
// | typeof failed

export type IOTPActions = ReturnType<OTPActionCreators>;
