import React, { useEffect, useState } from "react";
import { Navbar } from "../components/navbar";
import { push } from "connected-react-router";
import { PageTitle } from "../components/PageTitle";
import "../css/index.css";
import styles from "../css/preview.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LineBreaker } from "../components/lineBreaker";
import { IRootState } from "../store";
import {
  formfinalConfirmThunk,
  formSubmitThunk,
  loadCaseThunk,
} from "../redux/form/thunk";
import { config } from "../config";
import LoadingOverlay from "../components/LoadingOverlay";
import { toast } from "react-toastify";
import { loadQAThunk } from "../redux/qa/thunk";
import moment from "moment";
import { getBankNameByCode } from "../utils/BankConfig";

import * as dotenv from "dotenv";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
dotenv.config();

interface Props {
  page: number;
  path: string;
}

interface Media {
  id: number;
  case_id: number;
  merchant_id: number;
  type: string;
  minetype: string;
  name: string;
  path: string;
  size: number;
  created_time: number;
  uploaded_ip_address: string;
  uploaded_user_agent: null;
  status: number;
  base64?: string;
}

export const Preview: React.FC<Props> = ({ page, path }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const caseToken = useSelector((state: IRootState) => state.otp.caseToken);
  const CaseData = useSelector((state: IRootState) => state.form.caseData);
  const submitIsProcessing = useSelector((state:IRootState) => state.form.isProcessing);
  const qaData = useSelector((state: IRootState) => state.qa.qaData);
  const language = useSelector((state: IRootState) => state.setting.language);
  const isSubmitted = useSelector(
    (state: IRootState) => state.form.isSubmitted
  );
  const [qaKey, setQaKey] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [images, setImages] = useState<Media[]>([]);

  const { hash } = useParams<Record<string, string | undefined>>();

  const marriage_status = {
    1: "marriage_status_single",
    2: "marriage_status_married",
    3: "marriage_status_divorced",
    4: "marriage_status_separated",
    5: "marriage_status_widowed"
  };

  const livingAddressFields = [
    "living_flat",
    "living_block",
    "living_floor",
    "living_building",
    "living_estate",
    "living_street_name",
    "living_street_number",
    "living_district",
    "living_city",
    "living_state",
    "living_country",
    "living_address_line_1",
    "living_address_line_2",
    "living_address_line_3",
    "full_address",
    "living_postal_code",
  ];

  const workingAddressFields = [
    "working_flat",
    "working_block",
    "working_floor",
    "working_building",
    "working_estate",
    "working_street_name",
    "working_street_number",
    "working_district",
    "working_city",
    "working_state",
    "working_country",
    "working_address_line_1",
    "working_address_line_2",
    "working_address_line_3",
    "working_postal_code",
  ];

  const idCardFields = [
    "other_name",
    "last_name",
    "first_name",
    "gender",
    "identity_type",
    "identity_country",
    "identity_number",
  ];

  const personalFields = ["email", "mobile_prefix", "mobile_number"];

  const downloadFile = async ()=>{
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/case/${caseToken}/file`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  
  useEffect(() => {
    if (!qaData || Object.keys(qaData).length <= 0) return;
    setQaKey(Object.keys(qaData));
  }, [qaData]);

  useEffect(() => {
    if (!isSubmitted) return;
    localStorage.clear();
    dispatch({type: "RESET_STATE"});
    dispatch(push(`${path}/complete`));
  }, [isSubmitted]);

  useEffect(() => {
    if (!caseToken || Object.values(caseToken).length <= 0) {
      dispatch(push(`${path}`));
      return;
    }
    dispatch(loadCaseThunk(caseToken));
    dispatch(loadQAThunk(caseToken));
    const loadMedia = async (caseToken: string) => {
      setIsProcessing(true);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/case/${caseToken}/media/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        const images = await res.json();
        setImages(images);
        setIsProcessing(false);
      } else {
        setIsProcessing(false);
        toast.error(t((await res.json()).message));
      }
    };
    loadMedia(caseToken);
    
   
    //load download file
  }, []);

  return (
    <>
      <LoadingOverlay isProcessing={isProcessing} />
      <Navbar canGoBack={true} />
      <div className="container">
        <PageTitle title={t("preview")} page={page} />
        {
          <>
            <LineBreaker />
            <div className="previewSection">
              <div className="previewSubtitle">{t("preview_region")}</div>
              <div className="contentText">
                {CaseData.locale == "cn"
                  ? "China"
                  : CaseData.locale == "hk"
                  ? "Hong Kong"
                  : CaseData.locale == "mc"
                  ? "Macau"
                  : CaseData.locale}
              </div>
              <LineBreaker />
              <div className="previewSubtitle">{t("preview_id_photo")}</div>
              {images &&
                images.map((image, index) => (
                  <React.Fragment key={index}>
                    <div className="contentText">{t(image?.type)}</div>
                    {image?.base64 && image.type != "video" && (
                      <div className={styles.dashedContainer}>
                        <div
                          className={styles.idCardContainer}
                          style={
                            ["idFront", "idBack"].indexOf(image?.type) > -1
                              ? {}
                              : {
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "50%",
                                }
                          }
                        >
                          <img
                            className={styles.image}
                            src={"data:image/png;base64," + image?.base64}
                          />
                        </div>
                      </div>
                    )}
                    { image?.base64 && image.type == "video" && (
                      <div className={styles.dashedContainer}>
                        <div
                          className={styles.idCardContainer}
                          style={
                            ["idFront", "idBack"].indexOf(image?.type) > -1
                              ? {}
                              : {
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "50%",
                                }
                          }
                        >
                          <video
                            width="320"
                            height="240"
                            controls
                            muted
                            playsInline
                            src={"data:video/mp4;base64," + image.base64}
                          >
                          </video>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}

              <LineBreaker />
              <div className="previewSubtitle">{t("preview_id_info")}</div>
              {idCardFields.map(
                (field: string, index: number) =>
                  CaseData[field] && (
                    <div
                      key={`living_address_${index}`}
                      className={styles.flexRow}
                    >
                      <div className="previewField">
                        {t(`preview_${field}`)}
                      </div>
                      {field === "gender" ? <div className="contentText">{t(`preview_gender_${CaseData[field]}`)}</div> : <div className="contentText">{CaseData[field]}</div>}
                    </div>
                  )
              )}
              <div className={styles.flexRow}>
                <div className="previewField"> {t("preview_dateOfBirth")}</div>
                <div className="contentText">
                  {CaseData["birth_day"] && CaseData["birth_day"] + "/"}
                  {CaseData["birth_month"] && CaseData["birth_month"] + "/"}
                  {CaseData["birth_year"]}
                </div>
              </div>
              {CaseData["identity_expiry_date"] &&
                <div className={styles.flexRow}>
                  <div className="previewField">
                    {t("preview_identity_expiry_date")}
                  </div>
                  <div className="contentText">
                    {CaseData["identity_expiry_date"] &&
                      moment
                        .unix(parseInt(CaseData["identity_expiry_date"]))
                        .format("l")}
                  </div>
                </div> 
              }
              {CaseData["identity_issue_date"] &&
                <div className={styles.flexRow}>
                  <div className="previewField">
                    {t("preview_identity_issue_date")}
                  </div>
                  <div className="contentText">
                    {CaseData["identity_issue_date"] &&
                      moment
                        .unix(parseInt(CaseData["identity_issue_date"]))
                        .format("l")}
                  </div>
                </div> 
              }
              <LineBreaker />
              <div className="previewSubtitle">
                {t("preview_personal_info")}
              </div>
              {personalFields.map(
                (field: string, index: number) =>
                  CaseData[field] && (
                    <div key={`personal_${index}`} className={styles.flexRow}>
                      <div className="previewField">
                        {t(`preview_${field}`)}
                      </div>
                      <div className="contentText">{CaseData[field]}</div>
                    </div>
                  )
              )}
              {qaData["signature"] && (
                <div key={`personal_signature`}>
                  <div className="previewField">{t(`preview_signature`)}</div>
                  <div style={{ maxWidth: "250px" }}>
                    <img
                      className="contentText"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        maxWidth: "100px",
                      }}
                      src={
                        Array.isArray(qaData["signature"])
                          ? qaData["signature"][qaData["signature"].length - 1]
                              ?.value
                          : qaData["signature"]?.value
                      }
                    />
                  </div>
                </div>
              )}
              {CaseData["marriage_status"] && (
                <div
                  key={`personal_${personalFields.length}`}
                  className={styles.flexRow}
                >
                  <div className="previewField">
                    {t(`preview_marriage_status`)}
                  </div>
                  <div className="contentText">
                    {t(marriage_status[CaseData["marriage_status"] as 1 | 2 | 3 | 4 | 5])}
                  </div>
                </div>
              )}
              {CaseData["bank_name"] && (
                <div key={`personal_bank_name`} className={styles.flexRow}>
                  <div className="previewField">{t(`preview_bank_name`)}</div>
                  <div className="contentText">
                    {CaseData["bank_name"] == "other"
                      ? CaseData["other_bank_name"]
                      : getBankNameByCode(CaseData["bank_name"])}
                  </div>
                </div>
              )}
              {CaseData["bank_account"] && (
                <div key={`personal_bank_account`} className={styles.flexRow}>
                  <div className="previewField">
                    {t(`preview_bank_account`)}
                  </div>
                  <div className="contentText">{CaseData["bank_account"]}</div>
                </div>
              )}
              {CaseData["bank_mobile"] && (
                <div key={`personal_bank_mobile`} className={styles.flexRow}>
                  <div className="previewField">{t(`preview_bank_mobile`)}</div>
                  <div className="contentText">{CaseData["bank_mobile"]}</div>
                </div>
              )}
              <LineBreaker />
              {CaseData["additional"]?.length > 0 && (
                <>
                  <div className="previewSubtitle">
                    {t("preview_other_bank")}
                  </div>
                  {CaseData["additional"].map(
                    (_field: string, index: number) => (
                      <div
                        key={`personal_${index}`}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div className={styles.flexRow}>
                          <div
                            className="previewField"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            {t(`preview_additional_bank_name`)}
                          </div>
                          <div
                            className="contentText"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            {CaseData["additional"][index][
                              "additional_bank_name"
                            ] == "other"
                              ? CaseData["additional"][index][
                                  "additional_other_bank_name"
                                ]
                              : getBankNameByCode(
                                  CaseData["additional"][index][
                                    "additional_bank_name"
                                  ]
                                )}
                          </div>
                        </div>
                        <div className={styles.flexRow}>
                          <div
                            className="previewField"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            {t(`preview_additional_bank_account`)}
                          </div>
                          <div
                            className="contentText"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            {
                              CaseData["additional"][index][
                                "additional_bank_account"
                              ]
                            }
                          </div>
                        </div>
                        {CaseData["additional"][index]["additional_bank_mobile"] &&
                          (<div className={styles.flexRow}>
                            <div
                              className="previewField"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              {t(`preview_additional_bank_mobile`)}
                            </div>
                            <div
                              className="contentText"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                              {
                                CaseData["additional"][index][
                                "additional_bank_mobile"
                                ]
                              }
                            </div>
                          </div>)}
                      </div>
                    )
                  )}
                  <LineBreaker />
                </>
              )}
              <div className="previewSubtitle">{t("preview_living_info")}</div>
              {livingAddressFields.map(
                (field: string, index: number) =>
                  CaseData[field] && (
                    <div
                      key={`living_address_${index}`}
                      className={styles.flexRow}
                    >
                      <div className="previewField">
                        {t(`preview_${field}`)}
                      </div>
                      <div className="contentText">{CaseData[field]}</div>
                    </div>
                  )
              )}
              <LineBreaker />
              {workingAddressFields.filter((value) => CaseData[value]).length >
                0 && (
                <>
                  <div className="previewSubtitle">
                    {t("preview_working_info")}
                  </div>
                  {workingAddressFields.map(
                    (field: string, index: number) =>
                      CaseData[field] && (
                        <div
                          key={`working_address_${index}`}
                          className={styles.flexRow}
                        >
                          <div className="previewField">
                            {t(`preview_${field}`)}
                          </div>
                          <div className="contentText">{CaseData[field]}</div>
                        </div>
                      )
                  )}
                  <LineBreaker />
                </>
              )}
              <div className="previewSubtitle">{t("preview_qa")}</div>
              {qaKey.map((key: string, index: number) => (
                <div
                  key={`qa_${index}`}
                  style={{
                    display:
                      key?.startsWith("signature") ||
                      qaData[key]?.element == "Signature" ||
                      Array.isArray(qaData[key])
                        ? "block"
                        : "flex",
                  }}
                >
                  {!Array.isArray(qaData[key]) && key != "signature" && (
                    <>
                      <div className="previewField">
                        {`${JSON.parse(qaData[key]?.label)?.[language]?.replace(
                          /(<([^>]+)>)/gi,
                          ""
                        ).replace("&nbsp;", "")}:`}
                      </div>

                      {qaData[key]?.selected_label && (
                        <div className="contentText">
                          {qaData[key]?.selected_label?.[language]}
                        </div>
                      )}
                      {(qaData[key]?.element.includes("Text") || qaData[key]?.element.includes("Input")) &&
                        (
                          <div className="contentText">
                            {qaData[key]?.value}
                          </div>
                        )
                      }

                      {/* {qaData[key]?.element !== "Signature" &&
                        qaData[key]?.element !== "Download" &&
                        qaData[key]?.element !== "Dropdown" &&
                        qaData[key]?.element !== "RadioButtons" &&
                        qaData[key]?.element !== "DatePicker" && (
                          <div className="contentText">
                            {qaData[key]?.value}
                          </div>
                        )}
                      {qaData[key]?.element === "Dropdown" && (
                        <div className="contentText">
                          {qaData[key]?.selected_label?.[language]}
                        </div>
                      )}
                      {qaData[key]?.element === "RadioButtons" && (
                        <div className="contentText">
                          {qaData[key]?.selected_label?.[language]}
                        </div>
                      )} */}
                      {qaData[key]?.element == "Signature" && (
                        <div style={{ maxWidth: "100px" }}>
                          <img
                            className="contentText"
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              maxWidth: "100px",
                            }}
                            src={qaData[key]?.value}
                          />
                        </div>
                      )}
                      {qaData[key]?.element === "Download" && (
                        // <a href={qaData[key]?.value}>{t("Download")}</a>
                        <button onClick={()=>downloadFile()}></button>
                      )}
                      {qaData[key]?.element === "DatePicker" && (
                        <div className="contentText">
                          {moment
                            .unix(parseInt(qaData[key]?.value))
                            .format("l")}
                        </div>
                      )}
                    </>
                  )}
                  {Array.isArray(qaData[key]) &&
                    qaData[key]?.[0]?.element !== "Signature" &&
                    qaData[key]?.[0]?.element !== "Download" &&
                    qaData[key].map((subKey: any, index: number) => (
                      <div
                        key={"subKey_" + index}
                        style={{
                          // display: "flex",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <div className="previewField">
                          {`${JSON.parse(qaData[key]?.[0]?.label)?.[
                            language
                          ]?.replace(/(<([^>]+)>)/gi, "").replace("&nbsp;", "")}:`}
                        </div>
                        <div className="contentText">
                          {subKey?.selected_label?.[language]}
                        </div>
                      </div>
                    ))}
                  {Array.isArray(qaData[key]) &&
                    qaData[key]?.[0]?.element &&
                    qaData[key]?.[0]?.element == "Download" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <div className="previewField">
                            {`${JSON.parse(qaData[key]?.[0]?.label)?.[
                              language
                            ]?.replace(/(<([^>]+)>)/gi, "").replace("&nbsp;", "")}:`}
                          </div>
                          {/* <a
                            href={qaData[key]?.[qaData[key].length - 1]?.value}
                          >
                            {t("Download")}
                          </a> */}
                          <div className="previewField">
                            {qaData[key]?.[qaData[key].length - 1]?.value}
                          </div>
                        </div>
                      </>
                    )}
                  {/* {Array.isArray(qaData[key]) &&
                    qaData[key]?.[0]?.element == "Signature" &&
                    qaData[key].map((signature: any) => (
                      <div
                        style={{
                          display: "block",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <div className="previewField">
                          {`${JSON.parse(qaData[key]?.[0]?.label)?.[
                            language
                          ]?.replace(/(<([^>]+)>)/gi, "").replace("&nbsp;", "")}:`}
                        </div>
                        <div style={{ maxWidth: "100px" }}>
                          <img
                            className="contentText"
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              maxWidth: "100px",
                            }}
                            src={signature?.value}
                          />
                        </div>
                      </div>
                    ))} */}
                </div>
              ))}
            </div>
          </>
        }
        <button
          className={ submitIsProcessing ? "disabledButton bottomMargin" : "button bottomMargin"}
          disabled={submitIsProcessing}
          onClick={() => {
            if (!caseToken) return;
            return dispatch(formfinalConfirmThunk(hash, caseToken, path, t));
          }}
        >
          <div className="buttonText">{t("complete")}</div>
        </button>
      </div>
    </>
  );
};
